import React, {useState} from 'react';
import './header.scss';
import {Link, useNavigate} from "react-router-dom";
import axios_http from "../../../http/axios-plugin";
import endpoint from "../../../endpoint/endpoint.ts";
import {errorAlert, toastAlert} from "../../../shared/helper";
import {setAccessToken, setUserDatasInfos} from "../../../store/authentification/AuthReducer";
import {useDispatch} from "react-redux";
import storage from "redux-persist/lib/storage";
const Header = () => {
    function sideBarToggle(){
        let toggle = document.body.getAttribute('data-kt-app-sidebar-minimize');
        if (toggle === 'on'){
            document.body.setAttribute('data-kt-app-sidebar-minimize', 'off');

        }else {
            document.body.setAttribute('data-kt-app-sidebar-minimize', 'on');
        }
    }
    function asideChatToogle(){
        let toggle = document.body.getAttribute('data-kt-drawer-chat');
        //
        document.getElementById('kt_drawer_chat').classList.add("drawer");
        document.getElementById('kt_drawer_chat').classList.add("drawer-end");
        document.getElementById('kt_drawer_chat').classList.add("drawer-on");
        document.getElementById('drawer-bg').style.display = 'block';
        document.body.setAttribute('data-kt-drawer-app-sidebar', 'on');
        document.body.setAttribute('data-kt-drawer', 'on');
    }

    function mobileSideBarToggle(){
        let toggle = document.body.getAttribute('data-kt-drawer-app-sidebar');
        //
        document.getElementById('kt_app_sidebar').classList.add("drawer");
        document.getElementById('kt_app_sidebar').classList.add("drawer-start");
        document.getElementById('kt_app_sidebar').classList.add("drawer-on");
        document.getElementById('drawer-bg').style.display = 'block';
        if (toggle === 'on'){
            document.body.setAttribute('data-kt-drawer-app-sidebar', 'off');
            document.body.setAttribute('data-kt-drawer', 'off');

        }else {
            document.body.setAttribute('data-kt-drawer-app-sidebar', 'on');
            document.body.setAttribute('data-kt-drawer', 'on');
        }
    }
    function mobileAsideToggle(){
        let toggle = document.body.getAttribute('data-kt-drawer-app-aside');
        //
        document.getElementById('kt_app_aside').classList.add("drawer");
        document.getElementById('kt_app_aside').classList.add("drawer-end");
        document.getElementById('kt_app_aside').classList.add("drawer-on");
        document.getElementById('drawer-bg').style.display = 'block';
        if (toggle === 'on'){
            document.body.setAttribute('data-kt-drawer-app-sidebar', 'off');
            document.body.setAttribute('data-kt-drawer', 'off');

        }else {
            document.body.setAttribute('data-kt-drawer-app-sidebar', 'on');
            document.body.setAttribute('data-kt-drawer', 'on');
        }
    }
    const [activeProfilMenu, setActiveProfilMenu] = useState(false);
    function toggleProfil(){
        setActiveProfilMenu(!activeProfilMenu);
    }
    const route = useNavigate();
    const dispatch = useDispatch();
    const logoutUser = ()=>{
        setTimeout(()=>{
            axios_http.post(endpoint.logoutUserSession,{}).then((resp_logout)=>{
                dispatch(setAccessToken(""))
                dispatch(setUserDatasInfos(null))
                localStorage.removeItem('access_token')
                localStorage.removeItem('authInfos')
                toastAlert(resp_logout.data.message).then()
                storage.removeItem('persist:root').then();
                route("/")
            }).catch((err)=>{
                errorAlert('Oops!', err.response?.data?.message).then();
            })
        }, 600)
    }
    return (

        <div id="kt_app_header" className="app-header  d-flex flex-column flex-stack">
            <div className="d-flex flex-stack flex-grow-1">

                <div className="app-header-logo d-flex align-items-center ps-lg-12" id="kt_app_header_logo">
                    <div
                        id="kt_app_sidebar_toggle"
                        className="app-sidebar-toggle btn btn-sm btn-icon bg-body btn-color-gray-500 btn-active-color-primary w-30px h-30px ms-n2 me-4 d-none d-lg-flex "
                        data-kt-toggle="true"
                        data-kt-toggle-state="active"
                        data-kt-toggle-target="body"
                        data-kt-toggle-name="app-sidebar-minimize"
                        onClick={sideBarToggle}
                    >

                        <i className="ki-outline ki-abstract-14 fs-3 mt-1"></i></div>

                    <div className="btn btn-icon btn-active-color-primary w-35px h-35px ms-3 me-2 d-flex d-lg-none"
                         id="kt_app_sidebar_mobile_toggle" onClick={mobileSideBarToggle}>
                        <i className="ki-outline ki-abstract-14 fs-2"></i></div>

                    <a href="#" className="app-sidebar-logo">
                        <img alt="Logo" src="/assets/images/logo-text.png" className="h-45px theme-light-show"/>
                    </a>
                </div>

                <div className="app-navbar flex-grow-1 justify-content-end" id="kt_app_header_navbar">
                    <div className="app-navbar-item d-flex align-items-stretch flex-lg-grow-1">

                        <div
                            id="kt_header_search"
                            className="header-search d-flex align-items-center w-lg-350px"

                            data-kt-search-keypress="true"
                            data-kt-search-min-length="2"
                            data-kt-search-enter="enter"
                            data-kt-search-layout="menu"
                            data-kt-search-responsive="true"
                            data-kt-menu-trigger="auto"
                            data-kt-search={true}
                            data-kt-menu-permanent="true"
                            data-kt-menu-placement="bottom-start"


                        >

                        </div>
                    </div>
                    <div className="app-navbar-item ms-2 ms-lg-6">
                        <div
                            className="btn btn-icon btn-custom btn-color-gray-600 btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative"
                            id="kt_drawer_chat_toggle" onClick={asideChatToogle}>
                            <i className="ki-outline ki-notification-on fs-1"></i> <span
                            className="position-absolute top-0 start-100 translate-middle  badge badge-circle badge-danger w-15px h-15px ms-n4 mt-3">5</span>
                        </div>
                    </div>

                    <div className="app-navbar-item ms-2 ms-lg-6">
                        <div
                            className="btn btn-icon btn-custom btn-color-gray-600 btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end" >
                            <i className="ki-outline ki-calendar fs-1"></i>
                        </div>

                        <div className="app-navbar-item ms-2 ms-lg-6" id="kt_header_user_menu_toggle">
                            <div className="cursor-pointer symbol symbol-circle symbol-30px symbol-lg-45px"
                                 data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                 data-kt-menu-attach="parent"
                                 data-kt-menu-placement="bottom-end" onClick={toggleProfil}>
                                <img
                                    src="https://preview.keenthemes.com/metronic8/demo39/assets/media/avatars/300-2.jpg"
                                    alt="user"/>
                            </div>

                            <div
                                className={activeProfilMenu === true ? "menu menu-sub  menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px show profile-menu" : "menu menu-sub  menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"}
                                data-kt-menu="true">

                                <div className="menu-item px-3">
                                    <div className="menu-content d-flex align-items-center px-3">
                                        <div className="symbol symbol-50px me-5">
                                            <img alt="Logo"
                                                 src="https://preview.keenthemes.com/metronic8/demo39/assets/media/avatars/300-2.jpg"/>
                                        </div>

                                        <div className="d-flex flex-column">
                                            <div className="fw-bold d-flex align-items-center fs-5">
                                                Max Smith <span
                                                className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span>
                                            </div>

                                            <a href="index.html#"
                                               className="fw-semibold text-muted text-hover-primary fs-7">
                                                max@kt.com </a>
                                        </div>
                                    </div>
                                </div>


                                <div className="separator my-2"></div>


                                <div className="menu-item px-5">
                                    <Link to={"/dashboard/user/profile"} className="menu-link px-5">
                                        Mon Profil
                                    </Link>
                                </div>


                                <div className="menu-item px-5">
                                    <Link to="/dashboard/user/config" className="menu-link px-5">
                                        <span className="menu-text">Configuration</span>
                                        <span className="menu-badge"></span>
                                    </Link>
                                </div>


                                <div className="menu-item px-5"
                                     data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                     data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                                    <Link to="/dashboard/user/subscribe" className="menu-link px-5">
                                        <span className="menu-title">Mon Abonnement</span>
                                    </Link>

                                </div>

                            </div>
                        </div>

                        <div className="app-navbar-item ms-2 ms-lg-6 me-lg-6">
                            <a onClick={logoutUser}
                               className="btn btn-icon btn-custom btn-color-gray-600 btn-active-color-primary w-35px h-35px w-md-40px h-md-40px cursor-pointer">
                                <i className="ki-outline ki-exit-right fs-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-header-separator"></div>
        </div>

    )
        ;
};

export default Header;