import React, {useState} from 'react';
import {environment} from "../../../environment/environment.ts";
import IziModal from "../../../shared/components/modal/IziModal";
import axios_http from "../../../http/axios-plugin";
import endpoint from "../../../endpoint/endpoint.ts";
import {v4 as uuidv4} from "uuid";
import {toastAlert} from "../../../shared/helper";
import {useDispatch} from "react-redux";
import {setPropertyChoose} from "../../../store/add-property/AddPropertyReducer";

const AdvancedEdit = ({propertyData = null}) => {
    const [propertyAttachment, setAttach] = useState([])
    const [propertyAttachFile, setAttachFile] = useState(propertyData.pieces_image)
    const [activeFile, setActiveFile] = useState(false)
    const dispatch = useDispatch();
    const onFileChange = (e) => {
        setAttach((prevState) =>
            [...prevState, e.target.files[0]]
        );
    }
    const updatePropAttachFile =()=>{
       const  propertyAttach = [];
        for (let y = 0; y < propertyAttachment.length;y++){
            propertyAttach.push(uuidv4().split('-')[0])
        }
        const attachFormData = new FormData()
        attachFormData.append("id",propertyData.id)
        attachFormData.append("attache_files", JSON.stringify(propertyAttach))
        for (let p = 0; p < propertyAttachment.length;p++){
            attachFormData.append(propertyAttach[p], propertyAttachment[p])
        }
        axios_http.post(endpoint.updatePropertyAttachFiles, attachFormData).then((resp_update)=>{
            dispatch(setPropertyChoose(resp_update.data.data))
            setAttach([]);
            setActiveFile(false);
            toastAlert(resp_update.data.message).then()
        }).catch(err=>{
            toastAlert(err.message, 'error').then()
        });
    }
    const removeNewFileChoose = (fileToRemove)=>{
        setAttach((prevState) =>
            [...prevState].filter(elem => elem !== fileToRemove)
        );
    }
    const updateOldAttachFile = (fileToRemovePath)=>{
        setAttachFile((prevState)=>[...prevState].filter(path=> path !== fileToRemovePath))
        const newAttachFile = [...propertyAttachFile];
        const removeData = {
            id: propertyData.id,
            fileToRemovePath:fileToRemovePath,
            propertyNewAttachFile:newAttachFile
        }
        axios_http.put(endpoint.removePropertyAttachFiles, removeData).then((resp_update)=>{
            dispatch(setPropertyChoose(resp_update.data.data))
            toastAlert(resp_update.data.message).then()
        }).catch((err)=>{
            toastAlert(err.message, 'error').then()
        });
    }
    return (
        <>
            <div className="d-flex flex-column gap-3">
                <div className="card shadow-sm">
                    <div className="card-header bg-white">
                        <h3 className="card-title">Pièces jointe</h3>
                        <div className="card-toolbar">
                            <button type="button" className="btn btn-flex btn-primary" onClick={()=>setActiveFile(true)}>
                                <i className="ki-outline ki-folder-up fs-5"></i> Nouvelle Pièce jointe
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {propertyAttachFile.map((img,i) => {
                                return <div className="col-sm-12 col-md-6 col-lg-3 ribbon ribbon-end ribbon-clip" key={i}>
                                    <div className="d-flex flex-center h-300px position-relative">
                                        <span
                                            className="position-absolute top-0 start-0 translate-middle  badge badge-circle badge-danger cursor-pointer" onClick={()=>updateOldAttachFile(img)}>
                                            <i className="bi bi-trash text-white"></i>
                                        </span>
                                        <img
                                            src={environment.storageApiUrl + img}
                                            data-src={environment.storageApiUrl + img}
                                            className="lozad rounded mw-100"
                                            alt=""
                                        />
                                    </div>
                                </div>

                            })}

                        </div>
                    </div>
                </div>
                <div className="card card-dashed">
                    <div className="card-header">
                        <h3 className="card-title">Pièces</h3>

                    </div>
                    <div className="card-body">
                        {propertyData.pieces.map((piece, index) => {
                            return <div className="card shadow-sm" key={index}>
                                <div className="card-header collapsible cursor-pointer rotate" data-bs-toggle="collapse"
                                     data-bs-target="#kt_docs_card_collapsible">
                                    <h3 className="card-title">{piece?.pieceType?.value.toString() ?? ""} : {piece?.label.toString() ?? ""}</h3>
                                    <div className="card-toolbar rotate-180">
                                        <i className="ki-duotone ki-down fs-1"></i>
                                    </div>
                                </div>
                                <div id="kt_docs_card_collapsible" className="collapse show">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table id="kt_datatable_vertical_scroll"
                                                   className="table table-striped table-row-bordered gy-5 gs-7">
                                                <thead>
                                                <tr className="fw-semibold fs-6 text-gray-800">
                                                    <th className="pe-7">Libellé</th>
                                                    <th>Type</th>
                                                    <th>état</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>Porte</td>
                                                    <td>{piece?.door?.type_elem.value}</td>
                                                    <td>{piece?.door?.state.value}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mur</td>
                                                    <td>{piece?.wal?.type_elem.value}</td>
                                                    <td>{piece?.wal?.state.value}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sol</td>
                                                    <td>{piece?.ground?.type_elem.value}</td>
                                                    <td>{piece?.ground?.state.value}</td>
                                                </tr>
                                                <tr>
                                                    <td>Vitrage</td>
                                                    <td>{piece?.glazing?.type_elem.value}</td>
                                                    <td>{piece?.glazing?.state.value}</td>
                                                </tr>
                                                <tr>
                                                    <td>Plafond</td>
                                                    <td>{piece?.ceiling?.type_elem.value}</td>
                                                    <td>{piece?.ceiling?.state.value}</td>
                                                </tr>
                                                <tr>
                                                    <td>Interrupteurs & Lumières</td>
                                                    <td>
                                                        <ul>
                                                            {piece?.lighting_switches?.map((light_label: any) => {
                                                                return <li>{light_label.label}</li>
                                                            })}
                                                        </ul>

                                                    </td>
                                                    <td>{piece?.lighting_switches?.map((light_label: any) => {
                                                        return <li>{light_label.state?.value}</li>
                                                    })}</td>
                                                </tr>
                                                <tr>
                                                    <td>Prise</td>
                                                    <td>
                                                        <ul>
                                                            {piece?.electrical_outlets?.map((light_label: any) => {
                                                                return <li>{light_label.label}</li>
                                                            })}
                                                        </ul>

                                                    </td>
                                                    <td>{piece?.electrical_outlets?.map((light_label: any) => {
                                                        return <li>{light_label.state?.value}</li>
                                                    })}</td>
                                                </tr>

                                                </tbody>
                                                <tfoot>
                                                <tr className="border-top fw-semibold fs-6 text-gray-800">
                                                    <th className="pe-7">Libellé</th>
                                                    <th>Type</th>
                                                    <th>état</th>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="card-toolbar">
                                            <button type="button" className="btn btn-sm btn-light">
                                                Mettre à jour
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-4">
                        <button type="button" className="btn btn-flex btn-danger fw-bold" onClick={() => setActiveFile(true)}>
                            <i className="ki-outline ki-trash-square fs-3"></i> Supprimer ce bien
                        </button>
                    </div>
                </div>
            </div>
            <IziModal closeValue={activeFile} modalTitle={"Ajouter une nouvelle pièce"} fullScreen={false}
                      closeModal={() => setActiveFile(false)}>
                <form className="form" action="none" >
                    <div className="modal-body pt-10 pb-15 px-lg-17">
                        <div className="form-group">
                            <div className="dropzone dropzone-queue mb-2" id="kt_modal_upload_dropzone">
                                <div className="dropzone-panel mb-4">
                                    <label htmlFor="iziFile">
                                        <input type="file" name="inputFile" onChange={(e) => onFileChange(e)}
                                               id="iziFile" className="d-none"/>
                                        <a className="dropzone-select btn btn-sm btn-success me-2 dz-clickable">Ajouter</a>
                                    </label>
                                    { propertyAttachment.length>0 && <a className=" btn btn-sm btn-primary me-2" onClick={updatePropAttachFile}>Mettre à
                                        jour</a>}
                                </div>
                                {
                                    propertyAttachment.map((newFile,i) => {
                                        return <div className="dropzone-items wm-200px" key={i}>
                                            <div className="dropzone-item p-5 dz-image-preview" id="">
                                                <div className="dropzone-file">
                                                    <div className="dropzone-filename text-gray-900"
                                                         title="some_image_file_name.jpg"><span
                                                        data-dz-name="">{newFile.name}</span>
                                                        <strong>(<span
                                                            data-dz-size=""><strong>{newFile.size / 1000}</strong> KB</span>)</strong>
                                                    </div>
                                                    <div className="dropzone-error mt-0" data-dz-errormessage=""></div>
                                                </div>
                                                <div className="dropzone-toolbar">
                                            <span className="dropzone-delete" data-dz-remove="" onClick={()=>removeNewFileChoose(newFile)}>
                                                <i className="ki-outline ki-cross fs-2"></i>
                                            </span>
                                                </div>

                                            </div>
                                        </div>
                                    })
                                }
                            </div>

                        </div>
                    </div>
                </form>
            </IziModal>

        </>
    );
};

export default AdvancedEdit;