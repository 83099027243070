import React from 'react';

const UserEdit = () => {
    return (
        <div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      noValidate="novalidate">
                    <div className="card-body border-top p-9">

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Nom complet</label>

                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="fname"
                                               className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                               placeholder="First name" value="Max"/>
                                        <div
                                            className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>

                                    <div className="col-lg-6 fv-row fv-plugins-icon-container">
                                        <input type="text" name="lname"
                                               className="form-control form-control-lg form-control-solid"
                                               placeholder="Last name" value="Smith"/>
                                        <div
                                            className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Ville</label>

                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company"
                                       className="form-control form-control-lg form-control-solid"
                                       placeholder="Company name" value="Keenthemes"/>
                                <div
                                    className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Pays</label>

                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="company"
                                       className="form-control form-control-lg form-control-solid"
                                       placeholder="Company name" value="Keenthemes"/>
                                <div
                                    className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span className="required">Contact</span>


                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Phone number must be active"
                                      data-bs-original-title="Phone number must be active" data-kt-initialized="1">
	<i className="ki-outline ki-information-5 text-gray-500 fs-6"></i></span> </label>

                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="tel" name="phone"
                                       className="form-control form-control-lg form-control-solid"
                                       placeholder="Phone number" value="044 3276 454 935"/>
                                <div
                                    className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Email</label>

                            <div className="col-lg-8 fv-row">
                                <input type="text" name="website"
                                       className="form-control form-control-lg form-control-solid"
                                       placeholder="Company website" value="max@origami.com"/>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Mot de passe</label>

                            <div className="col-lg-8 fv-row">
                                <input type="text" name="website"
                                       className="form-control form-control-lg form-control-solid"
                                       placeholder="Company website" value=""/>
                            </div>
                        </div>


                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="button" className="btn btn-primary"
                                id="kt_account_profile_details_submit">Appliquer les
                            Changement
                        </button>
                    </div>
                    <input type="hidden"/></form>
            </div>
        </div>
    );
};

export default UserEdit;
