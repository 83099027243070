import React, {useState} from 'react';
import StepAside from "./StepAside";
import IziButton from "../../../../shared/button/IziButton";
import {StepContent} from "./StepContent";
import {AccountTypeForm} from "../../AccountTypeForm";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAccountType,
    selectUserAccount,
    selectUserInfos,
    selectUserInfosValidation,
    setAccountInfos,
    setAccountInfosValidation,
    setUserInfosValidation,
    setUserPersonalDatas,
    setUserType
} from "../../../../store/user-register/CustomerReducer"
import {errorAlert, successAlert} from "../../../../shared/helper";
import {AccountForm} from "../../AccountForm";
import RegisterSuccessFull from "../../RegisterSuccessFull";
import UserInfosForm from "../../UserInfosForm";
import axios_http from "../../../../http/axios-plugin";
import endpoint from "../../../../endpoint/endpoint.ts";
import {setAccessToken, setUserDatasInfos} from "../../../../store/authentification/AuthReducer";
import { useNavigate } from "react-router-dom";
const Stepper = () => {
    const [itemElements, setElement] =useState(
        [
            {
                title:"Type de compte",
                desc:"Définissez le type de compte",
                isCurrent:true,
                stepNum:1,
                nextElem:true,
                isFinal:false
            },
            {
                title:"Infos personnelles",
                desc:"Détaille de vos infos",
                isCurrent:false,
                stepNum:2,
                nextElem:true,
                isFinal:false
            },
            {
                title:"Infos compte",
                desc:"Détaille de votre compte",
                isCurrent:false,
                stepNum:3,
                nextElem:true,
                isFinal:false
            },
            {
                title:"Résumé",
                desc:"Resumé de vos infos",
                isCurrent:false,
                stepNum:4,
                nextElem:false,
                isFinal:true
            },
        ]
    )
    const [loading, setLoad] = useState(false)
    const navigate = useNavigate();
    const [stepMove, setStep] = useState(1)
    const accountType = useSelector(selectAccountType)
    const userInfos = useSelector(selectUserInfos)
    const userAccount = useSelector(selectUserAccount)
    const userInfosValid = useSelector(selectUserInfosValidation)
    const dispatch = useDispatch()
    const moveStep = (mode="next")=>{
        const oldStep = stepMove
        const oldItems = itemElements
        if (mode === "next" && oldStep < 4){
            if (oldStep === 1 && accountType ===""){
                errorAlert('null', "Veuillez choisir votre type de compte").then();
            }else if (oldStep === 2 && (userInfos ===null || !userInfosValid)){
                errorAlert('null', "Veuillez bien renseigné vos données personnelles").then();
            }
            else if (oldStep === 3 && userAccount ===null){
                errorAlert('null', "Veuillez bien renseigné vos accès, le mot de passe doit contenir au moin 8 caractère").then();
            }
            else {
                setStep(oldStep + 1)
                oldItems[oldStep].isCurrent = true
                setElement(oldItems)
            }
        }
        if (mode === "previous" && oldStep > 1){
             setStep(oldStep - 1)
            oldItems[oldStep].isCurrent = false
            setElement(oldItems)
        }
    }
    const storeUserInfos = ()=>{
        setTimeout(()=>{
            setLoad(true)
        },600)
        const registerAllDatas = {
        ...userInfos, ...userAccount,user_type_label:accountType, user_type: accountType ==="Basic" ? 1:2
        }
        setTimeout(()=>{
            axios_http.post(endpoint.registerUserAccount,registerAllDatas).then((resp_user)=>{
                dispatch(setAccessToken(resp_user.data.token))
                dispatch(setUserDatasInfos(resp_user.data.data))
                localStorage.setItem('access_token',resp_user.data.token )
                localStorage.setItem('authInfos',JSON.stringify(resp_user.data.data) )
                setLoad(false)
                dispatch(setUserType(""));
                dispatch(setAccountInfos(null));
                dispatch(setAccountInfosValidation(null));
                dispatch(setUserInfosValidation(null));
                dispatch(setUserPersonalDatas(null));
                successAlert("Vote compte à été créer avec succès","Accéder à mon espace").then(confirm=>{
                    if (confirm.isConfirmed){
                        navigate('/dashboard')
                    }
                })
            }).catch((err)=>{
                setTimeout(()=>{
                    setLoad(false)
                },600)
                if (err.response.data){
                    errorAlert('Ooops!', err.response.data.message).then();
                }else{
                    errorAlert('Ooops!', "Une erreur s'est produite  veuillez réessayé").then();
                }
            })
        }, 1000)

    }
    return (
        <>
            <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid gap-10"
                 id="kt_create_account_stepper" data-kt-stepper="true">
                <StepAside itemsList={itemElements}></StepAside>
                <div className="card bg-white shadow border-light d-flex flex-row-fluid flex-center">
                    <div className="card-body py-20 w-100  px-6 fv-plugins-bootstrap5 fv-plugins-framework"
                         >
                        {
                            stepMove===1 && <StepContent isActive={ (stepMove === 1)}>
                                <AccountTypeForm></AccountTypeForm>
                            </StepContent>
                        }
                        {
                            stepMove===2 && <StepContent isActive={stepMove === 2}>
                            <UserInfosForm></UserInfosForm>
                            </StepContent>
                        }
                        {
                            stepMove===3 && <StepContent isActive={stepMove === 3}>
                                <AccountForm></AccountForm>
                            </StepContent>
                        }
                        {
                            stepMove===4 && <StepContent isActive={stepMove === 4}>
                                <RegisterSuccessFull></RegisterSuccessFull>
                            </StepContent>
                        }
                        <div className="d-flex flex-stack pt-10">
                            <div className="mr-2">
                                {stepMove > 1 && <IziButton onClick={() => moveStep("previous")}>
                                    <i className="ki-outline ki-arrow-left fs-4 me-1"></i> Précédent
                                </IziButton>}
                            </div>

                            <div>
                                { stepMove < 4 && <IziButton onClick={()=>moveStep("next")}>
                                    Suivant<i className="ki-outline ki-arrow-right fs-4 ms-1 me-0"></i>
                                </IziButton>}
                                {
                                    stepMove === 4 && <IziButton disabled={loading} onClick={storeUserInfos}>
                                        {loading ===false && <span>Valider
                                        <i className="ki-outline ki-check-circle fs-4 ms-1 me-0"></i>

                                        </span>}

                                        {loading && <span className={loading ? "d-inline-block" : "indicator-progress"}>
                                            Veuillez patienter...
                                         <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>}
                                    </IziButton>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Stepper;
