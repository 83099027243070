import React, {useState} from 'react';
import IziStepper from "../../shared/components/stepper/IziStepper";
import StepContent from "../../shared/components/stepper/StepContent";
import IziButton from "../../shared/button/IziButton";
import IziInputField from "../../shared/components/form/IziInputField";
import IziSelectField from "../../shared/components/form/IziSelectField";
import IziDatePicker from "../../shared/components/form/IziDatePicker";

const NewCampaign = () => {
    const [campaignForm,setCampaignInfos] = useState({
        type:'',
        label:'',
        tenants:[],
        canals:[],
        startDate:'',
        relanceDate:[],
        nbrMonth:1
    })
    const onChangeType = (event)=>{
        setCampaignInfos({
            ...campaignForm,
            'type': event,});
    }
    const onChangeCanal = (event)=>{
        setCampaignInfos({
            ...campaignForm,
            'canals': event,});
    }
    const onChangeTenants = (event)=>{
        setCampaignInfos({
            ...campaignForm,
            'tenants': event,});
    }
    const onChangeRelanceDates = (event)=>{
        setCampaignInfos({
            ...campaignForm,
            'relanceDate': event,});
    }
    const campaignCanal =[
        {label:"Email", value:"Email"},
        {label:"SMS", value:"SMS"},
        {label:"Whatsapp", value:"Whatsapp"},
    ]

    const tenantList = [
        {lastName:"Gbaguidi", firstName:"Anderson", contact:"0586957514", email:"orin45@gmail.com"},
        {lastName:"Zorkor", firstName:"Larien", contact:"0786357514", email:"marc45@gmail.com"},
        {lastName:"Nsue", firstName:"Andi", contact:"0186957514", email:"origin405@gmail.com"},
        {lastName:"Bolasie", firstName:"Muavaku", contact:"0586907514", email:"zoro45@gmail.com"},
        {lastName:"Roadon", firstName:"Wilson", contact:"0176957514", email:"orin45@gmail.com"},
        {lastName:"Mouape", firstName:"Chaline", contact:"0783957514", email:"orin45@gmail.com"},
    ]
    const tenantGroup = tenantList.map(tenant => {
        return {label: tenant.lastName +' '+ tenant.firstName, value:tenant}
    })
    const campaignType = [
        {label:"Loyer impayé", value:"Loyer impayé"},
        {label:"Travaux", value:"Travaux"},
        {label:"Echéance loyer", value:"Echéance loyer"},
        {label:"Paiement loyer", value:"Paiement loyer"},
        {label:"Révision contrat", value:"Révision contrat"},
        {label:"Evenementiels", value:"Evenementiels"},
    ]
    const  [stepMove, setStepMove]=useState(0);

    const  [stepItems, setStepItems] = useState([
        {
            label: "Information",
            isCurrent: true,
        },
        {
            label: "Cible",
            isCurrent: false,
        },
        {
            label: "Planification",
            isCurrent: false,
        },
    ]);
    const onChange = (event) => {
        const { name, value, type } = event.target;
        setCampaignInfos({
            ...campaignForm,
            [name]:value,
        });
    };

    function  handleMove(next ) {
        let newVal = stepMove;
        newVal =next === true ? stepMove + 1 : stepMove - 1;
        setStepMove(newVal)
        switch(newVal){
            case 0:
                stepItems.map((item)=> item.isCurrent = false);
                stepItems[newVal].isCurrent = true;
                setStepItems(stepItems);
                break;
            case 1:
                stepItems.map((item)=> item.isCurrent = false);
                stepItems[newVal].isCurrent = true;
                setStepItems(stepItems)
                break;
            case 2:
                stepItems.map((item)=> item.isCurrent = false);
                stepItems[newVal].isCurrent = true;
                setStepItems(stepItems)
                break;
            case 3:
                stepItems.map((item)=> item.isCurrent = false);
                stepItems[newVal].isCurrent = true;
                setStepItems(stepItems)
                break;
            default:
                stepItems.map((item)=> item.isCurrent = false);
                stepItems[0].isCurrent = true;
                setStepItems(stepItems)
                break;
        }

    }
    return (
        <>
            <h4>Démarrer une nouvelle campagne</h4>
            <IziStepper stepItemList={stepItems} className={"w-100"}>
                {
                    stepMove === 0 && <StepContent headTitle={"Information de la campagne"} isActive={true}>

                        <form>
                            <div className="row">
                                <div className="col">
                                    <IziSelectField label={'Type de campagne'} onChange={(event) => onChangeType(event)}
                                                    type={'text'}
                                                    validateRule={['required']}
                                                    dataList={campaignType}
                                                    errorMessage={'Veuillez sélectionné un type'}
                                                    value={campaignForm.type} name={'type'} required={true}/>

                                </div>
                                <div className="col">
                                    <IziInputField label={'Tite de la campagne'} onChange={onChange} type={'text'}
                                                   validateRule={['required']}
                                                   errorMessage={'Veuillez saisir le titre de la campagne'}
                                                   value={campaignForm.label} name={'label'} required={true}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <IziSelectField label={'Canaux d\'envoie'} onChange={(event) => onChangeCanal(event)}
                                                    type={'text'}
                                                    validateRule={['required']}
                                                    dataList={campaignCanal}
                                                    isMulti={true}
                                                    errorMessage={'Veuillez sélectionné les canaux de diffusion'}
                                                    value={campaignForm.canals} name={'canals'} required={true}/>

                                </div>
                            </div>

                        </form>
                        <div className="d-flex justify-content-end py-6 gap-3">
                            <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false}
                                       onClick={() => handleMove(true)}>
                                Suivant
                            </IziButton>
                        </div>
                    </StepContent>
                }
                {
                    stepMove === 1 && <StepContent headTitle={"Locataires à cibler"} isActive={true}>
                        <div>
                            <ul>

                            </ul>
                            {
                                campaignForm.tenants.map((tenant) => {
                                    return <li className={"fw-bold fs-2x"} key={tenant.value.contact}>{tenant.label} {tenant.value.contact}</li>
                                })
                            }
                        </div>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <IziSelectField label={'Locataire cible'}
                                                    onChange={(event) => onChangeTenants(event)} type={'text'}
                                                    validateRule={['required']}
                                                    dataList={tenantGroup}
                                                    isMulti={true}
                                                    errorMessage={'Veuillez sélectionné au moins un locataire'}
                                                    value={campaignForm.tenants} name={'tenants'} required={true}/>

                                </div>

                            </div>
                        </form>
                        <div className="d-flex justify-content-end py-6 gap-3">
                            <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false}
                                       onClick={() => handleMove(false)}>
                                Précedent
                            </IziButton>
                            <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false}
                                       onClick={() => handleMove(true)}>
                                Suivant
                            </IziButton>
                        </div>
                    </StepContent>
                }
                {
                    stepMove === 2 && <StepContent headTitle={"Planification de la campagne"} isActive={true}>
                        <form>
                            <div className="row">
                                {/*<div className="col">*/}
                                {/*    <IziInputField label={'Date de rappel'} onChange={onChange} type={'text'}*/}
                                {/*                   validateRule={['required']}*/}
                                {/*                   errorMessage={'Veuillez saisir le titre de la campagne'}*/}
                                {/*                   value={campaignForm.relanceDate} name={'relanceDate'} required={true}/>*/}

                                {/*</div>*/}
                                <div className="col">
                                    <IziInputField label={'Nombre de mois'} onChange={onChange} type={'number'}
                                                   validateRule={['required']}
                                                   errorMessage={'Veuillez saisir le titre de la campagne'}
                                                   value={campaignForm.nbrMonth} name={'nbrMonth'} required={true}/>
                                </div>
                                <div className="col">
                                    <IziDatePicker onChange={onChangeRelanceDates} values={campaignForm.relanceDate}
                                                   label={'Date de rappel'} name={'relanceDate'}></IziDatePicker>
                                </div>
                            </div>
                        </form>
                        <div className="d-flex justify-content-end py-6 gap-3">
                            <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false}
                                       onClick={() => handleMove(false)}>
                                Précedent
                            </IziButton>
                            <IziButton buttonKtType={'button'} size={'btn-lg'} isProgress={false}
                            >
                                Créer la campagne
                            </IziButton>
                        </div>

                    </StepContent>
                }
            </IziStepper>
        </>
    );
};

export default NewCampaign;
