import React, {useState} from "react";
import IziInputField from "../../shared/components/form/IziInputField";
import {useDispatch, useSelector} from "react-redux";
import {selectUserInfos, setAccountInfos} from "../../store/user-register/CustomerReducer";

export const AccountForm = () => {
    const getUserInfos = useSelector(selectUserInfos)
    const [registerAccountInfos, setRegisterAccount] = useState({
        email: "",
        username: "",
        phone_number: getUserInfos.contact,
        userPassword: "",
        userRole:"admin",
    });
    const dispatch  = useDispatch()
    const accountValidator = ()=>{
        return (
            registerAccountInfos.userPassword.length >= 8
            && registerAccountInfos.username.length > 2
        )
    }
    const onChange = (event) => {
        const { name, value } = event.target;
        const oldDatas = {...registerAccountInfos, [name] : value};
        setRegisterAccount(oldDatas);
        if (accountValidator()){
            dispatch(setAccountInfos(registerAccountInfos))
        }
    };


    return (
        <>
            <div className="pb-10 pb-lg-15">
                <h2 className="fw-bold text-gray-900">Informations du compte</h2>

                <div className="text-muted fw-semibold fs-6">
                    Veuillez renseigner vos identifiants. (assurez-vous d'avoir mémorisé votre mot de passe)
                </div>
            </div>
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center form-label mb-3">
                    Identifiants
                </label>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Email'} onChange={onChange} type={'email'} validateRule={[]}
                                       errorMessage={'Veuillez saisir un email'} value={registerAccountInfos.email}
                                       name={'email'} />

                    </div>
                    <div className="col">
                        <IziInputField label={'Mot de passe '} onChange={onChange} type={'password'} validateRule={['required']}
                                       errorMessage={'Veuillez saisir un mot de passe'} value={registerAccountInfos.userPassword}
                                       name={'userPassword'} required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Nom utilisateur'} onChange={onChange} type={'text'} validateRule={['required']}
                                       errorMessage={'Veuillez saisir un nom d\'utilisateur'} value={registerAccountInfos.username}
                                       name={'username'} required={true}/>

                    </div>
                    <div className="col">
                    </div>
                </div>

            </div>
        </>
    )
}