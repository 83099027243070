import React from 'react';
import StepItem from "./StepItem";

const StepAside = ({itemsList = []}) => {

    return (
        <div
            className="card bg-white shadow border-light d-flex justify-content-center justify-content-xl-start flex-row-auto sm:w-100 md:w-25 w-xl-300px w-xxl-400px">
            <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
                <div className="stepper-nav">
                    {
                        itemsList.map(stepElem => {
                           return <StepItem key={stepElem.stepNum} desc={stepElem.desc} title={stepElem.title} isCurrent={stepElem.isCurrent} stepNum={stepElem.stepNum} hasNext={stepElem.nextElem} isFinal={stepElem.isFinal}/>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default StepAside;
