import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import TabPanne from "../../shared/TabPanne";
import EditBailInfos from "./components/EditBailInfos";
import EditRentInfos from "./components/EditRentInfos";
import EditTenant from "./components/EditTenant";

const ContractEdit = () => {
    const route = useNavigate();
    const backToList = ()=>{
        route(`/dashboard/contracts`)
    }
    const tabPanneList=['Locataire','Infos du bail',  'loyer'];
    const [activeTabContent,setActiveTabContent] = useState(0)
    const getTabIndex = (index) => {
        setActiveTabContent(index);
    }
    return (
        <>
            <TabPanne backTo={backToList} tabElement={tabPanneList}
                      tabIndex={getTabIndex}
                      tabTitle={"Modifier les informations du contrat"}>
                {activeTabContent === 0 && <EditTenant/>}
                {activeTabContent === 1 && <EditBailInfos/>}
                {activeTabContent === 2 && <EditRentInfos/>}
            </TabPanne>
        </>
    );
};

export default ContractEdit;
