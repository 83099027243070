import React from 'react';

const CampaignCard = ({campaign}) => {
    return (
        <>
            <div className="col">
                <div className="card bg-white shadow my-2 border-1 border-light">
                    <div className="card-body d-flex flex-center flex-column py-9 px-5">
                        <div className="symbol symbol-75px symbol-circle mb-5">
                        <span className="symbol-label fs-2x fw-semibold text-info bg-light-primary">
                            <i className="bi bi-envelope-at fs-3x"></i>
                        </span>
                            {campaign.status=='102'&& <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-30px w-30px ms-n3 mt-n3"></div>}
                            {campaign.status=='101'&& <div className="bg-info position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-30px w-30px ms-n3 mt-n3"></div>}
                            {campaign.status=='103'&& <div className="bg-warning position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-30px w-30px ms-n3 mt-n3"></div>}
                        </div>

                        <p  className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">N°: {campaign.uid}</p>
                        <div className="d-flex flex-center flex-wrap mb-4 w-100">
                            <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                                {campaign.status=='102' &&<div className="fs-6 fw-bold text-gray-700">En attente</div>}
                                {campaign.status=='101' &&<div className="fs-6 fw-bold text-gray-700">En cours</div>}
                                {campaign.status=='103' &&<div className="fs-6 fw-bold text-gray-700">Terminé</div>}
                                <div className="fw-semibold text-gray-400">Statut</div>
                            </div>

                            <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                                <div className="fs-6 fw-bold text-gray-700">{campaign.start_date}</div>
                                <div className="fw-semibold text-gray-400">Début</div>
                            </div>
                            <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                                <div className="fs-6 fw-bold text-gray-700">{campaign.canals.map((canal,i) =>  <span key={"can-"+i}>-{canal}</span>)}</div>
                                <div className="fw-semibold text-gray-400">Canaux</div>
                            </div>
                            <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                                <div className="fs-6 fw-bold text-gray-700">{campaign.type}</div>
                                <div className="fw-semibold text-gray-400">Type</div>
                            </div>
                            <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                                <div className="fs-6 fw-bold text-gray-700">{campaign.created_at}</div>
                                <div className="fw-semibold text-gray-400">Ajouté le</div>
                            </div>
                            <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                                <div className="fs-6 fw-bold text-gray-700">{campaign.tenants.length}</div>
                                <div className="fw-semibold text-gray-400">Locataires</div>
                            </div>
                        </div>
                        <div className="d-flex flex-center flex-wrap gap-3 w-100">
                            <button className="btn btn-sm btn-light-primary btn-flex btn-center"
                                    data-kt-follow-btn="true">
                                <i className="ki-outline ki-eye following fs-3"></i>
                                <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                                <span className="indicator-label">Détails</span>
                            </button>
                            <button className="btn btn-sm btn-light-primary btn-flex btn-center"
                                    data-kt-follow-btn="true">
                                <i className="ki-outline bi-pencil-square following fs-3"></i>
                                <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                                <span className="indicator-label">Modifier</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default CampaignCard;
