import { configureStore, combineReducers } from '@reduxjs/toolkit'
import CustomerReducer from "./user-register/CustomerReducer";
import AddPropertyReducer from "./add-property/AddPropertyReducer";
import AuthReducer from "./authentification/AuthReducer";
import PropertyFilterReducer from "./property-filter/PropertyFilterReducer";
import storage from "redux-persist/lib/storage";
import {persistReducer, persistStore} from "redux-persist";
import AddContractReducer from "./add-contract/AddContractReducer";
const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist:['auth', 'property', 'property_filter','customer']
};
const reducer = combineReducers({
    customer: CustomerReducer,
    auth: AuthReducer,
    property: AddPropertyReducer,
    property_filter: PropertyFilterReducer,
    add_contract: AddContractReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
    reducer: persistedReducer,
})
const persistor = persistStore(store);

export { store, persistor };