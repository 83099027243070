import React, {useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import IziTextAreaField from "../../../shared/components/form/IziTextAreaField";
import IziButton from "../../../shared/button/IziButton";

const AddKeysForm = ({getItem}) => {
    const [keys, setKeys]= useState({
        type:'',
        number:'',
        description:'',
    });
    const onChangeKey = (event) => {
        const { name, value, type } = event.target;
        const isCheckbox = type === "checkbox";
        setKeys({
            ...keys,
            [name]: isCheckbox ?[value] : value,
        });

    };
    const sendItemData = ()=>{
        getItem(keys);
        setKeys({
            type:'',
            number:'',
            description:'',
        });
    }
    return (
        <div className={"border-2 border-primary border border-dashed rounded p-3 mb-3"}>
            <div className="row">
                <div className="col">
                    <IziInputField label={'type'} onChange={(event)=>onChangeKey(event)} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir la designation du bien'} value={keys.type} name={'type'} required={true}/>

                </div>
                <div className="col">
                    <IziInputField label={'Nombre de paire'} onChange={onChangeKey} type={'number'} validateRule={[]} errorMessage={'Veuillez saisir le prénom du membre'} value={keys.number} name={'number'}  required={true}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <IziTextAreaField onChange={onChangeKey} name="description" value={keys.description} label={"Description"} ></IziTextAreaField>
                </div>
            </div>
            <IziButton onClick={sendItemData}>Ajouter</IziButton>
        </div>
    );
};

export default AddKeysForm;
