import React from 'react';
import Stepper from "./components/stepper/Stepper";

const Register = () => {
    return (
        <div id="kt_app_content" className="app-content  flex-column-fluid ">
            <h3 className="text-center fw-bold">Création de compte</h3>
            <div id="kt_app_content_container" className="app-container  container-fluid ">
                <Stepper></Stepper>
            </div>
        </div>
    );
};

export default Register;