import React from 'react';

const StepItem = (props) => {
    const {itemLabel, isCurrent} = props;
    return (
        <div className={isCurrent === false?"stepper-item me-5 me-md-15 pending":"stepper-item me-5 me-md-15 current"} data-kt-stepper-element="nav">
            <h3 className="stepper-title">
                {itemLabel}
            </h3>
        </div>
    );
};

export default StepItem;