import React from 'react';

const Backdrop = () => {
    function toggleMobile(){
        document.getElementById('kt_app_sidebar').classList.remove("drawer");
        document.getElementById('kt_app_sidebar').classList.remove("drawer-start");
        document.getElementById('kt_app_sidebar').classList.remove("drawer-on");
        document.getElementById('drawer-bg').style.display = 'none';
    }
    return (
        <div style={{zIndex: 105, display:"none"}} id="drawer-bg" className="drawer-overlay" onClick={toggleMobile}></div>
    );
};

export default Backdrop;