import React, {useEffect, useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziButton from "../../../shared/button/IziButton";
import axios_http from "../../../http/axios-plugin";
import endpoint from "../../../endpoint/endpoint.ts";
import IziCheckboxField from "../../../shared/components/form/IziCheckboxField";
import IziFileField from "../../../shared/components/form/IziFileField";
import {useDispatch} from "react-redux";
import {setContractInfos} from "../../../store/add-contract/AddContractReducer";
import {errorAlert} from "../../../shared/helper";

export const bailForm = {
    start_date:"",
    end_date:"",
    subject:"",
    rent_revision_date:"",
    rent_revision_amount:0,
    bail_periodicity:null,
    bail_date:Date(),
    bail_model:null,
    bail_other_model:false,
    bail_other_model_file:null,
}
const Bail = () => {
    const saveState = useDispatch();
    const bailFormValidator = ()=>{
        const {start_date, bail_other_model_file, bail_other_model
            , bail_model, bail_periodicity, end_date, rent_revision_amount,
            rent_revision_date, subject} = {...bailInfos}
        return start_date.length > 6 && end_date.length > 6 && bail_periodicity !== null && subject.length > 3 &&
            Number(rent_revision_amount) > 0 && rent_revision_date.length > 6
            && ((bail_model !== null && bail_other_model === false) || (bail_other_model_file !== null && bail_other_model === true))
    }

    const [bailInfos, setBailInfos] = useState(bailForm);
    const bailPeriodicy = [
        {label:"Journalier", value:"Journalier"},
        {label:"Hebdomadaire", value:"Hebdomadaire"},
        {label:"Mensuelle", value:"Mensuelle"},
        {label:"Trimestrielle", value:"Trimestrielle"},
        {label:"Semestrielle", value:"Semestrielle"},
        {label:"Annuelle", value:"Annuelle"},
    ];
    const [bailModel, setBailModel] =useState([]);
    const onChange = (event) => {
        const { name, value, type, checked } = event.target;
        setBailInfos((prevState)=>(
            {
                ...prevState,
                [name]:type === 'checkbox' ? checked : value,
            }
        ));
        saveBailData();
    };
    const onChangeFile = (event)=>{
        setBailInfos((prevState)=>({
            ...prevState,
            'bail_other_model_file': event[0]}));
        saveBailData();

    }
    useEffect(() => {
        axios_http.get(endpoint.getGlobalContractTemplate).then((template_data)=>{
            const formatData = [...template_data.data.data];
            formatData.map(tempplate=>{
                tempplate.value = tempplate.id
                return tempplate
            });
            setBailModel([...formatData]);
        })
    }, []);
    const onChangePeriod = (event)=>{
        setBailInfos((prevState)=>({
            ...prevState, 'bail_periodicity': event,
        }));
        saveBailData()
    }
    const saveBailData = ()=>{
        if (bailFormValidator()){
            const bail_start_date = Date(bailInfos.start_date);
            const bail_end_date = Date(bailInfos.end_date);
            if (bail_start_date >= bail_end_date) {

                if (bailInfos.bail_other_model && bailInfos.bail_other_model_file && bailInfos.bail_other_model_file instanceof File){
                    const bail_file_path = URL.createObjectURL(bailInfos.bail_other_model_file)
                    const all_bail_infos = {...bailInfos}
                    all_bail_infos.bail_other_model_file = bail_file_path;
                    setBailInfos({...all_bail_infos});
                    saveState(setContractInfos(bailInfos));
                }else {
                    saveState(setContractInfos(bailInfos));
                }
            }else {
                errorAlert("Ooops!", "Veuillez bien renseigné la periode d'execution du contrat", "compris").then()
            }
        }
    }
    const onChangeModel = (event)=>{
        setBailInfos((prevState)=>({
            ...prevState,  'bail_model': event
        }));
        saveBailData()
    }
    return (
        <>
            <form>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Date de debut'} onChange={onChange} type={'date'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir la date de debut du bail'}
                                       value={bailInfos.start_date} name={'start_date'} required={true}/>
                    </div>
                    <div className="col">
                        <IziInputField label={'Date de fin'} onChange={onChange} type={'date'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir la date de fin du bail'}
                                       value={bailInfos.end_date} name={'end_date'} required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Objet du contrat'} onChange={onChange} type={'text'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir la date de debut du bail'}
                                       value={bailInfos.subject} name={'subject'} required={true}/>
                    </div>
                    <div className="col">
                        <IziSelectField onChange={(event) => onChangePeriod(event)}
                                        label={'Périodicités de la location'} value={bailInfos.bail_periodicity}
                                        name={'bail_periodicity'} dataList={bailPeriodicy}></IziSelectField>

                    </div>

                </div>
                <div className="row">

                    <div className="col-6">
                        {
                            bailInfos.bail_other_model === false && <>
                                <IziSelectField onChange={(event) => onChangeModel(event)}
                                                label={'Modèle à utiliser pour le bail'} value={bailInfos.bail_model}
                                                name={'bail_model'} dataList={bailModel}></IziSelectField>
                                <IziButton>Voir</IziButton>
                            </>
                        }
                        {
                            bailInfos.bail_other_model === true && <>
                                <IziFileField getfile={(event) => onChangeFile(event)}
                                              id={"bailFile"}></IziFileField>
                            </>
                        }
                    </div>
                    <div className="col-6 pt-6">
                        <IziCheckboxField onChange={onChange} name={'bail_other_model'}
                                          value={bailInfos.bail_other_model}
                                          label={"Charger un mon contrat"}></IziCheckboxField>
                        {bailInfos.bail_other_model === true &&
                            <p>
                                <span
                                    className="col-6 badge badge-primary p-2">{bailInfos?.bail_other_model_file?.name} ({(bailInfos.bail_other_model_file?.size??10) / 1000} Kb)</span>
                            </p>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <IziInputField label={'Montant de la revision du contrat'} onChange={onChange} type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir la date de debut du bail'}
                                       value={bailInfos.rent_revision_amount} name={'rent_revision_amount'}
                                       required={true}/>
                    </div>
                    <div className="col">
                        <IziInputField label={'Date de revision du contrat'} onChange={onChange} type={'date'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir la date de fin du bail'}
                                       value={bailInfos.rent_revision_date} name={'rent_revision_date'}
                                       required={true}/>
                    </div>

                </div>
            </form>
        </>
    );
};

export default Bail;
