import React from 'react';
import {Outlet} from "react-router-dom";
import Header from "../../main/components/header/Header";
import SideBar from "../../main/components/sidebar/SideBar";
import {Notification} from "../../main/components/sidebar/Notification";
import Backdrop from "./Backdrop";

const DashboardLayout = () => {

    return (
        <div className="app-page  flex-column flex-column-fluid" id="kt_app_page">
            <Header></Header>
            <div className="app-wrapper  flex-column flex-row-fluid" id="kt_app_wrapper">
                <SideBar></SideBar>
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                    <div className="d-flex flex-column flex-column-fluid">
                        <div id="kt_app_content" className="app-content  flex-column-fluid ">
                            <div id="kt_app_content_container" className="app-container  container-fluid ">
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </div>
                <Notification></Notification>
            </div>
            <Backdrop/>
        </div>
    );
};

export default DashboardLayout;