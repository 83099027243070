import Swal from "sweetalert2";

export  const notificationAlert = (title = 'Attention!', msg='Ete vous sure de vouloir de le faire', textAnnuler='Annuler', confirmText='oui') => {
   return  Swal.fire({
        title: title,
        text: msg,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText:textAnnuler,
        cancelButtonColor: "#d33",
        confirmButtonText: confirmText,
    })
};
export  const successAlert = ( msg='succès', confirmText='ok') => {
    return Swal.fire({
        title: 'Félicitation',
        text: msg,
        icon: "success",
        confirmButtonColor: "#29419A",
        confirmButtonText: confirmText,
    })
};
export  const errorAlert = (title = 'Ooops!', msg='Erreur', confirmText='ok') => {
    return  Swal.fire({
        title: title === 'null'?'Ooops!': title,
        text: msg,
        icon: "error",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmText,
    })
};
export const toastAlert = ( msg='Merci', status='success')=>{
    return Swal.fire({
        toast: true,
        icon: status,
        title: msg,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000 // Duration of the toast in milliseconds
    });
}

export const Validator = (inputValue, validationRule= ['required', 'number','max:10'])=>{
    const errors = [];
    if (validationRule.length > 0) {
        validationRule.forEach(validation => {
            if(validation === 'required'&&(typeof inputValue === 'string' && inputValue.length < 1)) errors.push({required: true})
            // if (validation === '')
        })
    }
    console.log(errors, inputValue)
    return errors
}
export const stepValidation = (fields, dataForm) => {
    let hasError = 0;
    fields.forEach(field => {
        hasError = dataForm[field].length === 0 ?  hasError + 1 : hasError;
    });
    return hasError <= 0 ;
}
export const loadDbJson = (key)=>{
    try {
        const jsonString =localStorage.getItem(key);
        if(JSON.parse(jsonString)){
            return  JSON.parse(jsonString);
        }
        return null
    }catch (err) {
        console.error(err);
    }
}
export const writeDbJson = (dataUpdate, key)=>{
    try {
        localStorage.setItem(key,  JSON.stringify(dataUpdate))
    }catch (err) {
        console.error(err);
    }
}