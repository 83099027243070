import React from 'react';

const UserInfos = ({userDatas}) => {
    return (
        <>
            <div className="card-body fs-5 p-9">
                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">Nom complet</label>

                    <div className="col-lg-8">
                        <span className="fw-bold fs-5 text-gray-800">Max Smith</span>
                    </div>
                </div>

                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">Compagnie</label>

                    <div className="col-lg-8 fv-row">
                        <span className="fw-semibold text-gray-800 fs-5">Origa</span>
                    </div>
                </div>

                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">
                        Contact

                        <span className="ms-1" data-bs-toggle="tooltip" aria-label="Phone number must be active"
                              data-bs-original-title="Phone number must be active" data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                    </label>

                    <div className="col-lg-8 d-flex align-items-center">
                        <span className="fw-bold fs-5 text-gray-800 me-2">044 3276 454 935</span>
                        {/*<span className="badge badge-success">Verified</span>*/}
                    </div>
                </div>

                <div className="row mb-7">
                    <label className="col-lg-4 fw-semibold text-muted">Ville</label>

                    <div className="col-lg-8">
                        <span className="fw-bold fs-5 text-gray-800 me-2">Okalama</span>
                        {/*<a href="#" className="fw-semibold fs-5 text-gray-800 text-hover-primary">keenthemes.com</a>*/}
                    </div>
                </div>

                <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">
                        Pays

                        <span className="ms-1" data-bs-toggle="tooltip" aria-label="Country of origination"
                              data-bs-original-title="Country of origination" data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                    </label>

                    <div className="col-lg-8">
                        <span className="fw-bold fs-5 text-gray-800">Germany</span>
                    </div>
                </div>



                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                    <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>

                    <div className="d-flex flex-stack flex-grow-1 ">
                        <div className=" fw-semibold">
                            <h4 className="text-gray-900 fw-bold">Nous avons besoin de votre attention!</h4>

                            <div className="fs-5 text-gray-700 ">Votre paiement expire bientôt. Pensez à le renouvellé,
                                 <a className="fw-bold" href="#">Ici</a>.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default UserInfos;
