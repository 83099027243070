import {createSlice} from "@reduxjs/toolkit";

export const addPropertySlice = createSlice({
    name: 'property',
    initialState: {
        propertyType: null,
        propertyChoose: null,
        propertyGeneralInfos: null,
        propertyPieces: null,
        propertyFullAddress: null,
        propertyOwner: null,
    },
    reducers:{
        setPropertyType: (state, action)=>{
            state.propertyType  = action.payload
        },
        setPropertyChoose: (state, action)=>{
            state.propertyChoose  = action.payload
        },
        setPropertyGeneralInfos: (state, action)=>{
            state.propertyGeneralInfos  = action.payload
        },
        setPropertyPieces: (state, action)=>{
            state.propertyPieces  = action.payload
        },
        setPropertyFullAddress: (state, action)=>{
            state.propertyFullAddress  = action.payload
        },
        setPropertyOwner: (state, action)=>{
            state.propertyOwner  = action.payload
        },
    }
});
export const {setPropertyType,
    setPropertyGeneralInfos,
    setPropertyImages,
    setPropertyChoose,
    setPropertyPieces,setPropertyFullAddress,  setPropertyOwner} = addPropertySlice.actions;

export const selectPropertyType = (state) => state.property.propertyType;
export const selectPropertyGeneralInfos = (state) => state.property.propertyGeneralInfos;
export const selectPropertyPieces = (state) => state.property.propertyPieces;
export const selectPropertyFullAddress = (state) => state.property.propertyFullAddress;
export const selectPropertyOwner = (state) => state.property.propertyOwner;
export const selectPropertyChoose = (state) => state.property.propertyChoose;
export default addPropertySlice.reducer