import React, {useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import IziButton from "../../../shared/button/IziButton";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import {
    piecesCeilingtype,
    piecesDoortype, piecesGlazingtype, piecesGroundtype,
    PiecesPart,
    piecesState,
    piecesType,
    piecesWaltype
} from "../../models/AddPieces.ts";
const AddPiecesForm = ({getItem}) => {
    const [door, setDoor]= useState({...PiecesPart});
    const [wal, setWal]= useState({...PiecesPart});
    const [ground, setGround]= useState({...PiecesPart});
    const [glazing, setGlazing]= useState({...PiecesPart});
    const [ceiling, setCeiling]= useState({...PiecesPart});
    const [lighting_switches, set_lighting_switches]= useState([]);
    const [electrical_outlets, set_electrical_outlets]= useState([]);
    const [lightingForm, setLightingForm] = useState({
        label:"",
        state:{}
    });
    const [electricalOutletForm, setElectricalOutletForm] = useState({
        label:"",
        state:{}
    });
    const onChangeLightForm = (event, mode=1)=>{
        if (mode===1){
            const {name, value} = event.target;
            setLightingForm({...lightingForm, [name]:value})
        }else {
            setLightingForm({...lightingForm, "state":event})
        }

    }
    const onChangeElectricalOutletForm = (event, mode=1)=>{
        if (mode===1){
            const {name, value} = event.target;
            setElectricalOutletForm({...electricalOutletForm, [name]:value})
        }else {
            setElectricalOutletForm({...electricalOutletForm, "state":event})
        }

    }
    const addLighting = ()=>{
        const oldData = [...lighting_switches,{...lightingForm}];
        set_lighting_switches(oldData)
        setLightingForm({
            label:"",
            state:{}
        })
    }
    const addElectricalOutlet = ()=>{
        const oldData = [...electrical_outlets,{...electricalOutletForm}];
        set_electrical_outlets(oldData)
        setElectricalOutletForm({
            label:"",
            state:{}
        })
    }
    const removeLighting = (itemIndex) =>{
        const oldDatas = [...lighting_switches.filter((elem, i)=> i !== itemIndex)];
        set_lighting_switches(oldDatas)
    }
    const removeElectricalOutlet = (itemIndex) =>{
        const oldDatas = [...electrical_outlets.filter((elem, i)=> i !== itemIndex)];
        set_electrical_outlets(oldDatas)
    }

    const [pieces, setPieces]= useState({
        label:'',
        pieceType: {},
        door:{},
        wal:{},
        ground:{},
        glazing:{},
        ceiling:{},
        lighting_switches:[],
        electrical_outlets:[],

    });

    const onChange = (event) => {
        const { name, value } = event.target;
        setPieces({
            ...pieces,
            [name]: value,
        });
    };
    const onChangeType = (event, mode, isOther = false)=>{
        switch (mode) {
            case 1:
                if (isOther){
                    setDoor({
                        ...door,
                        'type_elem_other': event.value});

                }else {
                    setDoor({
                        ...door,
                        'type_elem': event,});
                }
                break;
            case 2:
                if (isOther){
                    setWal({
                        ...wal,
                        'type_elem_other': event.value,
                    });
                } else {
                setWal({
                    ...wal,
                    'type_elem': event,
                });}
                break;
            case 3:
                if (isOther){
                    setGround({
                        ...ground,
                        'type_elem_other': event.value});

                }else {
                    setGround({
                        ...ground,
                        'type_elem': event,});
                }
                break;
            case 4:
                if (isOther){
                    setGlazing({
                        ...glazing,
                        'type_elem_other': event.value});

                }else {
                    setGlazing({
                        ...glazing,
                        'type_elem': event});
                }
                break;
            case 5:
                if (isOther){
                    setCeiling({
                        ...ceiling,
                        'type_elem_other': event.value});
                }else {
                    setCeiling({
                        ...ceiling,
                        'type_elem': event});
                }
                break;
            default:
                if (isOther){
                    setDoor({
                        ...door,
                        'type_elem_other': event.value});

                }else {
                    setDoor({
                        ...door,
                        'type_elem': event,});
                }
                break;

        }
        // console.warn(door);
    }
    const onChangeState = (event, mode)=>{
        switch (mode) {
            case 1:
                setDoor({
                    ...door,
                    'state': event,});
                break;
            case 2:
                setWal({
                    ...wal,
                    'state': event,});
                break;
            case 3:
                setGround({
                    ...ground,
                    'state': event,});
                break;
            case 4:
                setGlazing({
                    ...glazing,
                    'state': event,});
                break;
            case 5:
                setCeiling({
                    ...ceiling,
                    'state': event,});
                break;
            default:
                setDoor({
                    ...door,
                    'state': event,});
                break;

        }

    }

    const onChangePieceType = (event)=>{
        setPieces({
            ...pieces,
            'pieceType': event,});
    }

    const sendItemData = ()=>{
        pieces.wal = wal;
        pieces.door = door;
        pieces.ground = ground;
        pieces.glazing = glazing;
        pieces.ceiling = ceiling;
        pieces.lighting_switches = lighting_switches;
        pieces.electrical_outlets = electrical_outlets;
        getItem(pieces);

    }

    return (
        <div className={"border-primary border border-dashed p-3 rounded"}>
            <div>
                <form className="container">
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangePieceType(event)} label={'Type de pièces'}
                                            value={pieces.pieceType} name={'pieceType'}
                                            dataList={piecesType}></IziSelectField>
                        </div>

                        <div className="col-6">
                            <IziInputField label={'Designation de la pièces'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir la designation'} value={pieces.label}
                                           name={'label'} required={true}/>
                        </div>
                    </div>

                    <h4>Porte</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeType(event, 1)} label={'Type de matériau'}
                                            value={door.type_elem} name={'type_elem'}
                                            dataList={piecesDoortype}></IziSelectField>
                            {door.type_elem.value == "Autre" &&
                                <IziInputField label={'Preciser'} onChange={(event) => onChangeType(event, 2, true)}
                                               type={'text'} validateRule={['required']}
                                               errorMessage={'Veuillez saisir une valeur'} value={door.type_elem_other}
                                               name={'type_elem_other'} required={true}/>
                            }
                        </div>
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 1)} label={'État'}
                                            value={door.state} name={'state'} dataList={piecesState}></IziSelectField>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4>Mur</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeType(event, 2)} label={'Type de matériau'}
                                            value={wal.type_elem} name={'type_elem'}
                                            dataList={piecesWaltype}></IziSelectField>
                            {wal.type_elem.value == "Autre" &&
                                <IziInputField label={'Preciser'} onChange={(event) => onChangeType(event, 2, true)}
                                               type={'text'} validateRule={['required']}
                                               errorMessage={'Veuillez saisir une valeur'} value={wal.type_elem_other}
                                               name={'type_elem_other'} required={true}/>
                            }
                        </div>
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 2)} label={'État'}
                                            value={wal.state} name={'state'} dataList={piecesState}></IziSelectField>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4>Sol</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeType(event, 3)} label={'Type de matériau'}
                                            value={ground.type_elem} name={'type_elem'}
                                            dataList={piecesGroundtype}></IziSelectField>
                            {ground.type_elem.value == "Autre" &&
                                <IziInputField label={'Preciser'} onChange={(event) => onChangeType(event, 3, true)}
                                               type={'text'} validateRule={['required']}
                                               errorMessage={'Veuillez saisir une valeur'}
                                               value={ground.type_elem_other} name={'type_elem_other'} required={true}/>
                            }
                        </div>
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 3)} label={'État'}
                                            value={ground.state} name={'state'} dataList={piecesState}></IziSelectField>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4>Vitrage</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeType(event, 4)} label={'Type de matériau'}
                                            value={glazing.type_elem} name={'type_elem'}
                                            dataList={piecesGlazingtype}></IziSelectField>
                            {glazing.type_elem.value == "Autre" &&
                                <IziInputField label={'Preciser'} onChange={(event) => onChangeType(event, 4, true)}
                                               type={'text'} validateRule={['required']}
                                               errorMessage={'Veuillez saisir une valeur'}
                                               value={glazing.type_elem_other} name={'type_elem_other'}
                                               required={true}/>
                            }
                        </div>
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 4)} label={'État'}
                                            value={glazing.state} name={'state'}
                                            dataList={piecesState}></IziSelectField>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4>Plafond</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeType(event, 5)} label={'Type de matériau'}
                                            value={ceiling.type_elem} name={'type_elem'}
                                            dataList={piecesCeilingtype}></IziSelectField>
                            {ceiling.type_elem.value == "Autre" &&
                                <IziInputField label={'Preciser'} onChange={(event) => onChangeType(event, 5, true)}
                                               type={'text'} validateRule={['required']}
                                               errorMessage={'Veuillez saisir une valeur'}
                                               value={ceiling.type_elem_other} name={'type_elem_other'}
                                               required={true}/>
                            }
                        </div>
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 5)} label={'État'}
                                            value={ceiling.state} name={'state'}
                                            dataList={piecesState}></IziSelectField>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4>Prises électriques</h4>
                    <div className="row gap-1">
                        {
                            lighting_switches.map((light, index) => <span className="col-3 badge badge-primary"
                                                                   key={light}>{light.label} : {light.state?.value}
                                <span className="badge badge-square badge-danger mx-2 cursor-pointer" onClick={()=>removeLighting(index)}>X</span></span>)
                        }
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <IziInputField label={'Libellé'} onChange={(event) => onChangeLightForm(event, 1)}
                                           type={'text'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir le libellé'}
                                           value={lightingForm.label} name={'label'} required={true}/>
                        </div>
                        <div className="col-4">
                            <IziSelectField onChange={(event) => onChangeLightForm(event, 2)} label={'État'}
                                            value={lightingForm.state} name={'state'}
                                            dataList={piecesState}></IziSelectField>
                        </div>
                        <div className="col-4 mt-4">
                            <IziButton onClick={addLighting}>Ajouter</IziButton>
                        </div>
                    </div>
                </form>
                <form className="container">
                    <h4>Éclairage et interrupteurs</h4>
                    <div className="row gap-1">
                        {
                            electrical_outlets.map((light, index) => <span className="col-3 badge badge-primary"
                                                                    key={light}>{light.label} : {light.state?.value}
                                <span className="badge badge-square badge-danger mx-3 cursor-pointer"
                                      onClick={() => removeElectricalOutlet(index)}>X</span></span>)
                        }
                    </div>
                    <div className="row">
                    <div className="col-4">
                            <IziInputField label={'Libellé'} onChange={(event) => onChangeElectricalOutletForm(event, 1)}
                                           type={'text'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir le libellé'}
                                           value={electricalOutletForm.label} name={'label'} required={true}/>
                        </div>
                        <div className="col-4">
                            <IziSelectField onChange={(event) => onChangeElectricalOutletForm(event, 2)} label={'État'}
                                            value={electricalOutletForm.state} name={'state'}
                                            dataList={piecesState}></IziSelectField>
                        </div>
                        <div className="col-4 mt-4">
                            <IziButton onClick={addElectricalOutlet}>Ajouter</IziButton>
                        </div>
                    </div>
                </form>
                <div className="row w-100">
                    <div className="col-12 d-flex justify-content-end">
                        <IziButton onClick={sendItemData}>Ajouter</IziButton>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default AddPiecesForm;
