import React from 'react';
import {useNavigate} from "react-router-dom";
import IziInputField from "../../../shared/components/form/IziInputField";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziButton from "../../../shared/button/IziButton";

const ReceiptPayment= () => {
    const route = useNavigate();
    const paymentMode = [
        {label:"Versement en Espèce", value:"Espèce"},
        {label:"Virement Bancaire", value:"Virement Bancaire"},
        {label:"Wave", value:"Wave"},
        {label:"Orange Money", value:"Orange money"},
        {label:"MTN Money", value:"MTN money"},
        {label:"Moov Money", value:"Moov money"},
    ];

    const backToList = ()=>{
        route('/dashboard/contract/rents');
    }
    return (
        <>
            <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                <div className="card card-flush pt-3 mb-5 mb-xl-10">
                    <div className="card-header">
                        <div className="card-title">
                            <h2 className="fw-bold">Modifier la quittance N°QMAMMDKKD55</h2>
                        </div>

                        <div className="card-toolbar">
                            <a href="#" className="btn btn-light-primary" onClick={backToList}>Retour</a>
                        </div>
                    </div>

                    <div className="card-body pt-3">
                        <div className="mb-10">
                            <h5 className="mb-4">Détails:</h5>

                            <div className="d-flex flex-wrap py-5">
                                <div className="flex-equal me-5">
                                    <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0 fs-4">
                                        <tbody>
                                        <tr>
                                            <td className="text-gray-500 min-w-175px w-175px">Bien concerné:</td>
                                            <td className="text-gray-800 min-w-200px">
                                                <a href="#" className="text-gray-800 text-hover-primary">Appartement amondji</a>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Locataire:</td>
                                            <td className="text-gray-800">
                                                Emma Smith                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Adresse:</td>
                                            <td className="text-gray-800">
                                                Floor 10, 101 Avenue of
                                                the Light Square, New York, NY, 10050.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Contact:</td>
                                            <td className="text-gray-800">(555) 555-1234</td>
                                        </tr>
                                        </tbody></table>
                                </div>

                                <div className="flex-equal">
                                    <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0 fs-4">
                                        <tbody>
                                        <tr>
                                            <td className="text-gray-500">Mois de :</td>
                                            <td className="text-gray-800">
                                                Janvier
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-gray-500">Loyer brute:</td>
                                            <td className="text-gray-800">
                                                $149.99 / Mois
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Charge:</td>
                                            <td className="text-gray-800">
                                                $49.99
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Loyer Total:</td>
                                            <td className="text-gray-800">$249 / Mois</td>
                                        </tr>
                                        </tbody></table>
                                </div>
                                <div className="flex-equal">
                                    <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0 fs-4">
                                        <tbody>
                                        <tr>
                                            <td className="text-gray-500">Montant payé :</td>
                                            <td className="text-gray-800">
                                                $49.99
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-gray-500">Montant Restant:</td>
                                            <td className="text-gray-800">
                                                $100
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-gray-500">Statut:</td>
                                            <td className="text-gray-800">
                                                non-payé
                                            </td>
                                        </tr>
                                        </tbody></table>
                                </div>
                            </div>
                        </div>

                        <div className="mb-0">
                            <div>
                                <div className="card card-flush pt-3 mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h2>Tout les paiements</h2>
                                        </div>
                                    </div>

                                    <div className="card-body pt-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-5" id="kt_table_customers_events">
                                                <tbody>
                                                <tr>
                                                    <td className="min-w-400px fs-5">
                                                        paiement ref <a href="#" className="fw-bold text-gray-800 text-hover-primary me-1">6742-7289</a> montant: $49  mode: Wave<span className="badge badge-light-success">Validé</span>
                                                    </td>
                                                    <td className="pe-0 text-gray-600 text-end min-w-200px">
                                                        21 Feb 2023, 10:30 am                            </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 className="mb-4">Effectuer un paiement:</h3>

                            <div className="row">
                                <div className="col">
                                    <IziInputField label={'Montant'}  type={'number'}
                                                   validateRule={['required']} errorMessage={'Veuillez saisir le montant du loyer'}  name={'rent_amount'} required={true}/>
                                </div>
                                <div className="col">
                                    <IziInputField label={'Référence'}  type={'text'}
                                                   validateRule={['required']} errorMessage={'Veuillez saisir le nombre de mois de  caution'}  name={'nbr_period_caution'}  required={true}/>
                                </div>
                                <div className="col">
                                    <IziSelectField  label={'Mode paiement'}  name={'payment_mode'} dataList={paymentMode}></IziSelectField>
                                </div>
                            </div>
                            <IziButton>Ajouter</IziButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReceiptPayment;
