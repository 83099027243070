import {createSlice} from "@reduxjs/toolkit";

export const addContractSlice = createSlice({
    name: 'add_contract',
    initialState: {
        propertyEnterInventoryExtra: null,
        propertyEnterInventoryExtraTwo: [],
        propertyEnterInventory: [],
        tenantChoose: null,
        tenantChooseGuard: null,
        isNewTenant: false,
        contractInfos: null,
        contractRent: null,
    },
    reducers:{
        setPropertyEnterInventory: (state, action)=>{
            state.propertyEnterInventory  = action.payload
        },
        setPropertyEnterInventoryExtra: (state, action)=>{
            state.propertyEnterInventoryExtra  = action.payload
        },
        setPropertyEnterInventoryExtraTwo: (state, action)=>{
            state.propertyEnterInventoryExtraTwo  = action.payload
        },
        setIsNewTenant: (state, action)=>{
            state.isNewTenant  = action.payload
        },
        setTenantChoose: (state, action)=>{
            state.tenantChoose  = action.payload
        },
        setTenantChooseGuard: (state, action)=>{
            state.tenantChooseGuard  = action.payload
        },
        setContractInfos: (state, action)=>{
            state.contractInfos  = action.payload
        },
        setContractRent: (state, action)=>{
            state.contractRent  = action.payload
        },
    }
});
export const {setPropertyEnterInventory,
    setIsNewTenant,
    setTenantChoose,
    setContractInfos,
    setContractRent, setPropertyEnterInventoryExtra,
    setPropertyEnterInventoryExtraTwo, setTenantChooseGuard} = addContractSlice.actions;

export const selectPropertyEnterInventory = (state) => state.add_contract.propertyEnterInventory;
export const selectIsNewTenant = (state) => state.add_contract.isNewTenant;
export const selectTenantChoose = (state) => state.add_contract.tenantChoose;
export const selectTenantChooseGuard = (state) => state.add_contract.tenantChooseGuard;
export const selectContractInfos = (state) => state.add_contract.contractInfos;
export const selectContractRent = (state) => state.add_contract.contractRent;
export const selectEnterInventoryExtra = (state) => state.add_contract.propertyEnterInventoryExtra;
export const selectEnterInventoryExtraTwo = (state) => state.add_contract.propertyEnterInventoryExtraTwo;
export default addContractSlice.reducer