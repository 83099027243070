import React, {useState} from 'react';
import IziInputField from "../../shared/components/form/IziInputField";
import Member from "../models/Member.ts";
import UserAccount from "../models/UserAccount.ts";
import IziButton from "../../shared/button/IziButton";
import {TabPane} from "react-bootstrap";
import TabPanne from "../../shared/TabPanne";
import Overview from "./Overview";
import EditAccount from "./EditAccount";
import EditSecurity from "./EditSecurity";

const MemberDetails = ({memberDetails=UserAccount, backToList}) => {
    const tabPanneList=['Infos Personnel', 'Compte', 'Sécurité'];
    const [activeTabContent,setActiveTabContent] = useState(0)
    const getTabIndex = (index) => {
        setActiveTabContent(index);
    }
    return (
        <>
            <TabPanne backTo={backToList} tabTitle={"Information du membre"} tabElement={tabPanneList} tabIndex={getTabIndex}>
                {
                    activeTabContent === 0 && <Overview memberInfos={memberDetails.user_infos}></Overview>}
                {
                    activeTabContent === 1 && <EditAccount userAccountInfos={memberDetails}></EditAccount>  }
                {
                    activeTabContent === 2 && <EditSecurity userAllAccess={{id_usr:memberDetails.id_usr,access:memberDetails.access}}></EditSecurity>
                }

            </TabPanne>
        </>

    );
};

export default MemberDetails;