import React from 'react';

const InventoryDetails = () => {
    return (
        <div className="card card-flush bg-white shadow border-1 border-light h-lg-100" id="kt_contacts_main">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                <div className="card-title">
                    <i className="ki-outline ki-information-3 fs-1 me-2"></i>
                    <h2>État des lieux associé au  contrat n-55555-55</h2>
                </div>

                <div className="card-toolbar gap-3">
                    <a href="#"
                       className="btn btn-sm btn-light btn-active-light-primary">
                        <i className="ki-outline ki-arrow-down fs-2"></i> Télécharger
                    </a>
                </div>
            </div>

            <div className="card-body pt-5">
                <div className="d-flex gap-7 align-items-center">
                    <div className="d-flex flex-column gap-2">
                        <h3 className="mb-0">Contrat du 2023-06-18</h3>

                        <div className="d-flex align-items-center gap-2">
                            <i className="ki-outline ki-calendar fs-2"></i>
                            <a href="#" className="text-muted text-hover-primary">Phase: Entrée</a>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                            <i className="ki-outline ki-calendar-2 fs-2"></i>
                            <a href="#" className="text-muted text-hover-primary">Date d'entrée: 2023-05-15 </a>
                        </div>
                    </div>
                </div>


                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                    role="tablist">

                    <li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                           data-bs-toggle="tab" href="#kt_contact_view_general" aria-selected="true" role="tab">
                            <i className="ki-outline ki-home fs-4 me-1"></i> Général
                        </a>
                    </li>


                    <li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4"
                           data-bs-toggle="tab" href="#kt_contact_view_meetings" aria-selected="false"
                           tabIndex="-1" role="tab">
                            <i className="ki-outline ki-more-2 fs-4 me-1"></i> Plus
                        </a>
                    </li>

                </ul>


                <div className="tab-content" id="">
                    <br/>
                    <div className="tab-pane fade show active" id="kt_contact_view_general" role="tabpanel">


                        <div className="d-flex flex-column gap-5 mt-7">

                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Locataire</div>
                                <div className="fw-bold fs-5">Hermine Treiviollette</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Adresse et contact</div>
                                <div className="fw-bold fs-5">Saint denis 78 rue orleand berger | +225 07 59 66 44 22</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Bailleur</div>
                                <div className="fw-bold fs-5">Angeline Immobilier | Yamoussoukro, Assabou place jean paul II</div>
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Statut</div>
                                <div className="fw-bold fs-5"> Approuvé</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Observations ou réserves</div>
                                <p>
                                    Emma Smith joined the team on September 2019 as a junior associate. She soon
                                    showcased her expertise and experience in knowledge and skill in the field,
                                    which was very valuable to the company. She was promptly promoted to senior
                                    associate on July 2020. <br/>
                                    <br/>
                                    Emma Smith now heads a team of 5 associates and leads the company's sales
                                    growth by 7%.
                                </p>
                            </div>
                            <br/>
                        </div>

                    </div>


                    <br/>
                    <div className="tab-pane fade" id="kt_contact_view_meetings" role="tabpanel">
                        <div className="row">
                            <div className="col">
                                <h4 className="fs-4 fw-bold text-center my-2">Abonnement</h4>
                            </div>
                        </div>

                        <div className="row w-100">
                            <div className="col">
                                <div className="d-flex flex-column gap-1">
                                    <div className="fw-bold text-muted">Électricité</div>
                                    <div className="fw-bold fs-5">Type Compteur: <span className="text-secondary">A Carte</span></div>
                                    <div className="fw-bold fs-5">N°Compteur: <span className="text-secondary">5354141022558558</span></div>
                                    <div className="fw-bold fs-5">Montant restant à payé: <span className="text-secondary">60 000 FCFA</span></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="d-flex flex-column gap-1">
                                    <div className="fw-bold text-muted">Eau</div>
                                    <div className="fw-bold fs-5">Type Compteur: <span className="text-secondary">Normal</span></div>
                                    <div className="fw-bold fs-5">N°Compteur: <span className="text-secondary">531022558558</span></div>
                                    <div className="fw-bold fs-5">Ancien index: <span className="text-secondary">640 m3</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h4 className="fs-4 fw-bold text-center my-2">Clés</h4>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-md-3 col-lg-4 col-sm-12">
                                <div className="d-flex flex-column gap-1">
                                    <div className="fw-bold text-muted">Type</div>
                                    <div className="fw-bold fs-5"><span className="text-secondary">Clés d’immeuble</span></div>
                                    <div className="fw-bold fs-5">Description: <p className="text-secondary">
                                        cle de l'immeuble B3
                                    </p></div>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4 col-sm-12">
                                <div className="d-flex flex-column gap-1">
                                    <div className="fw-bold text-muted">Type</div>
                                    <div className="fw-bold fs-5"><span className="text-secondary">Boites aux lettres</span></div>
                                    <div className="fw-bold fs-5">Description: <p className="text-secondary">
                                        Clé de la Boites aux lettres
                                    </p></div>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4 col-sm-12">
                                <div className="d-flex flex-column gap-1">
                                    <div className="fw-bold text-muted">Type</div>
                                    <div className="fw-bold fs-5"><span className="text-secondary">Clés d’immeuble</span></div>
                                    <div className="fw-bold fs-5">Description: <p className="text-secondary">
                                        cle de l'immeuble B3
                                    </p></div>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4 col-sm-12">
                                <div className="d-flex flex-column gap-1">
                                    <div className="fw-bold text-muted">Type</div>
                                    <div className="fw-bold fs-5"><span className="text-secondary">Clés d’immeuble</span></div>
                                    <div className="fw-bold fs-5">Description: <p className="text-secondary">
                                        cle de l'immeuble B3
                                    </p></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h4 className="fs-4 fw-bold text-center my-2">État des pièces</h4>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="d-flex flex-column h-400px overflow-auto">
                                    <div>
                                        <div className="fw-bold  fs-5 text-muted">Pièces</div>
                                        <div className="fw-bold fs-5"><span className="text-secondary">Salon</span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Mur</div>
                                        <div className="fw-bold fs-5 d-flex flex-sm-column flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Sonnette ou interphone</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Sol</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Vitrage</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Plafond</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Eclairage et interrupteurs</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Prises électriques</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                    </div>
                                    <div>
                                        <div className="fw-bold  fs-5 text-muted">Pièces</div>
                                        <div className="fw-bold fs-5"><span className="text-secondary">Chambre 1</span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Mur</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Sonnette ou interphone</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Sol</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Vitrage</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Plafond</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Eclairage et interrupteurs</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                        <hr className="fa-divide"/>
                                        <div className="fw-bold fs-5 text-muted">Prises électriques</div>
                                        <div className="fw-bold fs-5 d-flex flex-row gap-1">Type:<span className="text-secondary">Bois</span> | Etat:
                                            <span className="text-secondary">Bon</span> | Details<span className="text-secondary"><p>kjhgfdghjksluhicnjcbjb </p></span></div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="row border border-dashed border-gray-300 rounded  g-1 p-7">
                                    <div className="col-4 overlay me-10">
                                        <div className="overlay-wrapper">
                                            <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                        </div>

                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" className="btn btn-sm btn-primary btn-shadow">image un</a>
                                        </div>
                                    </div>
                                    <div className="col-4 overlay me-10">
                                        <div className="overlay-wrapper">
                                            <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                        </div>

                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" className="btn btn-sm btn-primary btn-shadow">image un</a>
                                        </div>
                                    </div>
                                    <div className="col-4 overlay me-10">
                                        <div className="overlay-wrapper">
                                            <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                        </div>

                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" className="btn btn-sm btn-primary btn-shadow">image un</a>
                                        </div>
                                    </div>
                                    <div className="col-4 overlay me-10">
                                        <div className="overlay-wrapper">
                                            <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                        </div>

                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" className="btn btn-sm btn-primary btn-shadow">image un</a>
                                        </div>
                                    </div>
                                    <div className="col-4 overlay me-10">
                                        <div className="overlay-wrapper">
                                            <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                        </div>

                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                            <a href="#" className="btn btn-sm btn-primary btn-shadow">image un</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <br/>

                </div>


            </div>

        </div>
    );
};

export default InventoryDetails;