import React from 'react';

const SubscribePlan = ({packTitle, desc = "", currency, amount, duration = "Mois", avantage = []}) => {
    return (
        <>
            <div className="d-flex h-75 align-items-center">
                <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                    <div className="mb-7 text-center">
                        <h1 className="text-dark mb-5 fw-boldest">{packTitle}</h1>

                        <div className="text-gray-400 fw-semibold mb-5">
                            {desc}
                        </div>

                        <div className="text-center">
                            <span className="mb-2 text-primary">{currency}</span>

                            <span className="fs-3x fw-bold text-primary" data-kt-plan-price-month="99"
                                  data-kt-plan-price-annual="999">{amount}</span>

                            <span className="fs-7 fw-semibold opacity-50" data-kt-plan-price-month="/ Mon"
                                  data-kt-plan-price-annual="/ Ann">/ {duration}</span>
                        </div>
                    </div>

                    <div className="w-100 mb-10">
                        {
                            avantage.map(item => {
                                    return  <>
                                        {item.include === true &&
                                            <div className="d-flex flex-stack mb-3" key={item}>
                                            <span className="fw-semibold fs-6 text-gray-800 text-start pe-2">
                                                {item.label}
                                            </span>
                                                <i className="ki-outline ki-check-circle fs-3 text-success"></i>
                                            </div>}
                                        {
                                            item.include === false &&
                                            <div className="d-flex flex-stack mb-3" key={item}>
                                                <span className="fw-semibold fs-6 text-gray-800">
                                                    {item.label}
                                                </span>
                                                <i className="ki-outline ki-cross-circle fs-3"></i>
                                            </div>
                                        }
                                    </>
                            })
                        }

                    </div>

                    {/*<a href="#" className="btn btn-primary">Select</a>*/}
                </div>
            </div>

        </>
    );
};

export default SubscribePlan;
