import React, {useState} from 'react';
import TenantItem from "./components/TenantItem";
import Paginator from "../../shared/components/paginator/Paginator";
import TenantSearch from "../components/advanced-search/UserSearch";
import PaginateContent from "../../shared/components/paginator/PaginateContent";

const TenantList = () => {
    const ownerTenants = [
        {
            last_name:"Ted",
            first_name:"silam",
            contact:"+225 66 555 997",
            job:"Machiniste",
            email:"ted.lombardie@gmail.com",
        },
        {
            last_name:"Tediam",
            first_name:"gailamio",
            contact:"+225 66 555 997",
            job:"Cariste",
            email:"origin.lombardie@gmail.com",
        },
        {
            last_name:"Ted",
            first_name:"silam",
            contact:"+225 66 555 997",
            job:"Machiniste",
            email:"ted.lombardie@gmail.com",
        },
        {
            last_name:"Ted",
            first_name:"silam",
            contact:"+225 66 555 997",
            job:"Machiniste",
            email:"ted.lombardie@gmail.com",
        },
        {
            last_name:"Harre",
            first_name:"Kabarer",
            contact:"+225 66 778 997",
            job:"Courtier",
            email:"kabarer.lombardie@gmail.com",
        },

    ];
    return (
        <PaginateContent itemData={ownerTenants}  render={(currentData)=><>
            <div className="col">
                <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                    <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                        <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                            Mes Locataires
                        </h1>
                    </div>
                    <TenantSearch></TenantSearch>
                </div>
            </div>
            <div className="col">
                <div className="row g-3 mb-6">
                    {currentData.map((tenant, index)=>{
                        return <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4" key={index}>
                            <TenantItem tenant={tenant} ></TenantItem>
                        </div>})
                    }
                </div>

            </div>
        </>}/>
    );
};

export default TenantList;
