import React, {useEffect, useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import {useDispatch, useSelector} from "react-redux";
import {selectPropertyFullAddress, setPropertyFullAddress} from "../../../store/add-property/AddPropertyReducer";
import http_axios from "../../../http/axios-plugin";
import {setUserInfosValidation, setUserPersonalDatas} from "../../../store/user-register/CustomerReducer";
import IziSelectField from "../../../shared/components/form/IziSelectField";

const Location = ({updateLocation=null}) => {
    const fullAddress = {
        country:null,
        township:'',
        town:null,
        neighborhood:'',
        address:'',
    };
    const getLocationState = useSelector(selectPropertyFullAddress)
    const [localisationForm, setLocalisationForm] = useState(getLocationState??fullAddress);
    const [countriesList, setCountries] = useState([])
    const [citiesList, setCities] = useState([])
    const storePropLocalisation = useDispatch();
    const [loading, setLoad] = useState(false);

    useEffect(() => {
        setTimeout(async () => {
            const allCountries = JSON.parse(localStorage.getItem('countries'));
            if (allCountries){
                const countriesList = [];
                allCountries.forEach((countryData)=>{
                    countriesList.push({
                        label:countryData.country,
                        value:countryData.country,
                        cities:countryData.cities,
                    });
                });
                setCountries(countriesList)
            }
            if (updateLocation){
                setLocalisationForm({...updateLocation})
            }
        }, 1000)
    }, []);
    const onchangeCountry = (data)=>{
        setLocalisationForm({
            ...localisationForm, country: data
        })
        const citiesList = [];
        data.cities.forEach((city)=>{
            citiesList.push({
                label:city,
                value:city,
            });
        });
        setCities(citiesList)
    }
    const Validator = ()=>{
        const {country, township, town, neighborhood, address} = localisationForm;
        return country !== null && township.length > 3 && town !== null && neighborhood.length > 3 && address.length > 3;
    }
    const onchangeTown = async (data) => {
        try {
             setLocalisationForm((prevState) => ({
                ...prevState, town:data
            }));
        }catch (e) {
            console.error(e)
        }
        if (Validator()) {
            storePropLocalisation(setPropertyFullAddress({...localisationForm, town: data}))
        }

    }
    const onChange = (event) => {
        const { name, value } = event.target;
        setLocalisationForm((prevState) => ({
            ...prevState,
            [name]:value
        }));
        if (Validator()) {
            storePropLocalisation(setPropertyFullAddress({
                ...localisationForm,
                [name]:value
            }))
        }

    };
    return (
        <form className={(loading ?"placeholder-glow":"")}>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <IziSelectField
                        dataList={countriesList}
                        validateRule={['required']}
                        required={true}
                        value={localisationForm.country}
                        onChange={onchangeCountry}
                        label={"Pays"}
                        name={"country"}
                        errorMessage={'Veuillez sélectionner le Pays'}
                    ></IziSelectField>

                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <IziInputField label={'Adresse'} onChange={onChange} type={'text'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir la ville l adresse ou est situé le bien'}
                                   value={localisationForm.address} name={'address'} required={true}/>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <IziInputField label={'Quartier'} onChange={onChange} type={'text'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir le quartier ou est situé le bien'}
                                   value={localisationForm.neighborhood} name={'neighborhood'} required={true}/>

                </div>
            </div>
            <div className="row ">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <IziInputField label={'Commune'} onChange={onChange} type={'text'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir la commune ou est situé le bien'}
                                   value={localisationForm.township} name={'township'} required={true}/>
                </div>
                <div className={"col-sm-12 col-md-6 col-lg-4 " + (loading ? "placeholder" : "")}>
                    <IziSelectField
                        dataList={citiesList}
                        validateRule={['required']}
                        required={true}
                        value={localisationForm.town}
                        onChange={onchangeTown}
                        label={"Ville"}
                        name={"town"}
                        errorMessage={'Veuillez sélectionner la ville'}
                    ></IziSelectField>
                </div>
            </div>
        </form>
    );
};

export default Location;