export const propertyCommodities = [
    'Chauffe-eau',
    'Climatisation',
    'Tv',
    'Wifi',
    'Jardin',
    'Fer à repasser',
    'Ustensiles de cuisine',
    'Micro-onde',
    'Frigidaire',
    'Machine à laver',
    'Machine à sécher',
    'Table à repasser',
    'Gazinère',
    'Linges',
    'Autres',
]