import React, {useEffect, useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import AddKeysForm from "./AddKeysForm";
import IziModal from "../../../shared/components/modal/IziModal";
import IziButton from "../../../shared/button/IziButton";
import CommodityInventory from "./CommodityInventory";
import AddInventoryPiecesForm from "./AddInventoryPiecesForm";
import {useDispatch, useSelector} from "react-redux";
import {selectPropertyChoose} from "../../../store/add-property/AddPropertyReducer";
import {
    selectEnterInventoryExtraTwo, selectPropertyEnterInventory, setPropertyEnterInventory,
    setPropertyEnterInventoryExtraTwo
} from "../../../store/add-contract/AddContractReducer";

const ContractInventory = () => {
    const [keyModal, setKeyModal] = useState(false);
    const [piecesModal, setPiecesModal] = useState(false);
    const keyItemsState = useSelector(selectEnterInventoryExtraTwo);
    const piecesState = useSelector(selectPropertyEnterInventory)
    const [keyItems, setKeyItems] = useState([...keyItemsState]??[]);
    const [pieceItems, setPieceItems] = useState([...piecesState]??[]);
    // const propertyChoose = useSelector(selectPropertyChoose);
    const itemsKeyState = useSelector(selectEnterInventoryExtraTwo)
    const saveState = useDispatch();
    const addKeyItems = (data)=>{
        setKeyItems((prevState)=>[ ...prevState, data]);
        saveState(setPropertyEnterInventoryExtraTwo([...keyItems, data]));
        setKeyModal(false);
    }
    const addPieceItems = (data)=>{
        saveState(setPropertyEnterInventory([...pieceItems, data]))
        setPieceItems((prevState)=>[ ...prevState, data]);
        setPiecesModal(false);
    }
    const removePieceItems = (index)=>{
        setPieceItems((prevState)=>[...prevState.filter((elem, i)=> i !== index)])
    }
    const closeKeyModal = (event)=>{
        setKeyModal(false);
    }
    const closePiecesModal = (event)=>{
        setPiecesModal(false);
    }
    const removeKeyItems = (index)=>{
        setKeyItems((prevState)=>[...prevState.filter((elem, i)=> i !== index)])
    }
    useEffect(() => {
        if (itemsKeyState){
            setKeyItems([...itemsKeyState])
        }
    }, []);
    return (
        <>
            <div className="d-flex flex-column">
                <IziButton onClick={()=>setKeyModal(true)}>Renseigner les clés du bien</IziButton>
                {keyModal === true && <IziModal modalTitle={"Renseignement des paire de clés du bâtiment ou autre pièces"} closeValue={keyModal} closeModal={closeKeyModal}>
                    <div className="row">
                        <div className="col d-flex flex-column">
                            <AddKeysForm getItem={addKeyItems}></AddKeysForm>
                        </div>
                    </div>
                </IziModal>}
                <div className="row my-3">
                    {keyItems.map((item, i) => {
                        return <div className="col-sm-12 col-md-6 col-lg-4" key={i}>
                            <div className="d-flex align-items-center w-200px w-sm-300px flex-column">
                                <div
                                    className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                                    <i className="ki-outline ki-trash fs-2hx text-danger me-4"
                                       onClick={() => removeKeyItems(i)}></i>
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <div className="fs-6 text-gray-700 ">
                                                <p>Type: {item.type_elem}</p>
                                                <p>Nombre de paire: {item.number}</p>
                                                <p>Description: {item.description}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    })}
                </div>
                <CommodityInventory></CommodityInventory>
                <IziButton onClick={()=>setPiecesModal(true)}>Renseigner l'état des pièces du bien</IziButton>
                {piecesModal === true &&
                <IziModal closeModal={closePiecesModal} closeValue={piecesModal} modalTitle={"Renseigner l'état des différents partie de la pièces"}>
                    <AddInventoryPiecesForm getItem={addPieceItems}></AddInventoryPiecesForm>
                </IziModal>
                }
                <div className="row border-2 border-primary rounded-4">
                    <div className="col d-flex flex-column">
                        <h4>Liste des pièces du bien</h4>
                        <div className="row">
                            {pieceItems.map((item, i) =>{
                                return <div className="card shadow-sm" key={i}>
                                    <div className="card-header collapsible cursor-pointer rotate bg-white" data-bs-toggle="collapse"
                                         data-bs-target={"#kt_docs_card_collapsible-"+i}>
                                        <h3 className="card-title">Pièce: {item.pieceType.label ?? ''}
                                            <i className="ki-outline ki-trash fs-2hx text-danger mx-8"
                                               onClick={() => removePieceItems(i)}></i>
                                        </h3>
                                        <div className="card-toolbar rotate-180">
                                        <i className="ki-duotone ki-down fs-1"></i>
                                        </div>
                                    </div>
                                    <div id={"kt_docs_card_collapsible-"+i} className="collapse show">
                                        <div className="row fs-5 text-gray-700 w-100">
                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                <h5>Port</h5>
                                                <p>Type: {item.door.type_elem.label ?? ''}</p>
                                                <p>État: {item.door.state.label}</p>
                                                <p>Description: {item.door.description}</p>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                <h5>Mur</h5>
                                                <p>Type: {item.wal.type_elem.label ?? ''}</p>
                                                <p>État: {item.wal.state.label}</p>
                                                <p>Description: {item.wal.description}</p>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                <h5>Sol</h5>
                                                <p>Type: {item.ground.type_elem.label ?? ''}</p>
                                                <p>État: {item.ground.state.label}</p>
                                                <p>Description: {item.ground.description}</p>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                <h5>Vitrage</h5>
                                                <p>Type: {item.glazing.type_elem.label ?? ''}</p>
                                                <p>État: {item.glazing.state.label}</p>
                                                <p>Description: {item.glazing.description}</p>
                                            </div>
                                        </div>
                                        <span className="separator h-2px my-1"></span>
                                        <div className="row fs-5 text-gray-700 w-100">
                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                <h5>Vitrage</h5>
                                                <p>Type: {item.glazing.type_elem.label ?? ''}</p>
                                                <p>État: {item.glazing.state.label}</p>
                                                <p>Description: {item.glazing.description}</p>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3">
                                                <h5>Plafond</h5>
                                                <p>Type: {item.ceiling.type_elem.label ?? ''}</p>
                                                <p>État: {item.ceiling.state.label}</p>
                                                <p>Description: {item.ceiling.description}</p>
                                            </div>
                                            {/*<div className="col-sm-6 col-md-4 col-lg-3">*/}
                                            {/*    <h5>Éclairage et interrupteurs</h5>*/}
                                            {/*    <p>État: {item.lighting_switches.state.label}</p>*/}
                                            {/*    <p>Description: {item.lighting_switches.description}</p>*/}
                                            {/*    <p>pièces jointe: {item.lighting_switches.images}</p>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-sm-6 col-md-4 col-lg-3">*/}
                                            {/*    <h5>Prises électriques</h5>*/}
                                            {/*    <p>Nombre: {item.electrical_outlets.nbr_outlet}</p>*/}
                                            {/*    <p>État: {item.electrical_outlets.state.label}</p>*/}
                                            {/*    <p>Description: {item.electrical_outlets.description}</p>*/}
                                            {/*    <p>pièces jointe: {item.electrical_outlets.images}</p>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>

                            })}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ContractInventory;
