import React from 'react';
import ContractSearch from "../components/advanced-search/ContractSearch";
import PaginateContent from "../../shared/components/paginator/PaginateContent";
import CampaignCard from "./components/CampaignCard";
import CampaignSearch from "../components/advanced-search/CampaignSearch";
import IziButton from "../../shared/button/IziButton";
import {useNavigate} from "react-router-dom";

const Campaign = () => {
    const route  = useNavigate();
    const newCampaign = ()=>{
        route(`/dashboard/campaign/add`);
    }
    const userCampaign = [
        {
            uid:"QDKDN855",
            status:101,
            tenants: Array(5).fill({first_name:"antone", last_name:"john"} ),
            label:"relance de locataires de janvier",
            type:"relance",
            canals:["email", "sms"],
            created_at:"2023-05-09",
            start_date:"2023-11-17",

        },
        {
            uid:"QOODN855",
            status:101,
            tenants: Array(46).fill({first_name:"antone", last_name:"john"} ),
            label:"augmentation de loyer",
            type:"campagne",
            canals:["sms"],
            created_at:"2023-08-19",
            start_date:"2023-10-07",

        },
        {
            uid:"QDKDN55M",
            status:102,
            tenants: Array(4).fill({first_name:"antone", last_name:"john"} ),
            label:"mao psks,",
            type:"impayé",
            canals:["email", "sms"],
            created_at:"2023-05-09",
            start_date:"2023-05-07",
        },
        {
            uid:"QDKDN855",
            status:101,
            tenants: Array(7).fill({first_name:"antone", last_name:"Poilue"} ),
            label:"relance de locataires de janvier",
            type:"relance",
            canals:["email", "sms"],
            created_at:"2023-05-09",
            start_date:"2023-06-27",

        },
        {
            uid:"QDKDN855",
            status:101,
            tenants: Array(8).fill({first_name:"mago", last_name:"jonie"} ),
            label:"relance de locataires de janvier",
            type:"relance",
            canals:["email", "sms"],
            created_at:"2023-05-09",
            start_date:"2023-05-11",

        },
        {
            uid:"QDKDN855",
            status:103,
            tenants: Array(6).fill({first_name:"amago", last_name:"john solen"} ),
            label:"relance de locataires de janvier",
            type:"relance",
            canals:["email", "sms"],
            created_at:"2023-05-09",
            start_date:"2024-05-07",

        },
        {
            uid:"QDKDN855",
            status:101,
            tenants: Array(8).fill({first_name:"antoine", last_name:"john ismo"} ),
            label:"relance de locataires de janvier",
            type:"relance",
            canals:["email", "sms"],
            created_at:"2023-05-09",
            start_date:"2023-05-15",

        },
        {
            uid:"QDKDN855",
            status:103,
            tenants: Array(6).fill({first_name:"antone", last_name:"john"} ),
            label:"relance de locataires de janvier",
            type:"relance",
            canals:["email", "sms"],
            created_at:"2023-05-09",
            start_date:"2023-05-07",

        },

    ];
    return (
        <>
            <PaginateContent itemData={userCampaign} render={(currentData)=> <>
                <div className="col">
                    <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                        <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                            <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                                Mes Campagnes
                            </h1>
                        </div>
                        <CampaignSearch></CampaignSearch>
                        <IziButton onClick={newCampaign}>Nouvelle campagne</IziButton>
                    </div>
                </div>
                <div className="col">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  w-100">
                        {
                            currentData.map((campaign, e) => (
                                <CampaignCard campaign={campaign} key={"card-"+e}></CampaignCard>
                            ))
                        }
                    </div>
                </div>
            </>}/>
        </>
    );
};

export default Campaign;
