import React, {useState} from 'react';
import Card from "../../shared/components/card/Card";
import UserInfos from "./UserInfos";
import UserEdit from "./UserEdit";
import {Link} from "react-router-dom";

const Profile = () => {
    const [modify, setModify] = useState(false)
    return (
        <>
            <Card>
                <div className="d-flex flex-wrap flex-sm-nowrap">
                    <div className="me-7 mb-4">
                        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img alt="image" src={"/assets/images/img_test.jpg"} />
                            <div
                                className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                        </div>
                    </div>

                    <div className="flex-grow-1">

                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">Max
                                        Smith</a>
                                    <a href="#"><i className="ki-outline ki-verify fs-1 text-primary"></i></a>
                                </div>

                                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                    <a href="#"
                                       className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                        <i className="ki-outline ki-profile-circle fs-4 me-1"></i> Administrateur
                                    </a>
                                    <a href="#"
                                       className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                        <i className="ki-outline ki-phone fs-4 me-1"></i> +225 05 05 99 77 50
                                    </a>
                                    <a href="#"
                                       className="d-flex align-items-center text-gray-500 text-hover-primary mb-2">
                                        <i className="ki-outline ki-sms fs-4"></i> max@origami.com
                                    </a>
                                </div>
                            </div>

                            <div className="d-flex my-4">

                                <Link to="/dashboard" className="btn btn-sm btn-primary me-3"
                                   data-bs-target="#kt_modal_offer_a_deal">Retour</Link>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap flex-stack">
                            <div className="d-flex flex-column flex-grow-1 pe-8">
                                <div className="d-flex flex-wrap">
                                    <div
                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            {/*<i className="ki-outline ki-arrow-up fs-3 text-success me-2"></i>*/}
                                            <div className="fs-2 fw-bold counted" data-kt-countup="true"
                                                 data-kt-countup-value="4500" data-kt-countup-prefix="$"
                                                 data-kt-initialized="1">Origami.co
                                            </div>
                                        </div>

                                        <div className="fw-semibold fs-6 text-gray-500">Entreprise</div>
                                    </div>

                                    <div
                                        className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            {/*<i className="ki-outline ki-arrow-down fs-3 text-danger me-2"></i>*/}
                                            <div className="fs-2 fw-bold counted" data-kt-countup="true"
                                                 data-kt-countup-value="8" data-kt-initialized="1">8
                                            </div>
                                        </div>

                                        <div className="fw-semibold fs-6 text-gray-500">Équipe</div>
                                    </div>

                                    {/*<div*/}
                                    {/*    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">*/}
                                    {/*    <div className="d-flex align-items-center">*/}
                                    {/*        <i className="ki-outline ki-arrow-up fs-3 text-success me-2"></i>*/}
                                    {/*        <div className="fs-2 fw-bold counted" data-kt-countup="true"*/}
                                    {/*             data-kt-countup-value="60"*/}
                                    {/*             data-kt-initialized="1">%60*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*    <div className="fw-semibold fs-6 text-gray-500">Success Rate</div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            {/*<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">*/}
                            {/*    <div className="d-flex justify-content-between w-100 mt-auto mb-2">*/}
                            {/*        <span className="fw-semibold fs-6 text-gray-500">Profile Compleation</span>*/}
                            {/*        <span className="fw-bold fs-6">50%</span>*/}
                            {/*    </div>*/}

                            {/*    <div className="h-5px mx-3 w-100 bg-light mb-3">*/}
                            {/*        <div className="bg-success rounded h-5px" role="progressbar" style={{width: "50%"}}*/}
                            {/*             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </Card>
            <Card>
                <div className="card-header cursor-pointer">
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Détails du profil</h3>
                    </div>

                    <button
                       className="btn btn-sm btn-primary align-self-center" onClick={()=> setModify(!modify)}>{modify ? "annuler":"Modifier le Profil"}</button>
                </div>
                {
                    modify===false && <UserInfos></UserInfos>
                }
                {
                    modify===true && <UserEdit></UserEdit>
                }
            </Card>
        </>
    );
};

export default Profile;
