import React from 'react';
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziInputField from "../../../shared/components/form/IziInputField";

const TenantSearch = () => {
    return (
        <>
        <div className="row w-100">
            <div className="col-sm-12 col-md-12 col-lg-8">
                <h3 className="fw-semibold text-dark">Recherche multi-critère</h3>
            </div>
        </div>
        <form data-kt-search-element="advanced-options-form" className="row align-items-center position-relative w-100 m-0" >
            <div className="col-12">
                <div className="d-flex justify-content-end pt-6">
                    <button type="reset" className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
                            data-kt-search-element="preferences-dismiss">Reinitialisé
                    </button>
                    <button type="submit" className="btn btn-sm fw-bold btn-primary">Appliquer le filtre</button>
                </div>

            </div>
            <div className="col-sm-6 col-md-6 col-lg-4">
                <IziSelectField dataList={[{value:"test-1", label:"tests-1"}, {value:"test-2", label:"tests-2"}, {value:"test", label:"tests"}]}></IziSelectField>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="nav-group nav-group-fluid">
                    <label>
                        <input type="radio" className="btn-check" name="type" value="has" checked="checked"/>
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                    All
                </span>
                    </label>

                    <label>
                        <input type="radio" className="btn-check" name="type" value="users"/>
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Users
                </span>
                    </label>

                    <label>
                        <input type="radio" className="btn-check" name="type" value="orders"/>
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Orders
                </span>
                    </label>

                    <label>
                        <input type="radio" className="btn-check" name="type" value="projects"/>
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Projects
                </span>
                    </label>
                </div>
            </div>

            <div className="ccol-sm-6 col-md-6 col-lg-4">
                <IziInputField label={"test"}>
                </IziInputField>
            </div>
        </form>
        </>
    );
};

export default TenantSearch;
