import React, {useState} from 'react';
import IziCheckboxField from "../../shared/components/form/IziCheckboxField";
import {userAccess} from "../../shared/constant/AccesList";

const EditSecurity = ({userAllAccess}) => {
    const [userAccessUpdate, setUserAccessUpdate] = useState(userAllAccess);
    const accessList = userAccess
    const onChange = (event) => {
        const { name, value} = event.target;
        setUserAccessUpdate({
            ...userAccessUpdate,
            [name]: event.target.checked === true ? [...userAccessUpdate.access,value]:[value],
        });

    };
    function updateUserAccess(){
        console.log(userAccessUpdate)
    }
    return (
        <div>
            <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                  noValidate="novalidate" data-select2-id="select2-data-kt_account_profile_details_form">
                <div >
                    <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                          noValidate="novalidate" data-select2-id="select2-data-kt_account_profile_details_form" onSubmit={updateUserAccess}>
                    <div className="row gap-1">
                        {
                            accessList.map((access, index)=>{
                                return <IziCheckboxField className={'col-4'}  key={index} onChange={onChange} value={access.rootLink} label={access.title}  name={'access'} />
                            })
                        }
                    </div>
                    </form>
                </div>
            </form>
        </div>
    );
};

export default EditSecurity;