import React, {useEffect, useState} from 'react';
import Paginator from "../../../shared/components/paginator/Paginator";
import ContractSearch from "../../components/advanced-search/ContractSearch";
import ContractElement from "./ContractElement";
import PaginateContent from "../../../shared/components/paginator/PaginateContent";


const PropertyContratsList = ({contracts, backTolist, activeCallBack = false,callBack, btnLabel="Quittances"}) => {
    useEffect(() => {
        console.log(contracts);
    }, []);
    return (
        <PaginateContent itemData={contracts} render={(currentData)=> <>
            <div className="col">
                <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                    <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                        <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                            Mes Contrats
                        </h1>
                    </div>
                    <ContractSearch></ContractSearch>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <a href="#" className="btn btn-flex btn-primary h-40px fs-7 fw-bold" onClick={backTolist}>
                            Retour
                        </a>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  w-100">
                    {
                        currentData.map((prop, i) => (
                            <ContractElement contract={prop} key={i} callBack={callBack} activeCallBack={activeCallBack} btnLabel={btnLabel} ></ContractElement>
                        ))
                    }
                </div>
            </div>
        </>}/>
    );
};

export default PropertyContratsList;
