import React from 'react';
import InventoryItem from "./InventoryItem";

const InventoryList = () => {
    return (
        <div className="card bg-white shadow border-1 border-light card-flush" id="kt_contacts_list">
            <div className="card-header pt-7" id="kt_contacts_list_header">
                <h5 className="fw-bold fs-4">Liste des états des lieux</h5>

            </div>
            <div className="card-body pt-5" id="kt_contacts_list_body">
                <InventoryItem isCurrent={true}></InventoryItem>
                <div className="separator separator-dashed d-none"></div>
                <InventoryItem></InventoryItem>
                <div className="separator separator-dashed d-none"></div>
                <InventoryItem></InventoryItem>
                <div className="separator separator-dashed d-none"></div>
                <InventoryItem></InventoryItem>
                <div className="separator separator-dashed d-none"></div>
                <InventoryItem></InventoryItem>
                <div className="separator separator-dashed d-none"></div>
                <InventoryItem></InventoryItem>
                <div className="separator separator-dashed d-none"></div>
            </div>
        </div>
    );
};

export default InventoryList;