import React, {useState} from 'react';
import IziSelectField from "../../shared/components/form/IziSelectField";
import IziInputField from "../../shared/components/form/IziInputField";
import IziButton from "../../shared/button/IziButton";

const ConfigFinance = () => {
    const [financeForm, setFinance] = useState(
        {
            owner_income: 0,
            later_fee:0,
            vat:0,
            agency_fee:0,
            receipt_caution:2,
            receipt_advance:2,
        }
    )
    const onChange = (event) => {
        const { name, value, type } = event.target;
        setFinance({
            ...financeForm,
            [name]:value,
        });
    };
    return (
        <>
            <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                    Ma configuration loyer
                </h1>
            </div>
            <form>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Revenus propriétaire (%)'} onChange={onChange} type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir une valeur'}
                                       value={financeForm.owner_income} name={'owner_income'} required={true}/>

                    </div>
                    <div className="col">
                        <IziInputField label={'Frais de retard loyers (%)'} onChange={onChange} type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir une valeur'}
                                       value={financeForm.later_fee} name={'later_fee'} required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'TVA sur honoraires d\'agence (%)'} onChange={onChange}
                                       type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir une valeur'}
                                       value={financeForm.vat} name={'vat'} required={true}/>

                    </div>
                    <div className="col">
                        <IziInputField label={'Frais de dossier (%)'} onChange={onChange} type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir une valeur'}
                                       value={financeForm.agency_fee} name={'agency_fee'} required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Avance de loyer'} onChange={onChange}
                                       type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir une valeur'}
                                       value={financeForm.receipt_advance} name={'receipt_advance'} required={true}/>

                    </div>
                    <div className="col">
                        <IziInputField label={'Caution de loyer'} onChange={onChange} type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir une valeur'}
                                       value={financeForm.receipt_caution} name={'receipt_caution'} required={true}/>
                    </div>
                </div>
                <div className="d-flex justify-content-end py-6 gap-3">
                    <IziButton buttonKtType={'button'} size={'btn-lg'} isProgress={false}>
                        Appliquer
                    </IziButton>
                </div>
            </form>
        </>
    );
};

export default ConfigFinance;
