import React from 'react';

const StepContentHeading = (props) => {
    const {headTitle, headDescription} = props;
    return (
        <div>
            <div className="pb-7 pb-lg-12">
                {/*// <!--begin::Title-->*/}
                <h1 className="fw-bold text-dark">{headTitle}</h1>
                {/*// <!--end::Title-->*/}

                {/*// <!--begin::Description-->*/}
                <div className="text-muted fw-semibold fs-4">
                    {headDescription}
                </div>
                {/*// <!--end::Description-->*/}
            </div>
        </div>
    );
};

export default StepContentHeading;