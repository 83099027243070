import React, {useState} from 'react';
import AddPiecesForm from "./AddPiecesForm";
import IziModal from "../../../shared/components/modal/IziModal";
import IziButton from "../../../shared/button/IziButton";
import {useDispatch, useSelector} from "react-redux";
import {selectPropertyPieces, setPropertyPieces} from "../../../store/add-property/AddPropertyReducer";

const PiecesInventory = () => {
    const getPiecesState = useSelector(selectPropertyPieces)
    const [pieceItems, setPieceItems] = useState(getPiecesState??[]);
    const [closeAddModal, setCloseAddModal] = useState(false);
    const storePieces = useDispatch();
    const addPieceItems = (data)=>{
        const oldDatas = [ ...pieceItems, data]
        setPieceItems(oldDatas);
        storePieces(setPropertyPieces(oldDatas))
        setCloseAddModal(false);
    }
    const removePieceItems = (index)=>{
        const oldDatas = [...pieceItems.filter((elem, i)=> i !== index)];
        setPieceItems(oldDatas)
        storePieces(setPropertyPieces(oldDatas))

    }
    const closeModal = (event)=>{
        setCloseAddModal(false)
    }
    return (
        <div className="d-flex flex-column">
            <div>
                <IziButton onClick={()=> setCloseAddModal(true)}>Ajouter une pièces au bien</IziButton>

                {closeAddModal && <IziModal modalTitle={"Renseignement des infos de la pièces"} closeModal={closeModal}
                           closeValue={closeAddModal}>
                    <AddPiecesForm getItem={addPieceItems}></AddPiecesForm>
                </IziModal>}
            </div>
            <div className="row border-2 border-primary rounded-4">
                <div className="col d-flex flex-column">
                    <h4>Liste des pièces du bien</h4>
                    <div className="row">
                        {pieceItems.map((item, i) =>{
                            return <div className="col-12" key={i}>
                                <div className="d-flex align-items-center flex-column">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6 w-100">
                                        <i className="ki-outline ki-trash fs-2hx text-danger me-4" onClick={()=>removePieceItems(i)}></i>
                                        <div className={"w-100 fw-semibold"}>
                                            <p className={"mb-3 fs-4 d-flex flex-row justify-content-between"}>
                                                <span>Pièces: {item.label}  </span>
                                                <span>Type de pièces: {item.pieceType.label}</span>
                                                        </p>
                                            <div className="row fs-5 text-gray-700 w-100">
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Port</h5>
                                                    <p>Type: {item.door.type_elem.label}</p>
                                                    <p>État: {item.door.state.label}</p>
                                                    {/*<p>Description: {item.door.description}</p>*/}
                                                    {/*<p>pièces jointe: {item.door.images}</p>*/}
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Mur</h5>
                                                    <p>Type: {item.wal.type_elem.label}</p>
                                                    <p>État: {item.wal.state.label}</p>
                                                    {/*<p>Description: {item.wal.description}</p>*/}
                                                    {/*<p>pièces jointe: {item.wal.images}</p>*/}
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Sol</h5>
                                                    <p>Type: {item.ground.type_elem.label}</p>
                                                    <p>État: {item.ground.state.label}</p>
                                                    {/*<p>Description: {item.ground.description}</p>*/}
                                                    {/*<p>pièces jointe: {item.ground.images}</p>*/}
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Vitrage</h5>
                                                    <p>Type: {item.glazing.type_elem.label}</p>
                                                    <p>État: {item.glazing.state.label}</p>
                                                    {/*<p>Description: {item.glazing.description}</p>*/}
                                                    {/*<p>pièces jointe: {item.glazing.images}</p>*/}
                                                </div>
                                            </div>
                                            <span className="separator h-2px my-1"></span>
                                            <div className="row fs-5 text-gray-700 w-100">
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Vitrage</h5>
                                                    <p>Type: {item.glazing.type_elem.label}</p>
                                                    <p>État: {item.glazing.state.label}</p>
                                                    {/*<p>Description: {item.glazing.description}</p>*/}
                                                    {/*<p>pièces jointe: {item.glazing.images}</p>*/}
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Plafond</h5>
                                                    <p>Type: {item.ceiling.type_elem.label}</p>
                                                    <p>État: {item.ceiling.state.label}</p>
                                                    {/*<p>Description: {item.ceiling.description}</p>*/}
                                                    {/*<p>pièces jointe: {item.ceiling.images}</p>*/}
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Éclairage et interrupteurs ({item.lighting_switches.length})</h5>
                                                    {
                                                        item.lighting_switches.map(light_item => <span>Libéllé: {light_item.label}, État: {light_item.state.value}</span>)
                                                    }
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3">
                                                    <h5>Prises électriques ({item.electrical_outlets.length})</h5>
                                                    {
                                                        item.electrical_outlets.map(electrical_item => <span>Libéllé: {electrical_item.label}, État: {electrical_item.state.value}</span>)
                                                    }
                                                    {/*<p>Description: {item.electrical_outlets.description}</p>*/}
                                                    {/*<p>pièces jointe: {item.electrical_outlets.images}</p>*/}
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>

                            </div>

                        })}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PiecesInventory;
