import React, {useEffect, useState} from 'react';
import IziTextAreaField from "../../../shared/components/form/IziTextAreaField";
import IziButton from "../../../shared/button/IziButton";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziFileField from "../../../shared/components/form/IziFileField";
import {piecesState} from "../../models/AddPieces.ts";
import {useSelector} from "react-redux";
import {selectPropertyChoose} from "../../../store/add-property/AddPropertyReducer";

const AddInventoryPiecesForm = ({getItem}) => {
    const propertyChoose = useSelector(selectPropertyChoose);
    const propertyAllPieces = propertyChoose.pieces;
    const piecesType = [];
    const [pieceSelected, setPieceSelect] = useState(null);
    const [door, setDoor]= useState({
        type_elem:pieceSelected?.door?.type_elem,
        state:{},
        description:'',
        images:[],
    });
    const [wal, setWal]= useState({
        type_elem:pieceSelected?.wal?.type_elem,
        state:{},
        desc:'',
        images:[],
    });
    const [ground, setGround]= useState({
        type_elem:pieceSelected?.ground?.type_elem,
        state:{},
        desc:'',
        images:[],
    });
    const [glazing, setGlazing]= useState({
        type_elem:{value:"Vitre", label:"Vitre"},
        state:{},
        desc:'',
        images:[],
    });
    const [ceiling, setCeiling]= useState({
        type_elem:pieceSelected?.ceiling?.type_elem,
        state:{},
        desc:'',
        images:[],
    });
    const [pieces, setPieces]= useState({
        label:'',
        pieceType: {},
        door:{},
        wal:{},
        ground:{},
        glazing:{},
        ceiling:{},
        lighting_switches:{},
        electrical_outlets:{},

    });
    const onChangeFiles = (piece_mode, event)=>{
        switch (piece_mode) {
            case 1:
                setDoor((prevState)=>({
                    ...prevState,
                    'images': [...prevState.images,event[0]]}));
                break;
            case 2:
                setWal((prevState)=>({
                    ...prevState,
                    'images': [...prevState.images,event[0]]}));
                break;
            case 3:
                setGround((prevState)=>({
                    ...prevState,
                    'images': [...prevState.images,event[0]]}));
                break;
            case 4:
                setGlazing((prevState)=>({
                    ...prevState,
                    'images': [...prevState.images,event[0]]}));
                break;
            case 5:
                setCeiling((prevState)=>({
                    ...prevState,
                    'images': [...prevState.images,event[0]]}));
                break;
            default:
                setDoor((prevState)=>({
                    ...prevState,
                    'images': [...prevState.images,event[0]]}));
                break;

        }
        // console.warn(door);
    }
    const onChangeState = (event, mode)=>{
        switch (mode) {
            case 1:
                setDoor((prevState)=>({
                    ...prevState,
                    'state': event}));
                break;
            case 2:
                setWal((prevState)=>({
                    ...prevState,
                    'state': event,
                }));
                break;
            case 3:
                setGround((prevState)=>({
                    ...prevState,
                    'state': event,
                }));
                break;
            case 4:
                setGlazing((prevState)=>({
                    ...prevState,
                    'state': event,
                }));
                break;
            case 5:
                setCeiling((prevState)=>({
                    ...prevState,
                    'state': event,
                }));
                break;
            default:
                setDoor((prevState)=>({
                    ...prevState,
                    'state': event,
                }));
                break;

        }

    }
    const onChangePieceField = (event, mode)=>{
        const { name, value } = event.target;
        switch (mode) {
            case 1:
                setDoor((prevState)=>({
                    ...prevState,
                    [name]: value}));
                break;
            case 2:
                setWal((prevState)=>({
                    ...prevState,
                    [name]: value
                }));
                break;
            case 3:
                setGround((prevState)=>({
                    ...prevState,
                    [name]: value
                }));
                break;
            case 4:
                setGlazing((prevState)=>({
                    ...prevState,
                    [name]: value
                }));
                break;
            case 5:
                setCeiling({
                    ...ceiling,
                    [name]: value});
                break;
            default:
                setDoor((prevState)=>({
                    ...prevState,
                    'state': event,
                }));
                break;

        }

    }
    const [lightFormList, setLightFormList]=useState(pieceSelected?.lighting_switches??[]);
    const [electOutletFormList, setElectOutletFormList]=useState(pieceSelected?.electrical_outlets??[]);

    const onChangeLightForm = (event, mode=1)=>{
        const dataToUpdateList = [...lightFormList];
        const newState = {
            ...dataToUpdateList[mode].state,
            label: event.label,
            value: event.value,
        };
        const newItem = {
            ...dataToUpdateList[mode],
            state: newState,
        };
        dataToUpdateList[mode] = newItem;
        setLightFormList([...dataToUpdateList]);

    }
    const onChangeElectricalOutletForm = (event, mode=1)=>{
        const dataToUpdateList = [...electOutletFormList];
        const newState = {
            ...dataToUpdateList[mode].state,
            label: event.label,
            value: event.value,
        };
        const newItem = {
            ...dataToUpdateList[mode],
            state: newState,
        };
        dataToUpdateList[mode] = newItem;
        setElectOutletFormList([...dataToUpdateList]);
    }
    const onChangePieceType = (event)=>{
        setPieces((prevState)=>({
            ...prevState,
            'pieceType': event}));
        setPieceSelect({...propertyAllPieces[event.pieceIndex]});
        setLightFormList([...propertyAllPieces[event.pieceIndex].lighting_switches])
        setElectOutletFormList([...propertyAllPieces[event.pieceIndex].electrical_outlets]);
        setDoor((prevState)=>({
            ...prevState, "type_elem":propertyAllPieces[event.pieceIndex].door.type_elem
        }));
        setWal((prevState)=>({
            ...prevState, "type_elem":propertyAllPieces[event.pieceIndex].wal.type_elem
        }));
        setGround((prevState)=>({
            ...prevState, "type_elem":propertyAllPieces[event.pieceIndex].ground.type_elem
        }));
        setCeiling((prevState)=>({
            ...prevState, "type_elem":propertyAllPieces[event.pieceIndex].ceiling.type_elem
        }));
        setGlazing((prevState)=>({
            ...prevState, "type_elem":propertyAllPieces[event.pieceIndex].glazing.type_elem
        }));
    }
    const sendItemData = ()=>{
        wal.images = convertImage(wal.images);
        pieces.wal = wal;
        door.images = convertImage(door.images)
        pieces.door = door;
        ground.images = convertImage(ground.images)
        pieces.ground = ground;
        glazing.images =convertImage(glazing.images);
        pieces.glazing = glazing;
        ceiling.images = convertImage(ceiling.images);
        pieces.ceiling = ceiling;
        pieces.lighting_switches = lightFormList;
        pieces.electrical_outlets = electOutletFormList;
        getItem(pieces);

    }
    const convertImage = (image:[]):string[]=>{
        const convertImages = [];
        image.map(img=>{
            convertImages.push(URL.createObjectURL(img));
        });
        return  convertImages;
    }
    useEffect(() => {
        if (propertyChoose){
            propertyAllPieces.map((propPiece, index)=>{
                piecesType.push({
                    label:propPiece.label+'('+propPiece.pieceType.value+')', value:propPiece.pieceType.value, pieceIndex:index
                })
            })
        }
    }, []);
    return (
        <div className={"border-primary border border-dashed p-3 rounded"}>
            <div>
                <form className="container">
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangePieceType(event)} label={'Pièces à renseigner'}
                                            value={pieces.pieceType} name={'pieceType'}
                                            dataList={piecesType}></IziSelectField>
                        </div>
                    </div>

                    <h4 className="my-2">Porte {'(' + pieceSelected?.door?.type_elem.value + ')'}</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 1)} label={'État'}
                                            value={door.state} name={'state'} dataList={piecesState}></IziSelectField>
                        </div>
                        <div className="col-6">
                            <IziTextAreaField onChange={(event) => onChangePieceField(event, 1)} name="description"
                                              value={door.description} label={"Description"}></IziTextAreaField>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IziFileField getfile={((event) => onChangeFiles(1, event))}></IziFileField>
                        </div>
                        <div className="col-6">
                            <div className="row gap-3">
                                {
                                    door.images.map((img, i) => {
                                        return <span className="col-6 badge badge-primary p-2"
                                                     key={i}>{img.name} ({img.size / 1000} Kb)</span>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </form>
                <form className="container">
                    <h4 className="my-2">Mur {'(' + pieceSelected?.wal?.type_elem.value + ')'}</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 2)} label={'État'}
                                            value={wal.state} name={'state'} dataList={piecesState}></IziSelectField>
                        </div>

                        <div className="col-6">
                            <IziTextAreaField onChange={(event) => onChangePieceField(event, 2)} name="description"
                                              value={wal.description} label={"Description"}></IziTextAreaField>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IziFileField getfile={((event) => onChangeFiles(2, event))} id={"walFile"}></IziFileField>
                        </div>
                        <div className="col-6">
                            <div className="row gap-3">
                                {
                                    wal.images.map((img, i) => {
                                        return <span key={i}
                                                     className="col-6 badge badge-primary p-2">{img.name} ({img.size / 1000} Kb)</span>
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4 className="my-2">Sol {'(' + pieceSelected?.ground?.type_elem.value + ')'}</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 3)} label={'État'}
                                            value={ground.state} name={'state'} dataList={piecesState}></IziSelectField>
                        </div>

                        <div className="col-6">
                            <IziTextAreaField onChange={(event) => onChangePieceField(event, 3)} name="description"
                                              value={ground.description} label={"Description"}></IziTextAreaField>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IziFileField getfile={((event) => onChangeFiles(3, event))}
                                          id={"groundFile"}></IziFileField>
                        </div>
                        <div className="col-6">
                            <div className="row gap-3">
                                {
                                    ground.images.map((img, i) => {
                                        return <span key={i}
                                                     className="col-6 badge badge-primary p-2">{img.name} ({img.size / 1000} Kb)</span>
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4 className="my-2">Vitrage {'(' + pieceSelected?.glazing?.type_elem.value + ')'}</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 4)} label={'État'}
                                            value={glazing.state} name={'state'}
                                            dataList={piecesState}></IziSelectField>
                        </div>

                        <div className="col-6">
                            <IziTextAreaField onChange={(event) => onChangePieceField(event, 4)} name="description"
                                              value={glazing.description} label={"Description"}></IziTextAreaField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IziFileField getfile={(event) => onChangeFiles(4, event)}
                                          id={"glazingFile"}></IziFileField>
                        </div>
                        <div className="col-6">
                            <div className="row gap-3">
                                {
                                    glazing.images.map((img, i) => {
                                        return <span key={i}
                                                     className="col-6 badge badge-primary p-2">{img.name} ({img.size / 1000} Kb)</span>
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </form>
                <form className="container">
                    <h4 className="my-2">Plafond {'(' + pieceSelected?.ceiling?.type_elem.value + ')'}</h4>
                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={(event) => onChangeState(event, 5)} label={'État'}
                                            value={ceiling.state} name={'state'}
                                            dataList={piecesState}></IziSelectField>
                        </div>

                        <div className="col-6">
                            <IziTextAreaField onChange={(event) => onChangePieceField(event, 5)} name="description"
                                              value={ceiling.description} label={"Description"}></IziTextAreaField>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IziFileField getfile={(event) => onChangeFiles(5, event)}
                                          id={"ceilingFile"}></IziFileField>
                        </div>
                        <div className="col-6">
                            <div className="row gap-3">
                                {
                                    ceiling.images.map((img, i) => {
                                        return <span key={i}
                                                     className="col-6 badge badge-primary p-2">{img.name} ({img.size / 1000} Kb)</span>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </form>
                <form className="container">
                    <h4>Prises électriques</h4>
                    <div className="row h-50 gap-1">
                        {
                            lightFormList.map((light, index) =>
                                    <>
                                        <div className="col-4" key={light}>
                                            <span className="h-50px  badge badge-primary pt-4"
                                                  >{light.label} : {light.state?.value}</span>
                                        </div>

                                        <div className="col-4">
                                            <IziSelectField onChange={(event) => onChangeLightForm(event, index)} label={'État'}
                                                            value={light.state} name={'state'}
                                                            dataList={piecesState}></IziSelectField>
                                        </div>
                                    </>
                            )
                        }
                    </div>
                </form>
                <form className="container">
                    <h4>Éclairage et interrupteurs</h4>
                    <div className="row h-50 gap-1">
                        {
                            electOutletFormList.map((elct_outlet, index) =>
                                <>
                                    <span className="col-4 badge badge-primary h-75"
                                          key={index}>{elct_outlet.label} : {elct_outlet.state.value}</span>
                                    <div className="col-4">
                                        <IziSelectField onChange={(event) => onChangeElectricalOutletForm(event, index)}
                                                        label={'État'}
                                                        value={elct_outlet.state} name={'state'}
                                                        dataList={piecesState}></IziSelectField>
                                    </div>

                                </>
                            )
                        }
                    </div>
                </form>
            </div>
            <div className="d-flex flex-end w-100">

                <IziButton onClick={sendItemData}>Ajouter</IziButton>
            </div>
        </div>
    );
};

export default AddInventoryPiecesForm;
