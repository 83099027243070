import React, {useEffect, useState} from 'react';
import TenantItem from "./TenantItem";
import {useSelector} from "react-redux";
import {selectUserDatasInfos} from "../../../store/authentification/AuthReducer";
import axios_http from "../../../http/axios-plugin";
import endpoint from "../../../endpoint/endpoint.ts";
import {toastAlert} from "../../../shared/helper";

const TenantChoose = () => {
    const currentUser  = useSelector(selectUserDatasInfos)
    console.warn(currentUser)
    useEffect(() => {
        axios_http.get(endpoint.getOwnerTenants(currentUser.owner.id)).then((resp_tenants)=>{
            toastAlert(resp_tenants.data.message).then();
            setOwnerTenants([...resp_tenants.data.data]);
        }).catch(err=>{
            toastAlert(err.response.data.message, "error").then();
        })
    }, []);
    const [ownerTenants, setOwnerTenants]= useState([]);
    return (
        <div className="row g-3 mb-6 ">

            {ownerTenants.map((tenant)=>{
               return <div className="col-sm-6 col-md-4 col-lg-4">
                   <TenantItem tenant={tenant}></TenantItem>
            </div>})
            }
        </div>
    );
};

export default TenantChoose;
