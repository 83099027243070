import React, {useState} from 'react';
import FullCard from "../../shared/components/full-card/FullCard";
import {useNavigate} from "react-router-dom";
import TenantList from "../tenant/TenantList";
import RentList from "../quittance/RentList";

const ContractDetails = () => {
    const route = useNavigate();
    const [step, setStep] = useState(1);
    const backToList = ()=>{
        route(`/dashboard/contracts`)
    }
    return (
        <>
            <FullCard>
                <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                    <div
                        className="d-none d-md-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
                        <i className="mw-50px mw-lg-75px fs-5hx bi bi-file-earmark-medical-fill"></i>
                    </div>

                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-1">
                                    <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bold me-3">Contrat
                                        Numéro: 04HH664MM33</a>
                                    <span className="badge badge-light-success me-auto fs-5">En cours</span>
                                </div>

                                <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-500">
                                    Bien concerné : Amondji pierre, villa basse (03) pièces
                                </div>
                            </div>

                            <div className="d-flex mb-4">
                                <a href="#" onClick={backToList} className="btn btn-sm btn-primary me-3">Retour</a>
                                <a href="#" className="btn btn-sm btn-primary me-3">Télécharger</a>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap justify-content-start">
                            <div className="d-flex flex-wrap">
                                <div
                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-4 fw-bold">29 Jan, 2023</div>
                                    </div>

                                    <div className="fw-semibold fs-6 text-gray-500">Début</div>
                                </div>
                                <div
                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-4 fw-bold">29 Dec, 2023</div>
                                    </div>

                                    <div className="fw-semibold fs-6 text-gray-500">Fin</div>
                                </div>


                                <div
                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-4 fw-bold counted" data-kt-countup="true"
                                             data-kt-countup-value="15000" data-kt-countup-prefix="CFA"
                                             data-kt-initialized="1">6 mois
                                        </div>
                                    </div>

                                    <div className="fw-semibold fs-6 text-gray-500">Durée</div>
                                </div>
                                <div
                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-4 fw-bold counted" data-kt-countup="true"
                                             data-kt-countup-value="15000" data-kt-countup-prefix="CFA"
                                             data-kt-initialized="1">CFA 15,000
                                        </div>
                                    </div>

                                    <div className="fw-semibold fs-6 text-gray-500">Loyer</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="separator"></div>

                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                    <li className="nav-item">
                        <a className={"nav-link text-active-primary py-5 me-6 "+(step === 1 && 'active')}
                           href="#" onClick={()=>setStep(1)}>
                            Infos général </a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link text-active-primary py-5 me-6 "+(step === 2 && 'active')}
                           href="#" onClick={()=>setStep(2)}>
                            Quittances </a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link text-active-primary py-5 me-6 "+(step === 3 && 'active')}
                           href="#" onClick={()=>setStep(3)}>
                            État des lieux </a>
                    </li>
                </ul>
            </FullCard>
            {step === 1 && <FullCard>
                <div className="row fs-5 p-4">
                    <div className="col-sm-12 col-lg-7">
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Locataire</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">N'guessan Arold Donalde</span>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Objet du contrat</label>

                            <div className="col-lg-8 fv-row">
                                <span className="fw-semibold text-gray-800 fs-5">location de bureau professionnel</span>
                            </div>
                        </div>


                        <div className="row mb-7">

                            <label className="col-lg-4 fw-semibold text-muted">Date de revision du loyer</label>


                            <div className="col-lg-8">
                                <span className="fw-semibold text-gray-800 fs-5">06/08/2024</span>
                            </div>

                        </div>


                        <div className="row mb-7">

                            <label className="col-lg-4 fw-semibold text-muted">
                                Montant de la revision du contrat

                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Country of origination"
                                      data-bs-original-title="Country of origination" data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                            </label>


                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">16000</span>
                            </div>

                        </div>

                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">
                                Périodicités de la location

                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Phone number must be active"
                                      data-bs-original-title="Phone number must be active"
                                      data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                            </label>


                            <div className="col-lg-8 d-flex align-items-center">
                                <span className="fw-bold fs-5 text-gray-800 me-2">Mensuelle</span>
                            </div>

                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">
                                Nombre de mois de caution

                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Phone number must be active"
                                      data-bs-original-title="Phone number must be active"
                                      data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                            </label>


                            <div className="col-lg-8 d-flex align-items-center">
                                <span className="fw-bold fs-5 text-gray-800 me-2">4</span>
                            </div>

                        </div>


                        <div className="row mb-7">

                            <label className="col-lg-4 fw-semibold text-muted">Nombre de mois d'avance</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">2</span>
                            </div>

                        </div>


                        <div className="row mb-10">

                            <label className="col-lg-4 fw-semibold text-muted">Montant de la caution</label>


                            <div className="col-lg-8">
                                <span className="fw-semibold fs-5 text-gray-800">120000</span>
                            </div>

                        </div>

                        <div className="row mb-10">

                            <label className="col-lg-4 fw-semibold text-muted">Montant de l'avance</label>


                            <div className="col-lg-8">
                                <span className="fw-semibold fs-5 text-gray-800">120000</span>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-5">
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Date de paiement</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">chaque 5 du mois</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Date de rappelle de paiement</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">chaque 5, 10 du mois</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Mode paiement</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">Espèce, Wave</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Frais d'agence</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">350000</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Montant des charges</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">450000</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Frais de mutation eau</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">250000</span>
                            </div>
                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Frais de mutation électricité</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">350000</span>
                            </div>
                        </div>
                    </div>
                </div>

            </FullCard>}
            {step === 2 && <RentList/>}
            {step === 3 && <FullCard>
                fffffooooooooooooooooooooooooooooooooooooooo
            </FullCard>}
        </>
    );
};

export default ContractDetails;
