import AsideMenu from "./Aside-Menu";
import React from "react";

export const AdminSideBar = () => {
    const Menu=  [
        {
            title: 'Tableau de bord',
            icon: 'ki-outline ki-dollar fs-2',
            id: 'financial',
            isLink:true,
            link:'/admin/dashboard',
        },

        {
            title: 'Mes Utilisateurs',
            icon: 'ki-outline ki-home-3 fs-2',
            id: 'pro',
            activeSub:false,
            isLink:false,
            submenu:
                [
                    {
                        subTitle: 'Comptes particulier',
                        id: 'pro-01',
                        link: '/admin/dashboard/',
                        isActive: false,

                    },
                    {
                        subTitle: 'Comptes entreprise',
                        link: '/admin/dashboard/',
                        id: 'pro-02',
                        isActive: false,
                    }
                ]
        },
        {
            title: 'Archive utilisateur',
            icon: 'ki-outline ki-user-square fs-2',
            id: 'tenant',
            isLink:false,

            activeSub:false,
            submenu:
                [
                    // {
                    //     subTitle: 'Nouvelle Propriété',
                    //     link: '/dashboard'
                    // },
                    {
                        subTitle: 'Liste des archive',
                        link: '/admin/dashboard',
                        id: 'tenant-02',
                        isActive: false,

                    }
                ]
        },
        {
            title: 'Abonnement',
            icon: 'ki-outline ki-file-sheet fs-2',
            id: 'contrat',
            isLink:false,
            activeSub:false,
            submenu:
                [
                    // {
                    //     subTitle: 'Nouvelle Propriété',
                    //     link: '/dashboard'
                    // },
                    {
                        subTitle: 'Liste des contrat',
                        link: '/admin/dashboard',
                        id: 'tenant-02',
                        isActive: false,

                    }
                ]
        },
        {
            title: 'Mon Equipe',
            icon: 'ki-outline ki-user fs-2',
            id: 'staff',
            activeSub:false,
            submenu:
                [
                    {
                        subTitle: 'Nouveau Membre',
                        link: '/admin/dashboard',
                        id: 'staff-01',
                        isActive: false,

                    },
                    {
                        subTitle: 'Lister',
                        link: '/admin/dashboard',
                        id: 'staff-02',
                        isActive: false,

                    }
                ]
        },

    ];

    return (
        <>
            <div id="kt_app_sidebar" className="app-sidebar  flex-column"
                 data-kt-app-sidebar-fixed={true}
                 data-kt-app-header-fixed={true}
                 data-kt-app-sidebar-push-header={true}
                 data-kt-drawer="true" data-kt-drawer-name="app-sidebar"
                 data-kt-drawer-activate="{default: true, lg: false}"
                 data-kt-drawer-overlay={true}
                 data-kt-drawer-width="250px" data-kt-drawer-direction={"start"}
                 data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
                <div
                    id="kt_app_sidebar_wrapper"
                    className="app-sidebar-wrapper" data-kt-app-sidebar-hoverable={true}>
                    <div
                        className="hover-scroll-y my-5 my-lg-2 mx-4"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_app_header"
                        data-kt-scroll-wrappers="#kt_app_sidebar_wrapper"
                        data-kt-scroll-offset="5px">
                        <div id="#kt_app_sidebar_menu"
                             data-kt-menu="true"
                             data-kt-menu-expand="false"
                             className="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary px-3 mb-5">
                            {Menu.map((userMenu) => {
                                return (
                                    <AsideMenu menuItem={userMenu} key={userMenu.id}></AsideMenu>
                                );
                            })}

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}