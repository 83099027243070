import React from 'react';

const StepItem = ({title="", desc="",stepNum =1, isCurrent = false, hasNext = true, isFinal = false}) => {
    return (
        <div className={"stepper-item "+(isCurrent ? 'current ':'') +(isFinal ? 'mark-completed':'')} data-kt-stepper-element="nav">
            <div className="stepper-wrapper">
                <div className="stepper-icon w-40px h-40px">
                    <i className="ki-outline ki-check fs-2 stepper-check"></i>
                    <span className="stepper-number">{stepNum}</span>
                </div>

                <div className="stepper-label">
                    <h3 className="stepper-title">
                        {title}
                    </h3>

                    <div className="stepper-desc fw-semibold">
                        {/*{desc}*/}
                    </div>
                </div>
            </div>

            {hasNext &&<div className="stepper-line h-40px"></div>}
        </div>

    );
};

export default StepItem;
