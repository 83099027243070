import React from 'react';
const Card = (props) => {
    return (
        <>
            <div className="card overflow-hidden h-md-100 mb-5 mb-xl-10 bg-white shadow border-1 border-white">
                <div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
                    <div className="mb-4 px-9">
                        <div className="d-flex align-items-center mb-2">
                            {
                               props.hasIcon === true && <i className={props.cardIcon+ " fs-3hx fw-bold  me-2 lh-1"}></i>
                            }

                        </div>

                        <span className="fs-4 fw-semibold text-gray-400">{props.subTitle}</span>
                        <div     className="fs-3hx fw-bold text-gray-800 me-2 lh-1">
                                {props.title}{ props.isAmount=== true &&<sup
                            className="fs-4 fw-semibold text-gray-400 align-self-start me-1>">FCFA</sup>}
                            </div>
                        {props.children}
                    </div>

                </div>
            </div>
        </>
    )
}
export default Card;