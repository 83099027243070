import React, {Component} from "react";
import Card from "../../shared/components/card/Card";
import IziChart from "../../shared/components/charts/IziChart";

export class Analytic extends Component {
    render() {
        return (
            <>
                <h2>resumé avancé </h2>
                <div className="row g-5 g-xl-10">
                    <div className="col-xxl-12 mb-md-5 mb-xl-10">
                        <div className="row g-5 g-xl-10">
                            <div className="col-12 mb-xxl-10">
                                <Card
                                    subTitle={"Commission moyenne par vente"}
                                >
                                    <IziChart></IziChart>
                                </Card>
                            </div>
                        </div>
                        <div className="row g-5 g-xl-10">
                            <div className="col-12 mb-xxl-10">
                                <Card
                                    subTitle={"top 5 biens les plus rentable"}
                                >
                                    <IziChart chartType={'bar'} abscisseLabel={['Villa duplex', 'immeuble hamilton', 'Appart 05','Magasin Y5', 'appart gba 2']}
                                              dataSeries={[
                                                  {
                                                      name: "gain",
                                                      data: [30000, 40666, 45452, 50000, 43369]
                                                  }
                                              ]}
                                    ></IziChart>
                                </Card>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}