import React from 'react';

const Paginator = ({itemPerPage = 5,currentPage=1, setCurrentPage, totalRecord=1, start,end}) => {
    const nPages = Math.ceil(totalRecord / itemPerPage);
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const getNextPage = () => {
        if(currentPage !== nPages)
            setCurrentPage(currentPage + 1)
    }
    const getPrevPage = () => {
        if(currentPage !== 1)
            setCurrentPage(currentPage - 1)
    }
    return (
        <>
            <div className="d-flex flex-stack flex-wrap pt-10">
                <div className="fs-6 fw-semibold text-gray-700">
                    Affichage de {start} à {end} sur {totalRecord} éléments
                </div>
                <ul className="pagination">
                    <li className="page-item previous" onClick={getPrevPage} >
                        <a href="#" className="page-link"><i className="previous"></i></a>
                    </li>

                    {pageNumbers.map((pgNumber)=>{
                        return   <li className={`page-item ${currentPage === pgNumber ? 'active' : ''} `} key={pgNumber}>
                            <a href="#" onClick={() => setCurrentPage(pgNumber)}  className="page-link">{pgNumber}</a>
                        </li>
                        })

                    }

                    <li className="page-item next" onClick={getNextPage}>
                        <a href="#" className="page-link"><i className="next"></i></a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Paginator;