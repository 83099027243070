import { createSlice } from '@reduxjs/toolkit'

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        accountType:"",
        userAccount:null,
        userAccountValidation:false,
        userInfosValidation:false,
        userInfos:null,
    },
    reducers: {
        setUserType: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            // Also, no return statement is required from these functions.
            state.accountType = action.payload
        },
        setAccountInfos: (state, action) => {
            state.userAccount = action.payload
        },
        setAccountInfosValidation: (state, action) => {
            state.userAccountValidation = action.payload
        },
        setUserInfosValidation: (state, action) => {
            state.userInfosValidation = action.payload
        },
        setUserPersonalDatas: (state, action) => {
            state.userInfos = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setUserType, setAccountInfos, setUserPersonalDatas,
    setAccountInfosValidation,
    setUserInfosValidation } = customerSlice.actions
export const selectAccountType = (state) => state.customer.accountType
export const selectUserAccount = (state) => state.customer.userAccount
export const selectUserInfos = (state) => state.customer.userInfos
export const selectUserAccountValidation = (state) => state.customer.userAccountValidation
export const selectUserInfosValidation = (state) => state.customer.userInfosValidation
export default customerSlice.reducer