import React, {useState} from 'react';
import ConfigEmailEditor from "../../shared/components/email-editor/ConfigEmailEditor";
import ConfigEmails from "./ConfigEmails";
import ConfigFinance from "./ConfigFinance";

const UserConfiguration = () => {
    const [tabSwitch, setTabSwitch] = useState(0)
    return (
        <>
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                <li className="nav-item mt-2" onClick={() => setTabSwitch(0)}>
                    <a className={"nav-link text-active-primary ms-0 me-10 py-5 " + (tabSwitch === 0 ? "active" : "")}
                       href="#">
                        Nouveau modèle mail</a>
                </li>

                <li className="nav-item mt-2" onClick={() => setTabSwitch(1)}>
                    <a className={"nav-link text-active-primary ms-0 me-10 py-5 " + (tabSwitch === 1 ? "active" : "")}
                       href="#">
                        Mes modèles mails </a>
                </li>


                <li className="nav-item mt-2" onClick={() => setTabSwitch(2)}>
                    <a className={"nav-link text-active-primary ms-0 me-10 py-5 " + (tabSwitch === 2 ? "active" : "")}
                       href="#">
                        Finance </a>
                </li>


            </ul>
            <div className="col mt-5">
                {
                    tabSwitch ===0 && <ConfigEmailEditor></ConfigEmailEditor>
                }
                {
                    tabSwitch ===1 && <ConfigEmails></ConfigEmails>
                }
                {
                    tabSwitch ===2 && <ConfigFinance></ConfigFinance>
                }
            </div>
        </>
    );
};

export default UserConfiguration;
