import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken:"",
        userDatas:null,
        ownerAgency:null
    },
    reducers: {
        setAccessToken: (state, action)=>{
            state.accessToken = action.payload
        },
        setOwnerAgency: (state, action)=>{
            state.ownerAgency = action.payload
        },
        setUserDatasInfos: (state, action)=>{
            state.userDatas = action.payload
        }
    },
})

export const {setAccessToken, setUserDatasInfos, setOwnerAgency} = authSlice.actions
export const selectAccessToken = (state)=> state.auth.accessToken
export const selectUserDatasInfos = (state)=> state.auth.userDatas
export const selectOwnerAgency = (state)=> state.auth.ownerAgency
export const isAuth = (state)=> state.auth.userDatas !== null
export default authSlice.reducer