import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {errorAlert, stepValidation, successAlert, toastAlert} from "../../shared/helper";
import IziStepper from "../../shared/components/stepper/IziStepper";
import StepContent from "../../shared/components/stepper/StepContent";
import IziButton from "../../shared/button/IziButton";
import ContractInventory from "./components/ContractInventory";
import Tenant from "./components/Tenant";
import Bail from "./components/Bail";
import Rent from "./components/Rent";
import {useDispatch, useSelector} from "react-redux";
import {selectPropertyChoose} from "../../store/add-property/AddPropertyReducer";
import {
    selectContractInfos,
    selectContractRent,
    selectEnterInventoryExtra,
    selectEnterInventoryExtraTwo,
    selectIsNewTenant,
    selectPropertyEnterInventory,
    selectTenantChoose,
    selectTenantChooseGuard, setContractInfos, setContractRent, setIsNewTenant,
    setPropertyEnterInventory,
    setPropertyEnterInventoryExtra, setPropertyEnterInventoryExtraTwo, setTenantChoose, setTenantChooseGuard
} from "../../store/add-contract/AddContractReducer";
import {v4 as uuidv4} from "uuid";
import axios_http from "../../http/axios-plugin";
import endpoint from "../../endpoint/endpoint.ts";
import tenantChoose from "./components/TenantChoose";

const NewContact = () => {
    let { id } = useParams();
    const propertyChoose = useSelector(selectPropertyChoose)
    const routeRedirect = useNavigate();
    const inventoryExtraData = useSelector(selectEnterInventoryExtra);
    const inventoryExtraTwoData = useSelector(selectEnterInventoryExtraTwo);
    const inventoryPiecesData = useSelector(selectPropertyEnterInventory);
    const newTenantData = useSelector(selectIsNewTenant);
    const tenantData = useSelector(selectTenantChoose);
    const tenantGuardData = useSelector(selectTenantChooseGuard);
    const contractData = useSelector(selectContractInfos);
    const rentData = useSelector(selectContractRent);
    const [load, setLoad] = useState(false)
    const saveState = useDispatch();
    const router = useNavigate()
    const convertToFile = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], "filename", { type: blob.type });
    };
    const storePropertyContract =async ()=>{
        if (rentData){
            setTimeout(()=>{
                setLoad(true)
            },200);
            const newInventoryFormData = new FormData();
            newInventoryFormData.append("pieces_keys", JSON.stringify(inventoryExtraTwoData));
            const updatedInventoryPiecesData =[];
                for (const piece of inventoryPiecesData) {
                const newPiece =  {...piece}; // Shallow copy of the piece

                for (const [key, value] of Object.entries(newPiece)) {
                    if (!["label", "pieceType", "lighting_switches", "electrical_outlets"].includes(key)) {
                        const newImageData = [];
                        if (Array.isArray(value['images'])) {
                            for (const pieceImage of value['images']) {
                                const img_id = uuidv4();
                                const img_file = await convertToFile(pieceImage)
                                newInventoryFormData.append(img_id, img_file);
                                newImageData.push(img_id);
                            }
                        }
                        newPiece[key] = {...value, images: newImageData};
                    }
                }

                updatedInventoryPiecesData.push(newPiece);
            }
            let tenantCreated:any = null;
            if(newTenantData){
                const tenantEmergencyFormData = new FormData();
                const newTenantFormData = new FormData();
                for (const [key, value] of Object.entries(tenantGuardData)) {
                    if ((key === "id_card_recto" && value !== null) || (key === "id_card_verso" && value !== null)){
                        if (key === "id_card_recto"){
                            const img_id = uuidv4();
                            const img_file = await convertToFile(value);
                            newTenantFormData.append(img_id, img_file)
                            newTenantFormData.append("id_card_recto_file", img_id)

                        }else {
                            const img_id = uuidv4();
                            const img_file = await convertToFile(value);
                            newTenantFormData.append(img_id, img_file)
                            newTenantFormData.append("id_card_verso_file", img_id)
                        }
                    }else {
                        if (key !== "email" && key !=="password"){
                            const formatValue = typeof value === "object" ? JSON.stringify(value):value;
                            tenantEmergencyFormData.append(key, formatValue)
                        }
                    }
                }
                const contactCountry = tenantGuardData.country ? tenantGuardData.country : "Côte d'ivoire";
                tenantEmergencyFormData.append("country", contactCountry)
                const tenantEmergencyContactInfos = await axios_http.post(endpoint.storeTenantEmergencyContact, tenantEmergencyFormData).then(res=>{
                    toastAlert(res.data.message,"success").then()
                    return res.data.data
                }).catch((err)=>{
                    toastAlert(err.message, 'error').then()
                });
                for (const [key, value] of Object.entries(tenantData)) {
                    if ((key === "id_card_recto" && value !== null) || (key === "id_card_verso" && value !== null)){
                        if (key === "id_card_recto"){
                            const img_id = uuidv4();
                            const img_file = await convertToFile(value);
                            newTenantFormData.append(img_id, img_file)
                            newTenantFormData.append("id_card_recto_file", img_id)

                        }else {
                            const img_id = uuidv4();
                            const img_file = await convertToFile(value);
                            newTenantFormData.append(img_id, img_file)
                            newTenantFormData.append("id_card_verso_file", img_id)
                        }

                    }else {
                        const formatValue = typeof value === "object" ? JSON.stringify(value):value;
                        newTenantFormData.append(key, formatValue)
                    }
                }
                const tenantCountry = tenantData.country ? tenantData.country : "Côte d'ivoire";
                newTenantFormData.append("country", tenantCountry)
                newTenantFormData.append("tenant_emergency_contact_id", tenantEmergencyContactInfos.id)
                const tenantInfos = await axios_http.post(endpoint.storeTenant, newTenantFormData).then(res=>{
                    toastAlert(res.data.message,"success").then()
                    return res.data.data
                }).catch((err)=>{
                    toastAlert(err.message, 'error').then()
                });
                tenantCreated = tenantInfos
            }else {
                tenantCreated = tenantData
            }

            newInventoryFormData.append("property_id", JSON.stringify(propertyChoose.id));
            newInventoryFormData.append("inventory_type", "ENTER");
            newInventoryFormData.append("tenant_id", tenantCreated.id);
            newInventoryFormData.append("inventory_pieces", JSON.stringify(updatedInventoryPiecesData));
            newInventoryFormData.append("commodity_subscribe", JSON.stringify(inventoryExtraData));
            newInventoryFormData.append("pieces_keys", JSON.stringify(inventoryExtraTwoData));
           const newPropertyInventory =  await  axios_http.post(endpoint.storePropertyInventory, newInventoryFormData).then((resp_inventory)=>{
               toastAlert(resp_inventory.data.message,"success").then()
               return resp_inventory.data.data
            }).catch((err)=>{
                console.log(err);
               toastAlert(err.message, 'error').then()
            });
             const newContractFormData = new FormData();
            newContractFormData.append("property_id", JSON.stringify(propertyChoose.id));
            newContractFormData.append("tenant_id", tenantCreated.id);
             newContractFormData.append("inventory_id", newPropertyInventory.id);
             const contractUpdate = {...contractData}
             if (contractData.bail_other_model){
                 const other_file_name =uuidv4()
                 const img_file =await  convertToFile(contractData.bail_other_model_file)
                 newContractFormData.append(other_file_name,img_file);
                 contractUpdate.bail_other_model_file = other_file_name;
             }
             newContractFormData.append("bail_infos", JSON.stringify(contractUpdate));
             newContractFormData.append("bail_rent_infos", JSON.stringify(rentData));
             axios_http.post(endpoint.storeContract, newContractFormData).then(res=>{
                 successAlert(res.data.message).then(confirm =>{
                     saveState(setPropertyEnterInventory(null));
                     saveState(setPropertyEnterInventoryExtra([]));
                     saveState(setPropertyEnterInventoryExtraTwo([]));
                     saveState(setTenantChoose(null));
                     saveState(setTenantChooseGuard(null));
                     saveState(setIsNewTenant(false));
                     saveState(setContractInfos(null));
                     saveState(setContractRent(null));
                     if (confirm.isConfirmed){
                         router("/properties")
                     }
                 })
             }).catch((err)=>{
                 console.warn(err)
                 toastAlert(err.message, "error")
             }).finally(()=>{
                 setTimeout(()=>{
                     setLoad(false)
                 },200);
             })
        }else {
            errorAlert("Ooops!", "Veuillez renseigné les infos relatives au loyer du contrat", "compris").then()
        }
    }
    const redirect = (uri) => {
        routeRedirect(uri);
    };
    const getBailData = (event)=>{
        console.log(event)
    }
    const getRentData = (event)=>{
        console.log(event)
    }
    function getPropList() {
        redirect('/dashboard/properties');
    }
    const  stepItems = [
        {
            label: "État des lieux",
            isCurrent: true,
        },
        {
            label: "Locataire",
            isCurrent: false,
        },
        {
            label: "Bail",
            isCurrent: false,
        },
        {
            label: "Loyer",
            isCurrent: false,
        },
    ];
    const [stepItem, setStepItem] = useState(stepItems)
    const  [stepMove, setStepMove]=useState(0);
    function  handleMove(next ){
        switch(stepMove){
            case 0:
                if (next){
                    if (inventoryExtraData  && inventoryPiecesData.length ){
                        stepItem.map((item)=> item.isCurrent = false);
                        stepItem[stepMove].isCurrent = true;
                        setStepItem([...stepItems]);
                        setStepMove((prevState)=>prevState + 1);

                    }else {
                        if (!inventoryPiecesData.length){
                            errorAlert("Ooops!", "Veuillez renseigné l'état des pieces du bien", "compris").then()
                        }
                        if (!inventoryExtraData){
                            errorAlert("Ooops!", "Veuillez renseigné les infos relatives aux compteurs  du bien", "compris").then()
                        }
                    }
                }else {
                    setStepMove((prevState)=>prevState - 1)
                }


                break;
            case 1:
                if (next){
                    if ((tenantData && newTenantData===false) || (tenantData && newTenantData===true && tenantGuardData)){
                        stepItem.map((item)=> item.isCurrent = false);
                        stepItem[stepMove].isCurrent = true;
                        setStepItem([...stepItem]);
                        setStepMove((prevState)=>prevState + 1)
                    }else {
                        if ((tenantData===null && newTenantData===false)){
                            errorAlert("Ooops!", " Veuillez selectionné un locataire", "compris").then()
                        }
                        if ((tenantData===null && newTenantData===true && tenantGuardData===null)){
                            errorAlert("Ooops!", "Veuillez bien renseigné les infos relatives au nouveau locataire", "compris").then()
                        }
                    }

                }else {
                    setStepMove((prevState)=>prevState - 1);
                }
                break;
            case 2:
                if (next){
                    if (contractData !== null){
                        const checkInterval = {
                            property_id: propertyChoose.id,
                            new_start_date: contractData.start_date,
                            new_end_date: contractData.end_date,
                        }
                        axios_http.post(endpoint.checkContractDateInterval,checkInterval).then(resp_check=>{
                            if (resp_check.data.status === true){
                                stepItem.map((item)=> item.isCurrent = false);
                                stepItem[stepMove].isCurrent = true;
                                setStepItem([...stepItem]);
                                setStepMove((prevState)=>prevState + 1);
                            }else {
                                errorAlert("Date Icorrecte!", "l'intervalle de date choisir est correspond déjà au contrat:"+
                                    resp_check.data.contract_infos.object+" Debut: "+resp_check.data.contract_infos.start_date+", Fin: "+resp_check.data.contract_infos.end_date, "compris").then()
                            }
                        }).catch(res_error=>{
                            console.warn(res_error.error)
                        });

                    }else {
                        errorAlert("Ooops!", "Veuillez bien renseigné les infos relatives au contrat", "compris").then()
                    }

                }else {
                    setStepMove((prevState)=>prevState - 1);
                }
                break;
            case 3:
                if (next){
                    stepItem.map((item)=> item.isCurrent = false);
                    stepItem[stepMove].isCurrent = true;
                    setStepItem([...stepItem]);
                    setStepMove((prevState)=>prevState + 1)
                }else{
                    setStepMove((prevState)=>prevState - 1);
                }
                break;
            default:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[0].isCurrent = true;
                setStepItem([...stepItem]);
                setStepMove((prevState)=>prevState + 1);

                break;
        }

    }

    return (
        <>
            <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                    <h4>Ajouter un nouveau contrat au bien : {propertyChoose.label}</h4>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    <button className="btn btn-flex btn-primary h-40px fs-7 fw-bold" onClick={getPropList}>
                        Retour
                    </button>
                </div>
            </div>

            <IziStepper stepItemList={stepItem} className={"w-100"}>
                {stepMove === 0 && <StepContent headTitle={"État des lieux entrée"}
                                                headDesc={"Veuillez renseigner les infos de l'état des lieux entrée avant signature du contrat"}
                                                isActive={true}>
                    <ContractInventory></ContractInventory>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Suivant
                        </IziButton>
                    </div>
                </StepContent>
                }
                {stepMove === 1 && <StepContent headTitle={"Locataire du bien"} headDesc={"Veuillez sélectionnez le locataire ou ajouter un nouveau"}
                                                isActive={true}>
                   <Tenant></Tenant>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Précedent
                        </IziButton>
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Suivant
                        </IziButton>
                    </div>
                </StepContent>
                }
                {stepMove === 2 && <StepContent headTitle={"Bail"} headDesc={"Veuillez renseigné les terms du contract"}
                                                isActive={true}>
                    <Bail ></Bail>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Précedent
                        </IziButton>
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Suivant
                        </IziButton>
                    </div>
                </StepContent>
                }
                {stepMove === 3 && <StepContent headTitle={"loyer"} headDesc={"Veuillez renseigné les infos liées au loyer"}
                                                isActive={true}>
                    <Rent getRentData={getRentData}></Rent>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Précedent
                        </IziButton>
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false}  onClick={storePropertyContract}>
                            Ajouter
                            {load === true &&<span className={load === true ? "d-inline-block" : "indicator-progress"}>
                                                    Patientez svp...
                                                    <span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>}
                        </IziButton>
                    </div>
                </StepContent>
                }
            </IziStepper>

        </>
    );
};

export default NewContact;
