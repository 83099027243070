import React from 'react';

const TenantDetails = () => {
    return (
        <div className="card card-flush bg-white shadow border-1 border-light h-lg-100" id="kt_contacts_main">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                <div className="card-title">
                    <i className="ki-outline ki-information-3 fs-1 me-2"></i>
                    <h2>Information du locataire</h2>
                </div>

                <div className="card-toolbar gap-3">
                    {/*<a href="#"*/}
                    {/*   className="btn btn-sm btn-light btn-active-light-primary">*/}
                    {/*    <i className="ki-outline ki-arrow-down fs-2"></i> Télécharger*/}
                    {/*</a>*/}
                </div>
            </div>

            <div className="card-body pt-5">
                <div className="d-flex gap-7 align-items-center">
                    <div className="d-flex flex-column gap-2">
                        <h3 className="mb-0">Mme Drago Malfoy Serpenta</h3>

                        <div className="d-flex align-items-center gap-2">
                            <i className="ki-outline ki-phone fs-2"></i>
                            <a href="#" className="text-muted text-hover-primary">Contact: +225 05 66 99 88 45</a>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                            <i className="ki-outline ki-address-book fs-2"></i>
                            <a href="#" className="text-muted text-hover-primary">Adresse: Charlie Armageddon 52 rue pol </a>
                        </div>
                    </div>
                </div>


                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                    role="tablist">

                    <li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                           data-bs-toggle="tab" href="#kt_contact_view_general" aria-selected="true" role="tab">
                            <i className="ki-outline ki-home fs-4 me-1"></i> Général
                        </a>
                    </li>


                    {/*<li className="nav-item" role="presentation">*/}
                    {/*    <a className="nav-link text-active-primary d-flex align-items-center pb-4"*/}
                    {/*       data-bs-toggle="tab" href="#kt_contact_view_meetings" aria-selected="false"*/}
                    {/*       tabIndex="-1" role="tab">*/}
                    {/*        <i className="ki-outline ki-more-2 fs-4 me-1"></i> Plus*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                </ul>


                <div className="tab-content" id="">
                    <br/>
                    <div className="tab-pane fade show active" id="kt_contact_view_general" role="tabpanel">


                        <div className="d-flex flex-column gap-5 mt-7">

                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Profession</div>
                                <div className="fw-bold fs-5">Inspecteur</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Email</div>
                                <div className="fw-bold fs-5">saintdenis78@gmail.com</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Contact d'urgence</div>
                                <div className="fw-bold fs-5">Mr Anderson | Contact: +225 07 89 55 22 36</div>
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Type de pièces</div>
                                <div className="fw-bold fs-5"> CNI</div>
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">N°pièces</div>
                                <div className="fw-bold fs-5"> C025 66655 8899</div>
                            </div>
                        </div>

                    </div>


                    <br/>
                    <div className="tab-pane fade" id="kt_contact_view_meetings" role="tabpanel">
                        <div className="row">
                            <div className="col">
                                <h4 className="fs-4 fw-bold text-center my-2">Abonnement</h4>
                            </div>
                        </div>
                    </div>

                    <br/>

                </div>


            </div>

        </div>
    );
};

export default TenantDetails;
