import React, {useState} from 'react';
import IziButton from "../../../shared/button/IziButton";
import IziModal from "../../../shared/components/modal/IziModal";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziInputField from "../../../shared/components/form/IziInputField";
import IziFileField from "../../../shared/components/form/IziFileField";

const TenantInfos = ({tenant, onModify}) => {
    const [modif, setModif]=useState(false);
    const [tenantUpdate, setTenantUpdate] = useState(tenant);
    const onChange = (event) => {
        const { name, value, type } = event.target;
        const isCheckbox = type === "checkbox";
        setTenantUpdate({
            ...tenantUpdate,
            [name]: isCheckbox ? event.target.checked === true ? [...tenantUpdate.access,value]:[value] : value,
        });

    };
    const idCardType = [
        {label:"Carte National d'identité", value:"CNI"},
        {label:"Passeport", value:"Passeport"},
        {label:"Permis de conduire", value:"Permis de conduire"},
        {label:"Attestation d'identité", value:"Attestation"},
    ];
    const civilityList = [
        {label:"Monsieur", value:"Mr"},
        {label:"Madame", value:"Mme"},
        {label:"Mademoiselle", value:"Mlle"},
    ];
    const onChangeId = (event)=>{
        setTenantUpdate({
            ...tenantUpdate,
            'id_card_type': event,});
    }
    const onChangeCivility = (event)=>{
        setTenantUpdate({
            ...tenantUpdate,
            'civility': event});
    }
    const closeModal = ()=>{
        setModif(false)
    }
    return (
        <>
            {modif === true && <IziModal modalTitle={"Modifier les information du locataire"} closeValue={modif} closeModal={closeModal}>
                <form>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziSelectField onChange={(event) => onChangeCivility(event)} label={'Civilité'}
                                            value={tenantUpdate.civility} name={'civility'}
                                            dataList={civilityList}></IziSelectField>

                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'Nom'} onChange={(event) => onChange(event)} type={'text'}
                                           validateRule={['required']} errorMessage={'Veuillez saisir le nom du membre'}
                                           value={tenantUpdate.last_name} name={'last_name'} required={true}/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'Prénom'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir le prénom du membre'}
                                           value={tenantUpdate.first_name} name={'first_name'} required={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'Commune'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir la commune du membre'}
                                           value={tenantUpdate.township} name={'township'} required={true}/>

                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'Ville'} onChange={onChange} type={'text'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir la ville du membre'} value={tenantUpdate.town}
                                           name={'town'} required={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'Contact'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir la ville du membre'}
                                           value={tenantUpdate.contact} name={'contact'} required={true}
                                           pattern="^[0-9]{10}$"/>

                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'Profession'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir la ville du membre'}
                                           value={tenantUpdate.contact} name={'contact'} required={true}
                                           pattern="^[0-9]{10}$"/>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziSelectField onChange={(event) => onChangeId(event)} label={'Type de pièces d\'identité'}
                                            value={tenantUpdate.id_card_type} name={'id_card_type'}
                                            dataList={idCardType}></IziSelectField>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'N°pièces'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir la ville du membre'}
                                           value={tenantUpdate.id_card} name={'id_card'} required={true}
                                           pattern="^[0-9]{10}$"/>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziFileField label={"Recto de la pièce d'identité"}></IziFileField>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziFileField label={"Verso de la pièce d'identité"}></IziFileField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <IziInputField label={'Email'} onChange={onChange} type={'email'} name={'email'}
                                           value={tenantUpdate.email}
                                           errorMessage={'Veuillez saisir l\'email du membre'} required={true}/>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex  justify-content-end">
                            <IziButton>Sauvegarder</IziButton>
                        </div>
                    </div>
                </form>
            </IziModal>}
        <div className="card  bg-white shadow border-1 border-light mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Informations Détaillés du locataire</h3>
                </div>
                <div className="my-3">
                    <IziButton onClick={()=>setModif(true)}>Modifier</IziButton>
                </div>
            </div>

            <div className="card-body p-9 fs-5">
                <div className="row">
                    <div className="col-sm-12 col-lg-8">
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Nom</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">{tenant.civility.value} {tenant.last_name} {tenant.first_name}</span>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">Pays</label>

                            <div className="col-lg-8 fv-row">
                                <span className="fw-semibold text-gray-800 fs-5">{tenant.country}</span>
                            </div>
                        </div>


                        <div className="row mb-7">

                            <label className="col-lg-4 fw-semibold text-muted">Ville</label>



                            <div className="col-lg-8">
                                <span className="fw-semibold text-gray-800 fs-5">{tenant.town}</span>
                            </div>

                        </div>



                        <div className="row mb-7">

                            <label className="col-lg-4 fw-semibold text-muted">
                                Commune

                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Country of origination" data-bs-original-title="Country of origination" data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                            </label>



                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">{tenant.township}</span>
                            </div>

                        </div>

                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">
                                Contact

                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Phone number must be active" data-bs-original-title="Phone number must be active"
                                      data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                            </label>


                            <div className="col-lg-8 d-flex align-items-center">
                                <span className="fw-bold fs-5 text-gray-800 me-2">{tenant.contact}</span>
                                {/*<span className="badge badge-success">Verified</span>*/}
                            </div>

                        </div>
                        <div className="row mb-7">
                            <label className="col-lg-4 fw-semibold text-muted">
                                Profession

                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Phone number must be active" data-bs-original-title="Phone number must be active"
                                      data-kt-initialized="1">
                    <i className="ki-outline ki-information fs-7"></i>                </span>
                            </label>


                            <div className="col-lg-8 d-flex align-items-center">
                                <span className="fw-bold fs-5 text-gray-800 me-2">{tenant.job}</span>
                            </div>

                        </div>


                        <div className="row mb-7">

                            <label className="col-lg-4 fw-semibold text-muted">Pièces</label>

                            <div className="col-lg-8">
                                <span className="fw-bold fs-5 text-gray-800">{tenant.id_card_type.value}</span>
                            </div>

                        </div>



                        <div className="row mb-10">

                            <label className="col-lg-4 fw-semibold text-muted">Référence de la pièce</label>



                            <div className="col-lg-8">
                                <span className="fw-semibold fs-5 text-gray-800">{tenant.id_card}</span>
                            </div>

                        </div>

                        <div className="row mb-10">

                            <label className="col-lg-4 fw-semibold text-muted">Email</label>



                            <div className="col-lg-8">
                                <span className="fw-semibold fs-5 text-gray-800">{tenant.email}</span>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <label className=" fw-semibold text-muted">Copies de la pièce</label>
                        <div className="row mb-10">

                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col">
                                        <img src={"/assets/images/img_test.jpg"} alt="recto" className="w-75  rounded" />
                                    </div>
                                    <div className="col">
                                        <img src={"/assets/images/img_test.jpg"} alt="recto" className="w-75  rounded" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div>
        </>
    );
};

export default TenantInfos;
