import React from 'react';
import TenantChoose from "./TenantChoose";
import IziButton from "../../../shared/button/IziButton";

const EditTenant = () => {
    return (
        <>
            <TenantChoose/>
            <div className="py-3 d-flex justify-content-end">
                <IziButton>Modifier</IziButton>
            </div>

        </>
    );
};

export default EditTenant;
