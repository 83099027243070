import FullCard from "../../shared/components/full-card/FullCard";
import React from "react";
import {useNavigate} from "react-router-dom";

export function RentItem({receipt}) {
    const route = useNavigate();
    const setModification = ()=>{
        route(`/dashboard/contract/rent/${receipt.uid}/edit`);
    }
    const getRentDetails = ()=>{
        route(`/dashboard/contract/rent/${receipt.uid}/details`);
    }
    const payReceipt = ()=>{
        route(`/dashboard/contract/rent/${receipt.uid}/payment`);
    }
    return (
        <FullCard isItem={true}>
            <div className="symbol symbol-75px symbol-circle mb-5">
                        <span className="symbol-label fs-2x fw-semibold text-info bg-light-primary">
                            <i className="bi bi-receipt fs-3x"></i>
                        </span>
            </div>
            <p  className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">N°: {receipt.uid}</p>
            <div className="d-flex flex-center flex-wrap mb-4 w-100">
                <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                   <div className="fs-6 fw-bold text-gray-700">{receipt.month}</div>
                    <div className="fw-semibold text-gray-400">Mois</div>
                </div>

                <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                    <div className="fs-6 fw-bold text-gray-700">{receipt.amount}</div>
                    <div className="fw-semibold text-gray-400">Montant</div>
                </div>
                <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                    <div className="fs-6 fw-bold text-gray-700">{receipt.status.label}</div>
                    <div className="fw-semibold text-gray-400">Statut</div>
                </div>
            </div>
            <div className="d-flex flex-center flex-wrap gap-3 w-100">
                <button className="btn btn-sm btn-light-primary btn-flex btn-center"
                        onClick={getRentDetails}
                        data-kt-follow-btn="true">
                    <i className="ki-outline ki-eye following fs-3"></i>
                    <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                    <span className="indicator-label">Détails</span>
                </button>
                {receipt.status.value === "unpaid" && <button className="btn btn-sm btn-light-primary btn-flex btn-center"
                         onClick={setModification}
                         data-kt-follow-btn="true">
                    <i className="ki-outline bi-pencil-square following fs-3"></i>
                    <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                    <span className="indicator-label">Modifier</span>
                </button>}
                {receipt.status.value === "unpaid" &&<button className="btn btn-sm btn-light-primary btn-flex btn-center"
                         onClick={payReceipt}
                         data-kt-follow-btn="true">
                    <i className="ki-outline bi-credit-card-2-back following fs-3"></i>
                    <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                    <span className="indicator-label">Payer</span>
                </button>}
            </div>

        </FullCard>
    )
}