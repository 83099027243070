import React, {useRef, useState} from 'react';
import IziButton from "./button/IziButton";

const TabPanne = ({tabElement = [], children, backTo, tabTitle='', tabIndex}) => {
    const [activeTab, setActiveTab] = useState(0)
    return (
        <div className="w-100">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold shadow-lg rounded p-6">
                {
                    tabElement.map((tab, i) =>{
                       return  <li className="nav-item mt-2" key={i} onClick={()=> {
                           setActiveTab(i);
                           tabIndex(i);
                       }}>
                            <a className={activeTab === i ?"nav-link text-active-primary ms-0 me-10 py-5 active":"nav-link text-active-primary ms-0 me-10 py-5 "} href="#">
                                {tab}
                            </a>
                        </li>
                    })
                }
            </ul>
            <div className="card mb-5 mt-5 mb-xl-10  shadow-lg rounded p-3">
                <div className="card-header border-0 cursor-pointer bg-white" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div className="card-title  justify-content-between w-100">
                        <h3 className="fw-bold mr-3">{tabTitle}</h3>
                        <IziButton  onClick={backTo} buttonType={'button'}>Retour</IziButton>
                    </div>
                </div>

                <div id="kt_account_settings_profile_details" className="collapse show" data-select2-id="select2-data-kt_account_settings_profile_details">
                        <div className="card-body border-top p-9">
                            {children}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default TabPanne;