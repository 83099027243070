import React from 'react';
import TenantItem from "./TenantItem";

const OldTenantList = () => {
    return (
        <div className="card bg-white shadow border-1 border-light card-flush" id="kt_contacts_list">
            <div className="card-header pt-7" id="kt_contacts_list_header">
                <h5 className="fw-bold fs-4">Liste des locataires</h5>

            </div>
            <div className="card-body pt-5" id="kt_contacts_list_body">
                <TenantItem isCurrent={true}></TenantItem>
                <div className="separator separator-dashed d-none"></div>
                <TenantItem isCurrent={false}></TenantItem>
                <div className="separator separator-dashed d-none"></div>
                <TenantItem isCurrent={false}></TenantItem>
                <div className="separator separator-dashed d-none"></div>
                <TenantItem isCurrent={false}></TenantItem>
                <div className="separator separator-dashed d-none"></div>
                <TenantItem isCurrent={false}></TenantItem>
                <div className="separator separator-dashed d-none"></div>
                <TenantItem isCurrent={false}></TenantItem>
                <div className="separator separator-dashed d-none"></div>
                <TenantItem isCurrent={false}></TenantItem>
                <div className="separator separator-dashed d-none"></div>

            </div>
        </div>
    );
};

export default OldTenantList;
