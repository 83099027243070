import React from 'react';
import {useNavigate, useParams} from "react-router-dom";

const ReceiptInvoice = () => {
    let {uid} = useParams();
    const route = useNavigate();
    const backToList = ()=>{
        route(`/dashboard/contract/rents`);
    }
    return (
        <div id="kt_app_content" className="app-content  flex-column-fluid ">
            <div className="row ">
                <div className="col d-flex justify-content-between my-2">
                    <a href="#" className="btn btn-sm btn-primary" onClick={backToList}>Retour</a>
                </div>
            </div>
            <div id="kt_app_content_container" className="app-container  container-fluid ">
                <div className="card">
                    <div className="card-body p-lg-20">
                        <div className="d-flex flex-column flex-xl-row">
                            <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                                <div className="mt-n1">
                                    <div className="d-flex flex-stack pb-10">
                                        <a href="#">
                                            <img alt="Logo" src={"/assets/images/logo-text.png"} className={"w-25"}/>
                                        </a>

                                        <a href="#" className="btn btn-sm btn-success">Payé</a>
                                        <a href="#" className="btn btn-sm btn-primary mx-1">Télécharger</a>
                                        <a href="#" className="btn btn-sm btn-primary mx-1">Envoyé</a>
                                    </div>

                                    <div className="m-0">
                                        <div className="fw-bold fs-3 text-gray-800 mb-8">Quittance N° {uid}</div>

                                        <div className="row g-5 mb-11">
                                            <div className="col-sm-6">
                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Date d'Émission:</div>

                                                <div className="fw-bold fs-6 text-gray-800">12 Apr 2021</div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Date d'Échéance:</div>

                                                <div
                                                    className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                                                    <span className="pe-2">02 May 2021</span>

                                                    <span className="fs-7 text-danger d-flex align-items-center">
                            <span className="bullet bullet-dot bg-danger me-2"></span>

                            Due in 7 days
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-5 mb-12">
                                            <div className="col-sm-6">
                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Locataire:</div>

                                                <div className="fw-bold fs-6 text-gray-800">KeenThemes Inc.</div>

                                                <div className="fw-semibold fs-7 text-gray-600">
                                                    8692 Wild Rose Drive <br/>
                                                    Livonia, MI 48150
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Bailleur:</div>

                                                <div className="fw-bold fs-6 text-gray-800">CodeLab Inc.</div>

                                                <div className="fw-semibold fs-7 text-gray-600">
                                                    9858 South 53rd Ave.<br/>
                                                    Matthews, NC 28104
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-1 mb-3">
                                            <div className="col-sm-12">
                                                <p className="fw-semibold fs-7 text-gray-600">Je certifie sur l’honneur avoir bien perçu de <strong>KeenThemes Inc.</strong> la somme de 20,600.00 FCFA, correspondant au montant du loyer et des charges du mois de <strong>Janvier</strong>.</p>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="table-responsive border-bottom mb-9">
                                                <table className="table mb-3">
                                                    <thead>
                                                    <tr className="border-bottom fs-6 fw-bold text-muted">
                                                        <th className="min-w-175px pb-2">Description</th>
                                                        <th className="min-w-70px text-end pb-2">Unité</th>
                                                        <th className="min-w-80px text-end pb-2">Prix</th>
                                                        <th className="min-w-100px text-end pb-2">Total</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    <tr className="fw-bold text-gray-700 fs-5 text-end">
                                                        <td className="d-flex align-items-center pt-6">
                                                            <i className="fa fa-genderless text-danger fs-2 me-2"></i>

                                                            Loyer
                                                        </td>

                                                        <td className="pt-6">1</td>
                                                        <td className="pt-6">$40.00</td>
                                                        <td className="pt-6 text-gray-900 fw-bolder">$3200.00</td>
                                                    </tr>

                                                    <tr className="fw-bold text-gray-700 fs-5 text-end">
                                                        <td className="d-flex align-items-center">
                                                            <i className="fa fa-genderless text-success fs-2 me-2"></i>

                                                            Électricité
                                                        </td>

                                                        <td>120</td>
                                                        <td>$40.00</td>
                                                        <td className="fs-5 text-gray-900 fw-bolder">$4800.00</td>
                                                    </tr>

                                                    <tr className="fw-bold text-gray-700 fs-5 text-end">
                                                        <td className="d-flex align-items-center">
                                                            <i className="fa fa-genderless text-primary fs-2 me-2"></i>

                                                            Eau
                                                        </td>

                                                        <td>210</td>
                                                        <td>$60.00</td>
                                                        <td className="fs-5 text-gray-900 fw-bolder">$12600.00</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                <div className="mw-300px">
                                                    <div className="d-flex flex-stack mb-3">
                                                        <div
                                                            className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:
                                                        </div>

                                                        <div className="text-end fw-bold fs-6 text-gray-800">$
                                                            20,600.00
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-stack mb-3">
                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">VAT 0%
                                                        </div>

                                                        <div className="text-end fw-bold fs-6 text-gray-800">0.00</div>
                                                    </div>

                                                    <div className="d-flex flex-stack mb-3">
                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal +
                                                            VAT
                                                        </div>

                                                        <div className="text-end fw-bold fs-6 text-gray-800">$
                                                            20,600.00
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-stack">
                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Total
                                                        </div>

                                                        <div className="text-end fw-bold fs-6 text-gray-800">$
                                                            20,600.00
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="m-0">
                                <div
                                    className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                                    <div className="mb-8">
                                        <span className="badge badge-light-success fs-6 me-2">Approuvé</span>

                                        {/*<span className="badge badge-light-warning">Pending Payment</span>*/}
                                    </div>

                                    <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">DETAILS DU PAIEMENT</h6>

                                    <div className="mb-6">
                                        <div className="fw-semibold text-gray-600 fs-7">Paypal:</div>

                                        <div className="fw-bold text-gray-800 fs-6">codelabpay@codelab.co</div>
                                    </div>

                                    <div className="mb-6">
                                        <div className="fw-semibold text-gray-600 fs-7">Account:</div>

                                        <div className="fw-bold text-gray-800 fs-6">
                                            Nl24IBAN34553477847370033 <br/>
                                            AMB NLANBZTC
                                        </div>
                                    </div>

                                    <div className="mb-15">
                                        <div className="fw-semibold text-gray-600 fs-7">Payment Term:</div>

                                        <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">
                                            14 days

                                            <span className="fs-7 text-danger d-flex align-items-center">
                <span className="bullet bullet-dot bg-danger mx-2"></span>

                Due in 7 days
            </span>
                                        </div>
                                    </div>

                                    <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">BIEN CONCERNER</h6>

                                    <div className="mb-6">
                                        <div className="fw-semibold text-gray-600 fs-7">Libellé</div>

                                        <div className="fw-bold fs-6 text-gray-800">
                                            SaaS App Quickstarter

                                            {/*<a href="#" className="link-primary ps-1">View Project</a>*/}
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <div className="fw-semibold text-gray-600 fs-7">Adresse:</div>

                                        <div className="fw-bold text-gray-800 fs-6">9858 South 53rd Ave.
                                            Matthews, NC 28104</div>
                                    </div>

                                    <div className="m-0">
                                        <div className="fw-semibold text-gray-600 fs-7">Type:</div>

                                        <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">
                                            Studios Americain

            {/*                                <span className="fs-7 text-success d-flex align-items-center">*/}
            {/*    <span className="bullet bullet-dot bg-success mx-2"></span>*/}

            {/*    35$/h Rate*/}
            {/*</span>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptInvoice;
