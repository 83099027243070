import React, {useEffect, useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import IziTextAreaField from "../../../shared/components/form/IziTextAreaField";
import IziCheckboxField from "../../../shared/components/form/IziCheckboxField";
import {propertyCommodities} from "../../../shared/constant/PropertyCommodities.ts";
import IziFileField from "../../../shared/components/form/IziFileField";
import {useDispatch, useSelector} from "react-redux";
import {selectPropertyGeneralInfos, setPropertyGeneralInfos} from "../../../store/add-property/AddPropertyReducer";

const GeneralInfos = ({getAttachFille, propertyData=null, withFileInout=true}) => {
    const getGeneralState = useSelector(selectPropertyGeneralInfos);
    const [generalInfosForm, setGeneralInfosForm] = useState(
        {
            label: '',
            area: 0,
            pieces_nbr: 0,
            rent_default: 0,
            building:'',
            has_furnished:'',
            property_image:[],
            commodities:[],
            status:'100',
            description:'',
        }
    );
    useEffect(() => {
        if (getGeneralState){
            setGeneralInfosForm(getGeneralState)
        }else {
            if (propertyData){
                setGeneralInfosForm({
                    label: propertyData.label,
                    area: propertyData.area,
                    pieces_nbr: propertyData.pieces_nbr,
                    rent_default: propertyData.rent_default,
                    building:propertyData.building,
                    has_furnished:'',
                    property_image:propertyData.property_image,
                    commodities:propertyData.commodity,
                    status:propertyData.status,
                    description:propertyData.description,
                })
            }
        }
    }, []);
    const storeInfos = useDispatch();
    const Validator = ()=>{
        const {label, area, pieces,description} = generalInfosForm
        return label.length > 3 && area > 1 && pieces > 0 && description.length > 3
    }
    const onChange = (event) => {
        const { name, value, type } = event.target;
        const isCheckbox = type === "checkbox";
        setGeneralInfosForm((prevState) => ({
            ...prevState,
            [name]: isCheckbox
                ? prevState.commodities.includes(value)
                    ? prevState.commodities.filter((item) => item !== value)
                    : [...prevState.commodities, value]
                : value,
        }));
        if (Validator){
            storeInfos(setPropertyGeneralInfos({
                ...generalInfosForm,
                [name]: isCheckbox
                    ? generalInfosForm.commodities.includes(value)
                        ? generalInfosForm.commodities.filter((item) => item !== value)
                        : [...generalInfosForm.commodities, value]
                    : value,
            }))
        }
    };

    return (
        <div>
            <form>

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <IziInputField label={'Designation'} onChange={(event) => onChange(event)} type={'text'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir la designation du bien'}
                                       value={generalInfosForm.label} name={'label'} required={true}/>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <IziInputField label={'Superficie (m2)'} onChange={onChange} type={'number'} validateRule={[]}
                                       errorMessage={'Veuillez saisir le prénom du membre'}
                                       value={generalInfosForm.area} name={'area'} required={true}/>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <IziInputField label={'Loyer par défaut'} onChange={(event) => onChange(event)} type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir le montant'}
                                       value={generalInfosForm.rent_default} name={'rent_default'} required={true}/>

                    </div>
                </div>
                {!['Magasin', 'Terrain nu', 'Parking'].includes(generalInfosForm.type) && <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <IziInputField label={'Nombre de piéces'} onChange={onChange} type={'number'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir le nombre de pièces du bien'}
                                       value={generalInfosForm.pieces_nbr} name={'pieces_nbr'} required={true}/>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <IziInputField label={'Immeuble'} onChange={onChange} type={'text'} validateRule={[]}
                                       value={generalInfosForm.building}
                                       name={'building'} required={false}/>
                    </div>
                </div>}
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <IziTextAreaField label={'Description'} validateRule={['required']}
                                          onChange={onChange} errorMessage={'Veuillez saisir la commune du membre'}
                                          value={generalInfosForm.description} name={'description'}
                                          placeholder={"informations supplémentaire sur le bien"}></IziTextAreaField>
                    </div>
                    {withFileInout &&<div className="col-sm-12 col-md-6 col-lg-4">
                        <IziFileField getfile={getAttachFille} multiple={true}></IziFileField>
                    </div>}
                </div>

                {!['Immeuble', 'Local professionnel'].includes(generalInfosForm.type) && <div>
                    <div className="d-flex d-inline-flex justify-content-center  text-center w-100">
                        <h4 className="text-center fw-bold fs-2x">Commodités</h4>
                    </div>
                    <div className="row">
                        {
                            propertyCommodities.map((commod, index) => {
                                return  <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
                                    <IziCheckboxField key={index} onChange={onChange} value={commod} label={commod} checked={generalInfosForm.commodities.includes(commod)}
                                                      name={'commodities'}/>
                                </div>
                            })
                        }
                    </div>

                </div>}

            </form>
        </div>
    );
};

export default GeneralInfos;