import React from 'react';
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziInputField from "../../../shared/components/form/IziInputField";

const CampaignSearch = () => {
    return (
        <>
            <form data-kt-search-element="advanced-options-form" className="row align-items-center position-relative w-100 m-0" >
                <div className="col-12">
                    <div className="d-flex justify-content-end pt-6">
                        <button type="reset" className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
                                data-kt-search-element="preferences-dismiss">Reinitialisé
                        </button>
                        <button type="submit" className="btn btn-sm fw-bold btn-primary">Appliquer le filtre</button>
                    </div>

                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                    <IziSelectField dataList={[{value:"test", label:"tests"}, {value:"test", label:"tests"}, {value:"test", label:"tests"}]}></IziSelectField>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4">
                    <div className="nav-group nav-group-fluid">
                        <label>
                            <input type="radio" className="btn-check" name="type" value="has" checked="checked"/>
                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                    All
                </span>
                        </label>

                        <label>
                            <input type="radio" className="btn-check" name="type" value="users"/>
                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Users
                </span>
                        </label>

                        <label>
                            <input type="radio" className="btn-check" name="type" value="orders"/>
                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Orders
                </span>
                        </label>

                        <label>
                            <input type="radio" className="btn-check" name="type" value="projects"/>
                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Projects
                </span>
                        </label>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4">
                    <IziSelectField dataList={[{value:"test", label:"tests"}, {value:"test", label:"tests"}, {value:"test", label:"tests"}]}></IziSelectField>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                    <label
                        className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                Projects
            </span>

                        <input className="form-check-input" type="checkbox" value="1" checked="checked"/>
                    </label>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4">
                    <label
                        className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                Targets
            </span>
                        <input className="form-check-input" type="checkbox" value="1" checked="checked"/>
                    </label>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4">
                    <label
                        className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                Affiliate Programs
            </span>
                        <input className="form-check-input" type="checkbox" value="1"/>
                    </label>
                </div>
                <div className="ccol-sm-6 col-md-6 col-lg-4">
                    <IziInputField label={"test"}>
                    </IziInputField>
                </div>
            </form>
        </>
    );
};

export default CampaignSearch;
