import React, {useState} from 'react';
import IziStepper from "../../shared/components/stepper/IziStepper";
import StepContent from "../../shared/components/stepper/StepContent";
import PropertyChoice from "./components/PropertyChoice";
// import {Property} from "../models/Property.ts";
import IziButton from "../../shared/button/IziButton";
import GeneralInfos from "./components/GeneralInfos";
import Location from "./components/Location";
import Owner from "./components/Owner";
import {errorAlert, successAlert} from "../../shared/helper";
import PiecesInventory from "./components/PiecesInventory";
import {useDispatch, useSelector} from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import {
    selectPropertyFullAddress,
    selectPropertyGeneralInfos,
    selectPropertyOwner,
    selectPropertyPieces,
    selectPropertyType,
    setPropertyFullAddress,
    setPropertyGeneralInfos,
    setPropertyOwner,
    setPropertyPieces,
    setPropertyType
} from "../../store/add-property/AddPropertyReducer";
import axios_http from "../../http/axios-plugin";
import endpoint from "../../endpoint/endpoint.ts";
import {useNavigate} from "react-router-dom";
const NewProperty = () => {
    const router = useNavigate()
    const  stepItems = [
        {
            label: "Type de bien",
            isCurrent: true,
        },
        {
            label: "Infos générales",
            isCurrent: false,
        },
        {
            label: "Infos des pièces",
            isCurrent: false,
        },
        {
            label: "Situation Géographique",
            isCurrent: false,
        },
        {
            label: "Propriétaire",
            isCurrent: false,
        },
    ];
    const [stepItem, setStepItem] = useState(stepItems)
    const  [stepMove, setStepMove]=useState(0)
    const  [load, setLoad]=useState(false)
    const [propertyAttachment, setAttach] = useState(null)
    const getFileData = (e)=>{
        setAttach(e);
    }
    const propType = useSelector(selectPropertyType)
    const propGeneralInfos = useSelector(selectPropertyGeneralInfos)
    const propPieces = useSelector(selectPropertyPieces)
    const propFullAddress = useSelector(selectPropertyFullAddress)
    const propOwner = useSelector(selectPropertyOwner)
    const saveState = useDispatch();
    function  handleMove(next ){
        let newVal = stepMove;
        if ( next === true && newVal === 0 && propType === null){
            errorAlert("Veuillez sélectionné votre type de bien").then();
        }else if ( next === true && newVal === 1 && propGeneralInfos == null){
            errorAlert("Veuillez renseigner les infos du bien").then();
        }
        else if (next === true && newVal === 2 && propPieces == null){
            errorAlert("Veuillez renseigner une pièces au moins").then();
        }
        else if (next === true && newVal === 3 && propFullAddress == null){
            errorAlert("Veuillez renseigner les infos géographiques du bien").then();
        }
        else {
            newVal =next === true ? stepMove + 1 : stepMove - 1;
            setStepMove(newVal)
        }
        switch(newVal){
            case 0:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem);
                break;
            case 1:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem)
                break;
            case 2:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem)
                break;
            case 3:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem)
                break;
            case 4:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem)
                break;
            default:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[0].isCurrent = true;
                setStepItem(stepItem)
                break;
        }
    }
    const storeNewProperty = ()=>{
        setLoad(true)
        if (propOwner !== null){
            const propertyFormeData = new FormData();
            const propertyImages = [];
            if (propertyAttachment){
                for (let y = 0; y < propertyAttachment.length;y++){
                    propertyImages.push(uuidv4().split('-')[0])
                }
                for (let p = 0; p < propertyAttachment.length;p++){
                    propertyFormeData.append(propertyImages[p], propertyAttachment[p])
                }
            }
            const propertyFullDatas:any = {property_type_id:propType, ...propGeneralInfos, pieces:propPieces, ...propFullAddress,
            isOtherOwner:propOwner.isOtherOwner, propertyOwner: {...propOwner}, property_image:propertyImages}
            for (const [key, value] of Object.entries(propertyFullDatas)) {
                propertyFormeData.append(key, JSON.stringify(value))
            }
            axios_http.post(endpoint.storeProperty,propertyFormeData).then((resp_prop)=>{
                successAlert("Opération réussie", resp_prop.data.message, "Compris").then(confirm=>{
                    saveState(setPropertyType(null));
                    saveState(setPropertyGeneralInfos(null));
                    saveState(setPropertyPieces(null));
                    saveState(setPropertyFullAddress(null));
                    saveState(setPropertyOwner(null))
                    if (confirm.isConfirmed){
                        router("/dashboard/properties")
                    }
                })
            }).catch((err)=>{
                errorAlert("Ooops!","Une erreur est survenue lors du traitement de votre requête veuillez réessayé").then()
                console.error(err)
            }).finally(()=>{
                setLoad(false)
            })
        }else {
            errorAlert("Veuillez renseigné les infos du propriétaire du bien").then();
        }
    }
    return (
        <>
            <h4>Ajouter un nouveau bien à mon espace</h4>
            <IziStepper stepItemList={stepItem} className={"w-100"}>
                {stepMove === 0 && <StepContent headTitle={"Type de bien"} headDesc={"Quel type de bien souhaitez-vous ajouter"}
                                                isActive={true}>
                    <PropertyChoice/>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Suivant
                        </IziButton>
                    </div>
                </StepContent>
                }
                {stepMove === 1 && <StepContent headTitle={"Caractéristiques du bien"} headDesc={"informations générales sur le bien"}
                                                isActive={true}>
                    <GeneralInfos getAttachFille={getFileData}></GeneralInfos>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Précedent
                        </IziButton>
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Suivant
                        </IziButton>
                    </div>
                </StepContent>}
                {stepMove === 2 && <StepContent headTitle={"Pièces du bien"} headDesc={"Veuillez renseigné les pièces du bien"}
                                                isActive={true}>
                    <PiecesInventory></PiecesInventory>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Précedent
                        </IziButton>
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Suivant
                        </IziButton>
                    </div>
                </StepContent>}
                {stepMove === 3 && <StepContent headTitle={"Adresse du bien"} headDesc={"information sur l'emplacement du bien"} isActive={true}>
                    <Location></Location>
                    <div className="d-flex justify-content-end py-3 gap-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Précedent
                        </IziButton>
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Suivant
                        </IziButton>
                    </div>
                </StepContent>}
                {stepMove === 4 && <StepContent headTitle={"Information sur le propriétaire du bien"} headDesc={""}
                                                isActive={true}>
                    <Owner></Owner>
                    <div className="d-flex justify-content-end py-3 gap-3 mt-2">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Précedent
                        </IziButton>
                        <IziButton onClick={storeNewProperty} buttonKtType={'type-next'} size={'btn-lg'} isProgress={load}>
                            Ajouter
                        </IziButton>
                    </div>
                </StepContent>}
            </IziStepper>
        </>
    );
};

export default NewProperty;