import {createSlice} from "@reduxjs/toolkit";
export const filterPropertySlice = createSlice({
    name: 'property_filter',
    initialState: {
        propertyList:[],
    },
    reducers: {
        setPropertyList: (state, action) => {
            state.propertyList = action.payload
        },
    }
});
export const {setPropertyList}=filterPropertySlice.actions
export const selectProperties = (state:any) => state.property_filter.propertyList;
export default filterPropertySlice.reducer