import React, {useEffect, useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import {useDispatch, useSelector} from "react-redux";
import {setContractRent} from "../../../store/add-contract/AddContractReducer";
import {selectPropertyChoose} from "../../../store/add-property/AddPropertyReducer";

export  const rentInfosForm = {
    rent_amount:0,
    payment_date:null,
    recall_date:[],
    payment_mode: [],
    nbr_period_caution:0,
    nbr_period_advance:0,
    caution_amount:0,
    advance_amount:0,
    charge_amount:0,
    agency_fee:0,
    mutation_fee_water:0,
    mutation_fee_electricity:0,

};
const Rent = ({getRentData, oldDatas = rentInfosForm}) => {
    const propertyChoose = useSelector(selectPropertyChoose);
    const [rentInfos, setRentInfos] = useState({...rentInfosForm});
    const saveState = useDispatch();
    // useEffect(() => {
    //     getRentData(rentInfos);
    // }, [rentInfos, getRentData]);
    const paymentMode = [
        {label:"Versement en Espèce", value:"Espèce"},
        {label:"Virement Bancaire", value:"Virement Bancaire"},
        {label:"Wave", value:"Wave"},
        {label:"Orange Money", value:"Orange money"},
        {label:"MTN Money", value:"MTN money"},
        {label:"Moov Money", value:"Moov money"},
    ];
    const dayList =Array.from({length:30}, (_,i)=>{
        return {label:"le "+(i+1)+" du mois",value:(i+1)};
    });
    useEffect(() => {
        setRentInfos((prevState)=>({...prevState,
            "rent_amount":propertyChoose.rent_default
        }));
    }, []);
    const rentValidator = ()=>{
        const {rent_amount, nbr_period_advance, nbr_period_caution, payment_mode, recall_date, payment_date} = {...rentInfos}
        return Number(rent_amount) > 0 && Number(nbr_period_advance) > 0 && Number(nbr_period_caution) > 0 &&
            payment_date !== null && payment_mode.length !== 0 && recall_date.length !==0;
    }
    const saveRentDatas = ()=>{
        if (rentValidator()){
            console.warn({...rentInfos})
            saveState(setContractRent({...rentInfos}))
        }
    }
    const onChangeSelect = (event,propName='payment_date')=>{
        // console.warn(event, propName)
        if (propName === "payment_date"){
            setRentInfos((prevState)=>({...prevState,
                [propName] : event,
            }));
        }else {
            setRentInfos((prevState)=>({...prevState,
                [propName] :[ ...event],
            }));
        }

        saveRentDatas();
    }
    const onChange = (event) => {
        const { name, value } = event.target;
        setRentInfos((prevState)=>({...prevState,
            [name] : value,
        }));
        const rentData = {...rentInfos};
    // && (value !=='' || !isNaN(value)) && rentData.rent_amount !==''
        if ((name === "nbr_period_caution" || name === "rent_amount")){
            const caution_total_amount = parseInt(value) * parseInt(rentData.rent_amount);
            setRentInfos((prevState)=>({...prevState,
                'caution_amount' : parseInt(caution_total_amount),
            }));
            // setRentInfos(rentData);
        // &&  (value !=='' || !isNaN(value)) && rentData.rent_amount !==''
        }else if ((name === "nbr_period_advance"|| name === "rent_amount")){
            const advance_total_amount = parseInt(value) * parseInt(rentData.rent_amount);
            setRentInfos((prevState)=>({...prevState,
                'advance_amount' :  parseInt(advance_total_amount),
            }));
        }
        saveRentDatas();
        console.warn(rentInfos);

    };

    return (
        <>
            <form>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Montant du loyer'} onChange={onChange} type={'number'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir le montant du loyer'}
                                       value={rentInfos.rent_amount} name={'rent_amount'} required={true}/>
                    </div>
                    <div className="col">
                        <IziInputField label={'Nombre de mois de caution'} onChange={onChange} type={'number'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir le nombre de mois de  caution'} value={rentInfos.nbr_period_caution} name={'nbr_period_caution'}  required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Nombre de mois d\'avance'} onChange={onChange} type={'number'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir le nombre de mois d\'avance'} value={rentInfos.nbr_period_advance} name={'nbr_period_advance'} required={true}/>
                    </div>
                    <div className="col">
                        <IziInputField label={'Date de revision du loyer'} onChange={onChange} type={'date'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir la date de revision'} value={rentInfos.rent_revision_date} name={'rent_revision_date'}  required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Montant de la caution'} value={rentInfos.caution_amount} name={'caution_amount'} type={"number"} disabled required={false}/>
                    </div>
                    <div className="col">
                        <IziInputField label={'Montant de l\'avance'} value={rentInfos.advance_amount} name={'advance_amount'} type={"number"} disabled required={false}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziSelectField onChange={(event)=>onChangeSelect(event,'payment_date')} label={'Date de paiement'} value={rentInfos.payment_date} name={'payment_date'} dataList={dayList}></IziSelectField>

                    </div>
                    <div className="col">
                        <IziSelectField id={"relance_date"} isMulti={true} onChange={(event)=>onChangeSelect(event, 'recall_date')} label={'Date de rappelle de  paiement'} value={rentInfos.recall_date} name={'recall_date'} dataList={dayList}></IziSelectField>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziSelectField isMulti={true} id={"pay_mod"} onChange={(event)=>onChangeSelect(event, 'payment_mode')} label={'Mode paiement'} value={rentInfos.payment_mode} name={'payment_mode'} dataList={paymentMode}></IziSelectField>

                    </div>

                    <div className="col">
                        <IziInputField label={'Frais d\'agence'} onChange={onChange} type={'number'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir la montant'} value={rentInfos.mutation_fee_water} name={'mutation_fee_water'} required={true}/>
                    </div>
                </div>
                <div className="row">

                    <div className="col-6">
                        <IziInputField label={'Montant des charges'} onChange={onChange} type={'number'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir la montant'} value={rentInfos.charge_amount} name={'charge_amount'} required={true}/>
                    </div>
                    <div className="col-6">
                        <IziInputField label={'Frais de mutation eau'} onChange={onChange} type={'number'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir la montant'} value={rentInfos.mutation_fee} name={'mutation_fee'} required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <IziInputField label={'Frais de mutation électricité'} onChange={onChange} type={'number'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir la montant'} value={rentInfos.mutation_fee_electricity} name={'mutation_fee_electricity'} required={true}/>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Rent;
