import React, {useState} from 'react';
import PropertySearch from "../../components/advanced-search/PropertySearch";
import Paginator from "../../../shared/components/paginator/Paginator";
import PropertyContractsItem from "./PropertyContractsItem";

const ContractByProperty = ({setContrat}) => {
    const propList = [
        {type:'Villa basse', status:'102',label:'Villa C500', town:'Bouake',township:'Air France', address:'rue olga 58', pieces:4, area: 650, id:58, uid:'N551N7Z',
            contracts: Array(6).fill({rent:8000, start_date:"20/10/2023",end_date:"24/05/2024", status:101, id:7, uid:'Q114AA3M'})},
        {type:'Appartement', status:'101',label:'Appart W6', town:'Abidjan',township:'Treichville', address:'rue dadier 78', pieces:5, area: 1650, id:8, uid:'VAKK8PO9',
            contracts: Array(8).fill({rent:8000, start_date:"20/10/2023",end_date:"24/05/2024", status:103,  id:16, uid:'Q114AA3M'})},
        {type:'Villa duplex', status:'102',label:'Villa Ag 60', town:'Man',township:'Gbepleu', address:' 41 rue sion', pieces:7, area: 2050, id:7, uid:'TTE1475K',
            contracts: Array(3).fill({rent:8000, start_date:"20/10/2023",end_date:"24/05/2024", status:101,  id:88, uid:'Q114AA3M'})},
        {type:'Villa basse', status:'101',label:'Villa C500', town:'Bouake',township:'Air France', address:'rue olga 58', pieces:4, area: 650, id:18, uid:'G36LR85', contracts: []},
        {type:'Villa basse', status:'103',label:'Villa Amondji', town:'San pedro',township:'Air France', address:'rue olga 58', pieces:4, area: 650, id:98, uid:'E558GG3'
        , contracts: Array(9).fill({rent:8000, start_date:"20/10/2023",end_date:"24/05/2024", status:102,  id:39, uid:'Q114AA3M'})},
        {type:'Appartement', status:'101',label:'Bureau ruby', town:'Jacqueville',township:'Anouanzè', address:'rue climbié beach 58', pieces:2, area: 650, id:9, uid:'Q114AA3M', contracts: []},
    ]
    const [recordsPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = propList.slice(indexOfFirstRecord, indexOfLastRecord);
    return (
        <>
            <div className="w-100">
                <div className="row d-flex flex-column">
                    <div className="col">
                        <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                            <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                                <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                                    Mes Contrats par Bien
                                </h1>
                            </div>
                            <PropertySearch></PropertySearch>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  w-100">
                            {
                                currentRecords.map((prop, i) => (
                                         <PropertyContractsItem property={prop} key={i} setPropItem={setContrat}></PropertyContractsItem>))
                            }
                        </div>
                    </div>
                    <div className="col">
                        <div className="container">
                            <Paginator
                                currentPage={currentPage}
                                itemPerPage={recordsPerPage}
                                setCurrentPage={setCurrentPage}
                                totalRecord={propList.length}
                                start={indexOfFirstRecord}
                                end={indexOfLastRecord}
                            ></Paginator>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractByProperty;
