import React from 'react';
import Rent from "./Rent";
import IziButton from "../../../shared/button/IziButton";

const EditRentInfos = () => {
    const getRentData = (event)=>{
        console.log(event)
    }
    const oldData = {
        rent_amount:780,
        payment_date:{label:"chaque 5 du mois", value:"5"},
        recall_date:{label:"chaque 10 du mois", value:"10"},
        payment_mode: {label:"Wave", value:"wave"},
        nbr_period_caution:"2",
        nbr_period_advance:"2",
        caution_amount:1500,
        advance_amount:1500,
        charge_amount:"900",
        agency_fee:"500",
        mutation_fee_water:"400",
        mutation_fee_electricity:"700",

    };
    return (
        <>
            <Rent getRentData={getRentData} oldDatas={oldData}></Rent>
            <div className="py-3 d-flex justify-content-end">
                <IziButton>Modifier</IziButton>
            </div>

        </>
    );
};

export default EditRentInfos;
