import React from 'react';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
const IziPhoneNumberField = ({label, value,onChange}) => {
    return (
        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                <span >{label}</span>
            </label>
            <PhoneInput
                className="form-control form-control-lg form-control-solid d-flex"
                international
                countryCallingCodeEditable={false}
                defaultCountry="CI"
                value={value}
                onChange={onChange}/>
        </div>

    );
};

export default IziPhoneNumberField;
