import React, {useState} from 'react';

const IziFileField = ({getfile, label="Ajouter des pièces jointes", multiple=false, id="iziFile"}) => {
    const [inputFile, setInputFile] =useState(null);
    const onFileChange = event => {
        const allFiles = event.target.files
        setInputFile([...allFiles]);
        getfile(allFiles)

    };
    return (
        <>
        <h4 className="fs-6 fw-semibold text-gray-800 mb-3">{label}</h4>
            <input type="file" name="inputFile"  onChange={(e)=>onFileChange(e)} id={id} multiple={multiple} className="d-none" />
        <label htmlFor={id} className="card w-100 flex-center bg-light-primary border-primary border border-dashed p-2 cursor-pointer">
            <a  className="text-hover-primary fs-5 fw-bold mb-2">
                Pièces jointe
            </a>
        </label>
        </>
    );
};

export default IziFileField;
