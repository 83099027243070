import React from 'react';
import PaginateContent from "../../shared/components/paginator/PaginateContent";
import TenantItem from "../tenant/components/TenantItem";
import ReceiptSearch from "../components/advanced-search/ReceiptSearch";
import {RentItem} from "./RentItem";
import IziButton from "../../shared/button/IziButton";

const RentList = ({contract, backToList}) => {
    const contractChoosen = contract;
    const rentData= [
        {
            id:4,
            uid:"QMODHDBU55",
            month:5,
            amount:1700,
            status:{
                value:"unpaid",
                label:"non-payé"},
            payment_mode:"Wave",
            contrat_id:9,
        },
        {
            id:8,
            uid:"QMOAZDBU55",
            month:2,
            amount:1800,
            status:{
                value:"paid",
                label:"payé"},
            payment_mode:"Wave",
            contrat_id:9,
        },
        {
            id:8,
            uid:"QMOAZDBU55",
            month:2,
            amount:1800,
            status:{
                value:"paid",
                label:"payé"},
            payment_mode:"Wave",
            contrat_id:9,
        },
        {
            id:8,
            uid:"QMOAZDBU55",
            month:2,
            amount:1800,
            status:{
                value:"unpaid",
                label:"non-payé"},
            payment_mode:"Wave",
            contrat_id:9,
        },
        {
            id:10,
            uid:"QMOAZD7U55",
            month:12,
            amount:800,
            status:{
                value:"unpaid",
                label:"non-payé"},
            payment_mode:"Wave",
            contrat_id:19,
        },
        {
            id:8,
            uid:"QMOAZDBU55",
            month:2,
            amount:1800,
            status:{
                value:"paid",
                label:"payé"},
            payment_mode:"Wave",
            contrat_id:9,
        },
        {
            id:18,
            uid:"QMOAZDBU55",
            month:9,
            amount:1800,
            status:{
                value:"unpaid",
                label:"non-payé"},
            payment_mode:"OM",
            contrat_id:39,
        },


    ];
    return (
        <>
            <PaginateContent itemData={rentData} render={(currentData)=> <>
                <div className="col">
                    <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                        <IziButton onClick={backToList}>Retour</IziButton>
                        <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                            <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                                Mes Quittances
                            </h1>
                        </div>
                        <ReceiptSearch></ReceiptSearch>
                    </div>
                </div>
                <div className="col">
                    <div className="row g-3 mb-6">
                        {currentData.map((receipt, index)=>{
                            return <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4" key={index}>
                                <RentItem receipt={receipt} ></RentItem>
                            </div>})
                        }
                    </div>

                </div>
            </>}/>
        </>
    );
};

export default RentList;
