import React from 'react';
import Card from "../../shared/components/card/Card";
import IziTable from "../../shared/components/table/IziTable";

const Financial = () => {
    const spentHeaderTable = [
        {label:"Bien", column:"propDesignation"},
        {label:"Type", column:"workType"},
        {label:"Domaine", column:"workDomaine"},
        {label:"Cout", column:"workAmount"},
        {label:"Statut", column:"workStatus"},
    ];
    const receiptHeaderTable = [
        {label:"Bien", column:"propDesignation"},
        {label:"Locataire", column:"tenantFullName"},
        {label:"Mois", column:"month"},
        {label:"Montant", column:"amount"},
        {label:"Statut", column:"status"},
    ];
    const spentDatas = [
        {
            propDesignation:"Amondji archange 2 pièces",
            workType:"Travaux",
            workDomaine:"Electricité-Plomberie",
            workAmount:115000,
            workStatus:"terminé",
        },
        {
            propDesignation:"Amondji archange 2 pièces",
            workType:"Administrative",
            workDomaine:"Impot",
            workAmount:5000,
            workStatus:"en-cour",
        },

    ];
    const receiptDatas = [
        {
            propDesignation:"Villa archange 3 pièces",
            tenantFullName: "Nicolas poivredavoire",
            month:"Janvier",
            amount:45000,
            status:"payé",
        }
    ];
    return (
        <>
            <h3>Rapports financiers</h3>
            <div className="row">
                <div className="col-md-4 col-xl-4 mb-xxl-10">
                    <Card
                        title={"700 000"}
                        contentNumber={"15%"}
                        subTitle={"Revenus locatifs"}
                        hasIcon={true}
                        cardIcon={"ki-outline ki-home-2 text-gray-400"}
                        isAmount={false}
                    ></Card>
                </div>
                <div className="col-md-4 col-xl-4 mb-xxl-10">
                    <Card
                        title={"100 000"}
                        contentNumber={"15%"}
                        subTitle={"Dépenses"}
                        hasIcon={true}
                        cardIcon={"ki-outline ki-home-2 text-gray-400"}
                        isAmount={false}
                    ></Card>
                </div>
                <div className="col-md-4 col-xl-4 mb-xxl-10">
                    <Card
                        title={"600 000"}
                        contentNumber={"15%"}
                        subTitle={"Bénéfices Net"}
                        hasIcon={true}
                        cardIcon={"ki-outline ki-home-2 text-gray-400"}
                        isAmount={false}
                    ></Card>
                </div>
                <div className="col-md-4 col-xl-4 mb-xxl-10">
                    <Card
                        title={"100 000"}
                        contentNumber={"15%"}
                        subTitle={"Commissions"}
                        hasIcon={true}
                        cardIcon={"ki-outline ki-home-2 text-gray-400"}
                        isAmount={false}
                    ></Card>
                </div>
            </div>
            <div className="row  w-100 align-items-center ">
                <div className="col-sm-12 col-md-12 col-xl-12  mb-xxl-10">
                    <h3>Liste des loyer encaissés</h3>
                   <IziTable  headerELem={receiptHeaderTable} tableDatable={receiptDatas}/>
                </div>
                <div className="col-sm-12 col-md-12  col-xl-12  col-lg-12 mb-xxl-10">
                    <h3>Liste des dépenses</h3>
                    <IziTable headerELem={spentHeaderTable} tableDatable={spentDatas}/>
                </div>
            </div>
        </>
    );
};

export default Financial;
