import React from 'react';
import WorkSearch from "../../components/advanced-search/WorkSearch";
import ContractSearch from "../../components/advanced-search/ContractSearch";

const ContractHeading = ({activeNewWork}) => {
    return (
        <div className={"card  bg-white shadow border-1 border-light h-75 m-3 p-7"}>
           <div className="row mb-4">
               <div className="col-sm-12 col-md-12 col-lg-8">
                   <h3 className="fw-semibold text-dark">Recherche multi-critère</h3>
               </div>
           </div>
            <div className="row w-100">
                <div className=" col-sm-12 col-md-12 col-lg-12">
                    <ContractSearch></ContractSearch>
                </div>
            </div>

        </div>
    );
};

export default ContractHeading;
