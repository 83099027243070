import {Component} from "react";

export class Notification extends Component {
    closeChatDrawer(){
        document.body.setAttribute('data-kt-drawer-app-sidebar', 'off');
        document.body.setAttribute('data-kt-drawer', 'off');
        document.getElementById('kt_drawer_chat').classList.remove("drawer");
        document.getElementById('kt_drawer_chat').classList.remove("drawer-end");
        document.getElementById('kt_drawer_chat').classList.remove("drawer-on");
        document.getElementById('drawer-bg').style.display = 'none';
    };

    render() {
        return (
            <>
                <div id="kt_drawer_chat" className="bg-body"
                     data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true"
                     data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}"
                     data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle"
                     data-kt-drawer-close="#kt_drawer_chat_close" style={{width: 500+'px !important'}}>

                    
                    <div className="card w-100 border-0 rounded-0" id="kt_drawer_chat_messenger">
                        
                        <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
                            
                            <div className="card-title">
                                
                                <div className="d-flex justify-content-center flex-column me-3">
                                    <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">Mes Notifcations</a>

                                    
                                    <div className="mb-0 lh-1">
                                        <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                        <span className="fs-7 fw-semibold text-muted">5 message non lus</span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            

                            
                            <div className="card-toolbar">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" id="kt_drawer_chat_close" onClick={this.closeChatDrawer}>
                                    <i className="ki-outline ki-cross-square fs-2"></i>                </div>
                            </div>
                        </div>

                        <div className="card-body" id="kt_drawer_chat_messenger_body">
                            
                            <div className="scroll-y me-n5 pe-5"
                                 data-kt-element="messages"
                                 data-kt-scroll="true"
                                 data-kt-scroll-activate="true"
                                 data-kt-scroll-height="auto"
                                 data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
                                 data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
                                 data-kt-scroll-offset="0px" style={{height: 720+'px'}}>



                                <div className="d-flex justify-content-start mb-10 ">
                                    <div className="d-flex flex-column align-items-start">
                                        
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="symbol  symbol-45px symbol-square ">
                                                <img alt="Pic" src="/assets/images/logo-text.png"/></div>
                                            <div className="ms-3">
                                                <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Iziloyer: Abonnement</a>
                                                <span className="text-muted fs-7 mb-1">il y a 30 mins. lus</span>
                                            </div>

                                        </div>
                                        

                                        <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
                                           Voter abonnemet expire dans 2 jours.            </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-start mb-10 ">
                                    <div className="d-flex flex-column align-items-start">
                                        
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="me-3">
                                                <span className="text-muted fs-7 mb-1">il y a 5 mins. non lus</span>
                                                <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">Iziloyer: Etat des lieu</a>
                                            </div>

                                            <div className="symbol  symbol-45px symbol-square "><img alt="Pic" src="/assets/images/logo-text.png"/>
                                            </div>
                                        </div>
                                        

                                        <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">
                                            Les l'etat des lieu du bien Vila 4 à été valider par le locataire Gervais.           </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-start mb-10 ">
                                    <div className="d-flex flex-column align-items-start">

                                        <div className="d-flex align-items-center mb-2">
                                            <div className="me-3">
                                                <span className="text-muted fs-7 mb-1">il y a 5 mins. non lus</span>
                                                <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">Iziloyer: Campagne</a>
                                            </div>

                                            <div className="symbol  symbol-45px symbol-square "><img alt="Pic" src="/assets/images/logo-text.png"/>
                                            </div>
                                        </div>


                                        <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">
                                            Votre campagne demmare jeudi 5 avril.           </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}