import React, {useState} from 'react';

const UpgradePlan = () => {
    const [planPeriod, switchPlan] = useState(false)
    return (
        <>
            <div className="pt-0 pb-15 px-5 px-xl-20">
                <div className="mb-13 text-center">
                    <h1 className="mb-3">Activez, Changer mon Forfait</h1>

                    <div className="text-muted fw-semibold fs-5">
                        Changez ou réconduisez votre abonnement.
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="nav-group nav-group-outline mx-auto" data-kt-buttons="true"
                         data-kt-initialized="1">
                        <button
                            className={"btn btn-color-gray-500 btn-active btn-active-secondary fw-semibold px-6 py-3 me-2 " + (planPeriod === false ? 'active' : '')}
                            onClick={() => switchPlan(false)}
                            data-kt-plan="month">
                            Mensuelle
                        </button>
                        <button
                            className={"btn btn-color-gray-500 btn-active btn-active-secondary fw-semibold px-6 py-3 " + (planPeriod === true ? 'active' : '')}
                            onClick={() => switchPlan(true)}
                            data-kt-plan="annual">
                            Annuelle
                        </button>
                    </div>

                    <div className="row mt-10">
                        <div className="col-lg-6 mb-10 mb-lg-0">
                            <div className="nav flex-column" role="tablist">
                                <label
                                    className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6 "
                                    data-bs-toggle="tab" data-bs-target="#kt_upgrade_plan_startup"
                                    aria-selected="true" role="tab">

                                    <div className="d-flex align-items-center me-2">
                                        <div
                                            className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                            <input className="form-check-input fs-4" type="radio" name="plan"
                                                    value="startup"/>
                                        </div>

                                        <div className="flex-grow-1">
                                            <div
                                                className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                                                Particulier (Pack Argent)
                                            </div>
                                            <div className="fw-semibold opacity-75">
                                                Adapter aux particuliers
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ms-5">
                                        <span className="mb-2">CFA</span>

                                        <span className="fs-3x fw-bold" data-kt-plan-price-month="39"
                                              data-kt-plan-price-annual="399">{planPeriod ? 399 : 39}</span>

                                        <span className="fs-7 opacity-50">/
                                                    <span data-kt-element="period">Mois</span>
                                                </span>
                                    </div>
                                </label>
                                <label
                                    className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                                    data-bs-toggle="tab" data-bs-target="#kt_upgrade_plan_advanced"
                                    aria-selected="false" role="tab" tabIndex="-1">

                                    <div className="d-flex align-items-center me-2">
                                        <div
                                            className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                            <input className="form-check-input  fs-4" type="radio" name="plan"
                                                   value="advanced"/>
                                        </div>

                                        <div className="flex-grow-1">
                                            <div
                                                className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                                                PME (Pack Gold)
                                            </div>
                                            <div className="fw-semibold opacity-75">
                                                Adapter aux petites agence immobilière
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ms-5">
                                        <span className="mb-2">CFA</span>

                                        <span className="fs-3x fw-bold" data-kt-plan-price-month="339"
                                              data-kt-plan-price-annual="3399">{planPeriod ? 3399 : 339}</span>

                                        <span className="fs-7 opacity-50">/
                                                    <span data-kt-element="period">Mois</span>
                                                </span>
                                    </div>
                                </label>

                                <label
                                    className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                                    data-bs-toggle="tab" data-bs-target="#kt_upgrade_plan_enterprise"
                                    aria-selected="false" role="tab" tabIndex="-1">

                                    <div className="d-flex align-items-center me-2">
                                        <div
                                            className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                            <input className="form-check-input  fs-4" type="radio" name="plan"
                                                   value="enterprise"/>
                                        </div>

                                        <div className="flex-grow-1">
                                            <div
                                                className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                                                Enterprise (Pack Diamant)
                                                <span
                                                    className="badge badge-light-success ms-2 py-2 px-3 fs-7">Populaire</span>
                                            </div>
                                            <div className="fw-semibold opacity-75">
                                                Adapter aux grandes agence immobilière
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ms-5">
                                        <span className="mb-2">CFA</span>

                                        <span className="fs-3x fw-bold" data-kt-plan-price-month="999"
                                              data-kt-plan-price-annual="9999">{planPeriod ? 9999 : 999}</span>

                                        <span className="fs-7 opacity-50">/
                                                    <span data-kt-element="period">Mois</span>
                                                </span>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="tab-content rounded h-100 bg-light p-10">
                                <div className="tab-pane fade active show" id="kt_upgrade_plan_startup"
                                     role="tabpanel">
                                    <div className="pb-5">
                                        <h2 className="fw-bold text-gray-900">Qu’y a-t-il dans le pack Argent ?</h2>

                                        <div className="text-muted fw-semibold">
                                            Idéal pour les propriétaires de biens
                                        </div>
                                    </div>

                                    <div className="pt-1">
                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            3 Biens maximum
                                            </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span
                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            1 Utilisateur
                                            </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Analytics Module
                                            </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-muted flex-grow-1">
                                                            Finance Module
                                            </span>
                                            <i className="ki-outline ki-cross-circle fs-1"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-muted flex-grow-1">
                                                            Accounting Module
                                            </span>
                                            <i className="ki-outline ki-cross-circle fs-1"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-muted flex-grow-1">
                                                            Network Platform
                                            </span>
                                            <i className="ki-outline ki-cross-circle fs-1"></i>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                            <span className="fw-semibold fs-5 text-muted flex-grow-1">
                                                            Unlimited Cloud Space
                                            </span>
                                            <i className="ki-outline ki-cross-circle fs-1"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="kt_upgrade_plan_advanced"
                                     role="tabpanel">
                                    <div className="pb-5">
                                        <h2 className="fw-bold text-gray-900">Qu’y a-t-il dans le pack Gold ?</h2>

                                        <div className="text-muted fw-semibold">
                                            Idéal pour les petite Agence immobilière, les gérants de biens indépendant.
                                        </div>
                                    </div>

                                    <div className="pt-1">
                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                           25 Biens maximum
                                            </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                           10 Utilisateurs
                                            </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                             Module  Analytique Activé
                                            </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                            <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Creation de compte client
                                            </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Accounting Module                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-muted flex-grow-1">
                                                            Network Platform                                                        </span>
                                            <i className="ki-outline ki-cross-circle fs-1"></i>
                                        </div>

                                        <div className="d-flex align-items-center ">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-muted flex-grow-1">
                                                            Unlimited Cloud Space                                                        </span>
                                            <i className="ki-outline ki-cross-circle fs-1"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="kt_upgrade_plan_enterprise"
                                     role="tabpanel">
                                    <div className="pb-5">
                                        <h2 className="fw-bold text-gray-900">What’s in Startup
                                            Plan?</h2>

                                        <div className="text-muted fw-semibold">
                                            Optimal for 1000+ team and enterpise
                                        </div>
                                    </div>

                                    <div className="pt-1">
                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Up to 10 Active Users                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Up to 30 Project Integrations                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Analytics Module                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>

                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Finance Module                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>
                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Accounting Module                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>
                                        <div className="d-flex align-items-center mb-7">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Network Platform                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>
                                        <div className="d-flex align-items-center ">
                                                                                                            <span
                                                                                                                className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                                                            Unlimited Cloud Space                                                        </span>
                                            <i className="ki-outline ki-check-circle fs-1 text-success"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-center flex-row-fluid pt-12">
                    {/*<button type="reset" className="btn btn-light me-3" data-bs-dismiss="modal">*/}
                    {/*    Cancel*/}
                    {/*</button>*/}

                    <button type="button" className="btn btn-lg btn-success fw-semibold" id="kt_modal_upgrade_plan_btn">
                        <span className="indicator-label">Mettre à jour</span>
                        {/*<span className="indicator-progress">Patientez svp...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>*/}
                    </button>
                </div>
            </div>
        </>
    );
};

export default UpgradePlan;
