import React, {useState} from 'react';
import {Link} from "react-router-dom";

const AsideMenu = ({menuItem}) => {
    const dropDown = 'menu-item here menu-accordion hover show';
    const subMenu = 'menu-sub menu-sub-accordion show';
    const [activeSubMenu, setActiveSubMenu] = useState(false);

    function handleDrop() {
        setActiveSubMenu(!activeSubMenu);
        console.log(activeSubMenu);
    }

    if (menuItem.isLink === true) {
        return <div className="menu-item here menu-accordion hover"
                    onClick={handleDrop} key={menuItem.id}>
            <Link className="menu-link" to={menuItem.link}>
                            <span className="menu-link">
                                <span className="menu-icon">
                                <i className={menuItem.icon}></i>
                                </span>
                                <span className="menu-title">{menuItem.title}</span>
                            </span>
            </Link>
        </div>
    } else {
        return <div className={activeSubMenu === true ? dropDown : " menu-item menu-accordion"}
                    onClick={handleDrop} key={menuItem.id}>
                            <span className="menu-link">
                                <span className="menu-icon">
                                <i className={menuItem.icon}></i>
                                </span>
                                <span className="menu-title">{menuItem.title}</span><span className="menu-arrow"></span>
                            </span>
            {menuItem.submenu.map((sub) => {
                return (
                    <div className={activeSubMenu === true ? subMenu : "menu-sub menu-sub-accordion"} key={sub.id}>
                        <div className="menu-item">
                            <Link className="menu-link" to={sub.link}>
                                                        <span
                                                            className="menu-bullet"><span
                                                            className="bullet bullet-dot"></span>
                                                        </span>
                                <span className="menu-title">{sub.subTitle}</span>
                            </Link>
                        </div>
                    </div>

                );
            })}
        </div>
    }

};

export default AsideMenu;