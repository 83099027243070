import React from 'react';

const TenantItem = ({isCurrent}) => {
    return (
        <div className={"notice d-flex "+(isCurrent ? 'bg-white border-2':'bg-light-primary border')+" rounded border-primary  border-dashed  p-4 cursor-pointer my-3"}>
            <i className="ki-outline ki-document fs-2tx text-primary me-4"></i>

            <div className="d-flex flex-stack flex-grow-1 ">
                <div className="fw-semibold">
                    <h5 className="text-gray-900 fw-bold">Harry Voldemort</h5>
                    <div className="fs-6 text-gray-700 ">
                        <p>contact: +225 04 05 02 66 74</p>
                        <p>type: particulier</p>
                        <p>Ajouter le : 2023-05-14 </p>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default TenantItem;
