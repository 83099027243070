import React from 'react';
import Chart from "react-apexcharts";
const IziChart = (props) => {
    let options ={
        chart: {
            id: "basic-bar"
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        }
    };
    let series=  [
        {
            name: "series-1",
            data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
    ];
    options.xaxis.categories = props.abscisseLabel && props.abscisseLabel.length > 0 ? props.abscisseLabel :options.xaxis.categories;
    series = props.dataSeries && props.dataSeries.length > 0 ? props.dataSeries:series;
    //
    //
    const chartType = props.chartType !== undefined || props.chartType !== null ? props.chartType: "bar";
    return (
        <>
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={options}
                        series={series}
                        type={chartType}
                        width="100%"
                        height="500"
                    />
                </div>
            </div>
        </>
    );
};

export default IziChart;