
export const IziRangeField = ({ label,value,onChange,name,...inputProps}) => {
    const slider = document.querySelector("#kt_slider_basic");
    const valueMin = document.querySelector("#kt_slider_basic_min");
    const valueMax = document.querySelector("#kt_slider_basic_max");

    return (
    <>
        <div className="mb-10">
            <label htmlFor="customRange1" className="form-label">{label} ({value} CFA)</label>
            <input type="range" value={value} name={name} onChange={onChange}  min="0" max="1000000" className="form-range noUi-lg fw-bold" id="customRange1"/>
        </div>
    </>
)
}