import React from 'react';
import StepItem from "./StepItem";

const IziStepper = (props) => {
    const {children, stepItemList} = props;

    return (
        <div className="stepper stepper-links d-flex flex-column first" id="kt_modal_create_project_stepper" data-kt-stepper="true">
            {/*// <!--begin::Container-->*/}
            <div className="container">
                {/*// <!--begin::Nav-->*/}
                <div className="stepper-nav justify-content-center py-2 w-100 ">
                    {stepItemList.map((stepItem, index) => {
                      return  <StepItem itemLabel={stepItem.label} isCurrent={stepItem.isCurrent} key={index}/>
                    })}
                </div>
                {/*// <!--end::Nav-->*/}

                {/*// <!--begin::Form-->*/}
                <div className="mx-auto w-100 w-lg-75 w-xl-75  pt-15 pb-10 fv-plugins-bootstrap5 fv-plugins-framework">
                    {children}
                </div>
            </div>
        </div>
);
};

export default IziStepper;