import React, {useEffect} from 'react';
import EmailItems from "./EmailItems";
import PaginateContent from "../../shared/components/paginator/PaginateContent";
import ContractSearch from "../components/advanced-search/ContractSearch";

const ConfigEmails = () => {
    const ownerEmailsData =[
        {
            uid:"foff55",
            type:"Paiement de loyer",
            content:`<h1>test bbbbbbbbbbbbbbbb test</h1>`,
            desc: "jjjjjjj",
        },
        {
            uid:"ff7755",
            type:"Paiement de loyer",
            content:`<h1>test mmmmmmmmmmmm</h1>`,
            desc: "aaaaaaaaaaaaaaaa",
        },
        {
            uid:"ff5h5",
            type:"Relance de loyer",
            content:`<h1>test ggggggggggggggggg test</h1>`,
            desc: "zeeeeeeeeee fffff",
        },
        {
            uid:"f55",
            type:"Paiement de loyer",
            content:`<h1>test iiiiiiiiiii test</h1>`,
            desc: "jjjjjjj",
        },
        {
            uid:"ffff55",
            type:"Paiement de loyer",
            content:`<h1>test test</h1>`,
            desc: "jjjjjjj",
        },
        {
            uid:"ffff55",
            type:"Evenementielle",
            content:`<h1>test test</h1>`,
            desc: "jjjjjjj",
        },


    ]
    useEffect(() => {
        console.log(ownerEmailsData);
    }, []);
    return (
        <PaginateContent itemData={ownerEmailsData} render={(currentData)=> <>
            <div className="col">
                <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                    <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                        <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                            Mes Modèles de Mail
                        </h1>
                    </div>
                    <ContractSearch></ContractSearch>
                </div>
            </div>
            <div className="col">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3  w-100">
                    {
                        currentData.map((prop, i) => (
                            <EmailItems emailData={prop} key={i} ></EmailItems>
                        ))
                    }
                </div>
            </div>
        </>}/>
    );
};

export default ConfigEmails;
