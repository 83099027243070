import React, {useState} from 'react';
import UserInfos from "../models/UserInfos.ts";
import IziInputField from "../../shared/components/form/IziInputField";
import IziButton from "../../shared/button/IziButton";
const EditAccount = ({userAccountInfos = UserInfos}) => {
    const [userUpdate, setUserUpdate] = useState(userAccountInfos);
    const onChange = (event) => {
        const { name, value } = event.target;
        setUserUpdate({
            ...userUpdate,
            [name]:  value,
        });

    };
    function updateUserInfos(){
        console.log(userUpdate)
    }
    return (
        <div>
            <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                  noValidate="novalidate" data-select2-id="select2-data-kt_account_profile_details_form" onSubmit={updateUserInfos}>
                <div>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Email'} onChange={onChange} type={'email'} name={'email'}  value={userUpdate.email} errorMessage={'Veuillez saisir l\'email du membre'} required={true}/>
                        </div>
                        <div className="col">
                            <IziInputField label={'Mot de Passe'} onChange={onChange} name={'password'} type={'text'} value={userUpdate.password} errorMessage={'Veuillez saisir le password du membre'} required={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Service'} onChange={(event)=>onChange(event)} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le nom du service'} value={userUpdate.service} name={'service'} required={true}/>

                        </div>
                        <div className="col">
                            <IziInputField label={'Fonction'} onChange={(event)=>onChange(event)} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le poste du membre'} value={userUpdate.post} name={'post'} required={true}/>
                        </div>
                    </div>
                </div>
                <div className="card-footer bg-white d-flex justify-content-end py-6 px-9">
                    <IziButton className={"ml-2"}  buttonType={'submit'}>Modifer</IziButton>
                </div>
            </form>
        </div>
    );
};

export default EditAccount;