import React from 'react';

const IziRadioField = ({label, errorMessage, value, onChange, name, checked=false}) => {
    return (
        <label
            className={"btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-3 " + (value !== '' ? 'active' : '')}
            data-kt-button="true">
                            <span
                                className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                    <input className="form-check-input" type="radio" name={name} checked={checked}
                                           value={value} onChange={onChange}/>
                                </span>
            <span className="ms-5">
                                    <span className="fs-4 fw-bold mb-1 d-block">{label}</span>
                            </span>
        </label>

    );
};

export default IziRadioField;