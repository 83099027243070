import React from 'react';
import Bail from "./Bail";
import IziButton from "../../../shared/button/IziButton";

const EditBailInfos = () => {
    const getBailData = (event)=>{
        console.log(event)
    }
    const oldBaildata = {
        start_date:"2023-06-11",
        end_date:"2024-06-11",
        subject:"vvvvvvvbbbbb",
        rent_revision_date:"2023-10-11",
        rent_revision_amount:1500,
        bail_periodicity:{label:"mensuelle", value:"mensuelle"},
        bail_date:"2023-06-11",
        bail_model:{label:"modele 1", value:"modelOne"}
    }
    return (
        <>
            <Bail getBailData={getBailData} oldData={oldBaildata}></Bail>
            <div className="py-3 d-flex justify-content-end">
                <IziButton>Modifier</IziButton>
            </div>
        </>
    );
};

export default EditBailInfos;
