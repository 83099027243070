import React, {useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import IziCheckboxField from "../../../shared/components/form/IziCheckboxField";
import {WorkIntervention} from "../../../shared/constant/WorkIntervention.ts";
import IziTextAreaField from "../../../shared/components/form/IziTextAreaField";
import IziButton from "../../../shared/button/IziButton";

const NewWork = ({backWorkList}) => {
    const [newWorkItem, setNewWorkItem] = useState({
        property_id:120,
        service_initiator:'',
        start_date:'',
        end_date:'',
        provider:'',
        provider_manager:'',
        provider_contact:'',
        intervention_domain:[],
        work_amount:0,
        description:'',
        work_media:[],
        work_status:'en-attente',
    });
    const onChange = (event) => {
        const { name, value, type } = event.target;
        const isCheckbox = type === "checkbox";
        setNewWorkItem({
            ...newWorkItem,
            [name]: isCheckbox ?[value] : value,
        });

    };
    return (
        <div className="card card-flush bg-white shadow border-1 border-light h-lg-100">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                <div className="card-title">
                    <i className="ki-outline ki-information-3 fs-1 me-2"></i>
                    <h2>Ajouter des travaux</h2>
                </div>

                <div className="card-toolbar gap-3">
                    <button className="btn btn-sm btn-light btn-active-light-primary" data-kt-drawer-show="true"
                            onClick={() => {
                                backWorkList(false)
                            }}>
                        <i className="ki-outline ki-arrow-left fs-2"></i> Retour
                    </button>
                </div>
            </div>
            <div className="card-body pt-5">
                <form>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Entreprise prestataire'} onChange={onChange} type={'text'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir la ville l adresse ou est situé le bien'} value={newWorkItem.address} name={'address'} required={true}/>
                        </div>
                        <div className="col">
                            <IziInputField label={'Nom et prénom(s) du prestataire'} onChange={onChange} type={'text'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir le quartier ou est situé le bien'} value={newWorkItem.neightboard} name={'neightboard'} required={true}/>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Contact du prestataire'} onChange={onChange} type={'text'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir la commune ou est situé le bien'} value={newWorkItem.township} name={'township'}  required={true}/>
                        </div>
                        <div className="col">
                            <IziInputField label={'Cout des travaux'} onChange={(event)=>onChange(event)} type={'number'} validateRule={['required']}
                                           errorMessage={'Veuillez renseigné la ville ou est situé le bien'} value={newWorkItem.town} name={'town'} required={true}/>

                        </div>
                    </div>
                    <div className="row">
                        <p>Période d'intervention</p>
                        <div className="col">
                            <IziInputField label={'Début'} onChange={onChange} type={'date'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir la commune ou est situé le bien'} value={newWorkItem.township} name={'township'}  required={true}/>
                        </div>
                        <div className="col">
                            <IziInputField label={'Fin'} onChange={(event)=>onChange(event)} type={'date'} validateRule={['required']}
                                           errorMessage={'Veuillez renseigné la ville ou est situé le bien'} value={newWorkItem.town} name={'town'} required={true}/>

                        </div>
                    </div>
                    <div className="d-flex d-inline-flex justify-content-center  text-center w-100">
                        <h4 className="text-center fw-bold fs-4">Domaines d'intervention</h4>
                    </div>
                        <div className="row">
                            {WorkIntervention.map((commod, index) => {
                                return <div className="col-4" key={index}>
                                    <IziCheckboxField key={index} onChange={onChange} value={commod} label={commod}
                                                      name={'commodities'}/>
                                </div>
                            })}
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <IziTextAreaField label={'Description'} validateRule={['required']} onChange={onChange} errorMessage={'Veuillez saisir la commune du membre'} value={newWorkItem.description} name={'description'} placeholder={"informations supplémentaire sur le bien"}></IziTextAreaField>
                        </div>
                    </div>
                    <div className="fv-row mb-5">
                        <label htmlFor="work_file" className="w-100">
                        <div className="dropzone dz-clickable" id="kt_modal_create_campaign_files_upload">
                            <div className="dz-message needsclick">
                                <i className="ki-outline ki-file-up fs-2hx text-primary"></i>
                                <input type="file" multiple className="d-none" id="work_file"/>
                                <div className="ms-4">
                                    <h3 className="dfs-1 fw-bold text-gray-900 mb-1">Pièces jointes.</h3>
                                    <span className="fw-semibold fs-4 text-muted">Ajouter des photos au bien</span>
                                </div>
                            </div>
                        </div>
                        </label>
                    </div>
                    <div className="d-flex justify-content-end py-6 gap-3">
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} >
                            Ajouter
                        </IziButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewWork;