import React, {Component} from "react";
import Card from "../../shared/components/card/Card";

export class Resume extends Component {
    render() {
        return (
            <>
                <h2>Feuille de route: resumé</h2>
                <div className="row g-5 g-xl-10">
                    <div className="col-xxl-12 mb-md-5 mb-xl-10">
                        <div className="row g-5 g-xl-10">
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"7"}
                                    contentNumber={"15%"}
                                    subTitle={"Biens Enregistrés"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-home-2 text-gray-400"}
                                    isAmount={false}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"4"}
                                    contentNumber={"15%"}
                                    subTitle={"Biens en cours de location"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-home-1 text-success"}
                                    isAmount={false}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"2"}
                                    subTitle={"Biens disponible"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-home-3 text-primary"}
                                    isAmount={false}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"15000"}
                                    contentNumber={"15%"}
                                    subTitle={"Total des loyers collectés"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-euro text-gray-400"}
                                    isAmount={true}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"15000"}
                                    contentNumber={"15%"}
                                    subTitle={"Total des loyers impayé"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-euro text-gray-400"}
                                    isAmount={true}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"15000"}
                                    contentNumber={"15%"}
                                    subTitle={"Depense du mois en cours"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-euro text-gray-400"}
                                    isAmount={true}
                                ></Card>
                            </div>
                        </div>
                        <div className="row g-5 g-xl-10">
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"2"}
                                    contentNumber={"15%"}
                                    subTitle={"Taux de rotation"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-home-2 text-gray-400"}
                                    isAmount={false}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"2"}
                                    contentNumber={"15%"}
                                    subTitle={"Taux d'occupations des biens"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-home-2 text-gray-400"}
                                    isAmount={false}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"7"}
                                    contentNumber={"15%"}
                                    subTitle={"Biens Enregistrés"}
                                    hasIcon={true}
                                    cardIcon={"ki-outline ki-home-2 text-gray-400"}
                                    isAmount={false}
                                ></Card>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}