import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isAuth, setAccessToken, setUserDatasInfos} from "../../store/authentification/AuthReducer";
import {Navigate} from "react-router-dom";

const AuthRedirection = ({children}) => {
    const dispatch = useDispatch()
    const hasConnect = useSelector(isAuth);
    if (!hasConnect && localStorage.getItem("access_token")){
        dispatch(setAccessToken(localStorage.getItem("access_token")))
        dispatch(setUserDatasInfos(JSON.parse(localStorage.getItem("authInfos"))))
    }
    return  hasConnect ?<Navigate to="/dashboard"/>  : children;
};

export default AuthRedirection;
