import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setPropertyChoose} from "../../store/add-property/AddPropertyReducer";

const PropertyCard = ({property, getDetails}) => {
    const routeRedirect = useNavigate();
    const storePropChoose = useDispatch()
    const redirect = (uri) => {
        routeRedirect(uri);
    };
    function getPropertyDetails(){
        storePropChoose(setPropertyChoose(property))
        redirect('/dashboard/property/' + property.status+'/details');
    }
    function getPropertyEdit(){
        storePropChoose(setPropertyChoose(property))
        redirect('/dashboard/property/' + property.status+'/edit');
    }
    function setPropertyContrat(){
        storePropChoose(setPropertyChoose(property))
        redirect('/dashboard/property/' + property.status+'/new/contract');
    }
    return (
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <div className="card bg-white shadow my-2 border-1 border-light">
                <div className="card-body d-flex flex-center flex-column py-9 px-5">
                    <div className="symbol symbol-75px symbol-circle mb-5">
                        <span className="symbol-label fs-2x fw-semibold text-info bg-light-primary">
                            <i className="bi bi-houses-fill fs-3x"></i>
                        </span>
                        {/*(property.status==='102'?'bg-success':'bg-info')+*/}
                        {property.status==='100'&& <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-30px w-30px ms-n3 mt-n3"></div>}
                        {property.status==='105'&& <div className="bg-info position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-30px w-30px ms-n3 mt-n3"></div>}
                        {property.status==='110'&& <div className="bg-warning position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-30px w-30px ms-n3 mt-n3"></div>}
                    </div>

                    <p  className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">N°: {property.uid}</p>
                    <div className="fw-semibold text-gray-400 mb-6"> {property.property_type?.label}</div>

                    <div className="d-flex flex-center flex-sm-wrap mb-4 w-100">
                        <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                            {property.status===100&&<div className="fs-6 fw-bold text-gray-700">Libre</div>}
                            {property.status===105&&<div className="fs-6 fw-bold text-gray-700">Occupé</div>}
                            {property.status===110&&<div className="fs-6 fw-bold text-gray-700">Entretient</div>}
                            <div className="fw-semibold text-gray-400">Statut</div>
                        </div>

                        {/*<div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">*/}
                        {/*    <div className="fs-6 fw-bold text-gray-700">{property.area} m2</div>*/}
                        {/*    <div className="fw-semibold text-gray-400">Superficie</div>*/}
                        {/*</div>*/}
                         <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                            <div className="fs-6 fw-bold text-gray-700">{property.rent_default} FCFA</div>
                            <div className="fw-semibold text-gray-400">Loyer</div>
                        </div>
                    </div>
                    <div className="d-flex flex-center flex-wrap gap-3 w-100">
                        <button className="btn btn-sm btn-light-primary btn-flex btn-center" onClick={getPropertyEdit} data-kt-follow-btn="true">
                            <i className="ki-outline ki-file following fs-3"></i>
                            <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                            <span className="indicator-label">Modifier</span>
                        </button>
                        <button className="btn btn-sm btn-light-primary btn-flex btn-center" onClick={getPropertyDetails} data-kt-follow-btn="true">
                            <i className="ki-outline ki-eye following fs-3"></i>
                            <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                            <span className="indicator-label">Details</span>
                        </button>
                        <button className="btn btn-sm btn-light-primary btn-flex btn-center" onClick={setPropertyContrat} data-kt-follow-btn="true">
                            <i className="ki-outline ki-add-folder following fs-3"></i>
                            <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                            <span className="indicator-label">Nouveau contrat</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PropertyCard;