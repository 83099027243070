import React from 'react';

const Loading = ({label}) => {
    return (
        <span className="indicator-progress">
                    {label} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
    );
};

export default Loading;