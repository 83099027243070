import React, {useEffect, useState} from 'react';
import IziRadioField from "../../../shared/components/form/IziRadioField";
import IziInputField from "../../../shared/components/form/IziInputField";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import {useDispatch, useSelector} from "react-redux";
import {setPropertyOwner} from "../../../store/add-property/AddPropertyReducer";
import {selectUserDatasInfos} from "../../../store/authentification/AuthReducer";

const Owner = () => {
    const  civilityList = [
        {value:'Mr', label:'Monsieur'}, {value:'Mme', label:'Madame'},
        {value:'Mlle', label: 'Mademoiselle'}
    ]
    const [isOtherOwner, setIsOtherOwner] = useState('');
    const [loading, setLoad] = useState(false);
    const currentUserInfos = useSelector(selectUserDatasInfos)
    const [ownerForm, setOwnerForm] = useState({
        last_name:'',
        first_name:'',
        contact:'',
        job:'',
        town:null,
        country:null,
        township:'',
        email:'',
        address:'',
        civility:'',
    });
    const [countriesList, setCountries] = useState([])
    const [citiesList, setCities] = useState([])
    const onchangeCountry = (data)=>{
        setOwnerForm({
            ...ownerForm, country: data
        })
        const citiesList = [];
        data.cities.forEach((city)=>{
            citiesList.push({
                label:city,
                value:city,
            });
        });
        setCities(citiesList)
    }
    useEffect(() => {
        setTimeout(async () => {
            const allCountries = JSON.parse(localStorage.getItem('countries'));
            if (allCountries){
                const countriesList = [];
                allCountries.forEach((countryData)=>{
                    countriesList.push({
                        label:countryData.country,
                        value:countryData.country,
                        cities:countryData.cities,
                    });
                });
                setCountries(countriesList)
            }
        }, 1000)
    }, []);
    const storeOwnerInfos = useDispatch();

    const onchangeTown =  (data) => {
        try {
            const oldInfos = {...ownerForm}
            oldInfos.town = data
             setOwnerForm(oldInfos);
        }catch (e) {
            console.error(e)
        }
        if(Validator()){
            const propertyFullDatas:any = isOtherOwner === "manager" ? {...ownerForm}:JSON.parse(localStorage.getItem("authInfos"))?.owner;
            propertyFullDatas.isOtherOwner = isOtherOwner === "manager";
            storeOwnerInfos(setPropertyOwner(propertyFullDatas))
        }

    }
    const Validator =  (ownerMode = isOtherOwner) => {
        if (ownerMode === 'owner') {
            const ownerDatas = JSON.parse(localStorage.getItem("authInfos")) ? JSON.parse(localStorage.getItem("authInfos")) : null;
            return !!ownerDatas;

        } else {
            console.warn(ownerMode)
            console.warn(ownerForm)
            const {last_name, first_name, contact, job, town, township, civility, country} = ownerForm;
            return last_name.length > 1
                && first_name.length >= 3
                && contact.length >= 8
                && job.length >= 3
                && town !== null
                && country !== null
                && township.length >= 3
                && civility !== null
        }
    }
    const onChange = (event) => {
        const {name, value, type} = event.target;
        setOwnerForm({
            ...ownerForm,
            [name]: value,
        });
        if(Validator()){
            const propertyFullDatas:any = isOtherOwner === "manager" ? {...ownerForm}:JSON.parse(localStorage.getItem("authInfos"))?.owner;
            propertyFullDatas.isOtherOwner = isOtherOwner === "manager";
            storeOwnerInfos(setPropertyOwner(propertyFullDatas))
        }
    }
    const onchangeCivility = (data)=> {
        setOwnerForm({
            ...ownerForm, civility: data
        })
        if(Validator()){
            const propertyFullDatas:any = isOtherOwner === "manager" ? {...ownerForm}:JSON.parse(localStorage.getItem("authInfos"))?.owner;
            propertyFullDatas.isOtherOwner = isOtherOwner === "manager";
            storeOwnerInfos(setPropertyOwner(propertyFullDatas))
        }

    }
    const onChangeOwnerMode = (event)=>{
        const {value} = event.target
        setIsOtherOwner(value)
        if (value === "manager"){
            setOwnerForm({
                last_name:'',
                first_name:'',
                contact:'',
                job:'',
                town:null,
                country:null,
                township:'',
                email:'',
                civility:'',
            })
            storeOwnerInfos(setPropertyOwner(null))
        }
        if(Validator(value)){
            const propertyFullDatas:any = value === "manager" ? {...ownerForm}:JSON.parse(localStorage.getItem("authInfos"))?.owner;
            propertyFullDatas.isOtherOwner = value === "manager";
            storeOwnerInfos(setPropertyOwner(propertyFullDatas))
        }


    }
    return (
        <form>
            <div>
                <h3>Ajouter ce bien en tant que</h3>
                <div className="row">
                    <div className="col-6">
                        <IziRadioField   value="owner" onChange={onChangeOwnerMode} checked={isOtherOwner ==="owner"} label={'Propriétaire'}></IziRadioField>
                    </div>
                    {currentUserInfos?.user_type?.label == "Business" && <div className="col-6">
                        <IziRadioField value="manager" onChange={onChangeOwnerMode} checked={isOtherOwner === "manager"}
                                       label={'Gérant'}></IziRadioField>
                    </div>}
                </div>
            </div>
            {(isOtherOwner === "manager" )&&
                <div>
                    <p className="fs-3 font-bold  my-3">Veuillez remplir le formulaire avec les infos du propriétaire du
                        bien</p>

                    <div className="row">
                        <div className="col-6">
                            <IziSelectField onChange={onchangeCivility} label={'Civilité'} value={ownerForm.civility}
                                            name={'civility'} dataList={civilityList}></IziSelectField>
                        </div>
                        <div className="col-6">
                            <IziInputField label={'Nom'} onChange={onChange} type={'text'} validateRule={['required']}
                                           errorMessage={'Veuillez saisir la designation du bien'}
                                           value={ownerForm.last_name} name={'last_name'} required={true}/>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IziInputField label={'Prénom'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir le prénom du propriétaire'}
                                           value={ownerForm.first_name}
                                           name={'first_name'} required={true}/>
                        </div>
                        <div className="col-6">
                            <IziInputField label={'Contact'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir le contact du propriétaire'}
                                           value={ownerForm.contact}
                                           name={'contact'} required={true}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IziInputField label={'Email'} onChange={onChange} type={'text'}
                                           validateRule={[]}
                                           value={ownerForm.email}
                                           name={'email'} required={true}/>
                        </div>

                        <div className="col-6">
                            <IziInputField label={'Profession'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir la profession du propriétaire'}
                                           value={ownerForm.job}
                                           name={'job'} required={true}/>
                        </div>
                    </div>
                    <div className={"row "}>
                        <div className="col-6">
                            <IziSelectField
                                value={ownerForm.country}
                                dataList={countriesList}
                                validateRule={['required']}
                                required={true}
                                onChange={onchangeCountry}
                                label={"Pays"}
                                name={"country"}
                                errorMessage={'Veuillez sélectionner le Pays'}
                            ></IziSelectField>

                        </div>

                        <div className="col-6">
                            <IziInputField label={'Commune'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir la commune du propriétaire'}
                                           value={ownerForm.township}
                                           name={'township'} required={true}/>
                        </div>

                    </div>
                    <div className={"row " + (loading ? "placeholder-glow" : "")}>
                        <div className="col-6">
                            <IziInputField label={'Address'} onChange={onChange} type={'text'}
                                           validateRule={['required']}
                                           errorMessage={'Veuillez saisir l\'adresse du propriétaire'}
                                           value={ownerForm.address}
                                           name={'address'} required={true}/>
                        </div>

                        <div className={"col-6 " + (loading ? "placeholder" : "")}>
                            <IziSelectField
                                value={ownerForm.town}
                                dataList={citiesList}
                                validateRule={['required']}
                                required={true}
                                onChange={onchangeTown}
                                label={"Ville"}
                                name={"town"}
                                errorMessage={'Veuillez sélectionner la ville'}
                            ></IziSelectField>
                        </div>
                    </div>
                </div>}

        </form>
    );
};

export default Owner;