import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import PropertyWorks from "./works/PropertyWorks";
import PropertyContracts from "./contracts/PropertyContracts";
import PropertyInventory from "./inventory/PropertyInventory";
import PropertyTenant from "./tenant/PropertyTenant";
import PropertyInfos from "./PropertyInfos";
import Statistic from "./financial/Statistic";
import {useSelector} from "react-redux";
import {selectPropertyChoose} from "../../store/add-property/AddPropertyReducer";

const PropertyDetails = () => {
    let {id} = useParams();
    const getPropChoose = useSelector(selectPropertyChoose)
    const routeRedirect = useNavigate();
    const [tabSwitch, setTabSwitch] = useState(0)
    const redirect = (uri) => {
        routeRedirect(uri);
    };

    function getPropList() {
        redirect('/dashboard/properties');
    }

    return (
        <div className="row d-flex flex-column">
            <div className="col">
                <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                    <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                        <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                            Information détaillé du bien
                        </h1>
                    </div>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <button className="btn btn-flex btn-primary h-40px fs-7 fw-bold" onClick={getPropList}>
                            Retour
                        </button>
                    </div>
                </div>

            </div>
            <div className="col">
                <div className="card  bg-white shadow border-1 border-light mb-5 mb-xxl-8 my-3">
                    <div className="card-body pt-9 pb-0">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <div className="me-7 mb-4">
                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                    <i className="bi bi-houses-fill fs-5qx"></i>
                                    <div
                                        className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                </div>
                            </div>

                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2">
                                            <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"> {getPropChoose.label}</a>
                                            <a href="#"><i className="ki-outline ki-verify fs-1 text-primary"></i></a>
                                        </div>

                                        <div className="d-flex flex-wrap fw-semibold fs-5 mb-4 pe-2">
                                            <a href="#"
                                               className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <i className="ki-outline ki-calendar fs-4 me-1"></i> Ajouté le {new Date(getPropChoose.created_at).toDateString()}
                                            </a>
                                            {/*<a href="#"*/}
                                            {/*   className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">*/}
                                            {/*    <i className="ki-outline ki-geolocation-home fs-4 me-1"></i> Bouake Air*/}
                                            {/*    France rue olga 58*/}
                                            {/*</a>*/}
                                            {/*<a href="#"*/}
                                            {/*   className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">*/}
                                            {/*    <i className="ki-outline ki-home-3 fs-4 me-1"></i> 5 pièces*/}
                                            {/*</a>*/}
                                            {/*<a href="#"*/}
                                            {/*   className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">*/}
                                            {/*    <i className="ki-outline ki-home-2 fs-4 me-1"></i> 600 m2*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                    <div className="d-flex flex-column flex-grow-1 pe-8">
                                        <div className="d-flex flex-wrap">
                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true"
                                                         data-kt-initialized="1">
                                                        {getPropChoose.status===100&& "Libre"}
                                                        {getPropChoose.status===105&& "Occupé"}
                                                        {getPropChoose.status===110&& "Entretient"}
                                                    </div>
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Statut</div>
                                            </div>

                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    {/*<i className="ki-outline ki-arrow-down fs-3 text-danger me-2"></i>*/}
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true"
                                                         data-kt-countup-value="80" data-kt-initialized="1">{getPropChoose.rent_default} FCFA
                                                    </div>
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Loyer</div>
                                            </div>

                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <i className="ki-outline ki-arrow-up fs-3 text-success me-2"></i>
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true"
                                                         data-kt-countup-value="60" data-kt-countup-prefix="%"
                                                         data-kt-initialized="1">5
                                                    </div>
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Contrat(s)</div>
                                            </div>
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted">
                                                        {getPropChoose.property_type.label.toLowerCase() === "Appartement meublé".toLocaleLowerCase() ? "OUI":"NON"}
                                                    </div>
                                                </div>

                                                <div className="fw-semibold fs-6 text-gray-400">Meublé</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">*/}
                                    {/*    <h4 className="fw-bold m-0">Description</h4>*/}
                                    {/*    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">*/}
                                    {/*        <i className="ki-outline ki-design-1 fs-2tx text-primary me-4"></i>*/}

                                    {/*        <div className="d-flex flex-stack flex-grow-1 ">*/}
                                    {/*            <div className=" fw-semibold">*/}

                                    {/*                <div className="fs-6 text-gray-700 ">*/}
                                    {/*                   Villa basse avec 3 chambres, 2 douches, une cuisine pro et un jardin de 200 m2*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}

                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                            <li className="nav-item mt-2" onClick={()=>setTabSwitch(0)}>
                                <a className={"nav-link text-active-primary ms-0 me-10 py-5 "+(tabSwitch === 0 ? "active" : "")}
                                   href="#">
                                    Informations </a>
                            </li>

                            <li className="nav-item mt-2" onClick={()=>setTabSwitch(1)}>
                                <a className={"nav-link text-active-primary ms-0 me-10 py-5 "+(tabSwitch === 1 ? "active" : "")}
                                   href="#">
                                    Intervention </a>
                            </li>


                            <li className="nav-item mt-2" onClick={()=>setTabSwitch(2)}>
                                <a className={"nav-link text-active-primary ms-0 me-10 py-5 "+(tabSwitch === 2 ? "active" : "")}
                                   href="#">
                                    Contrats </a>
                            </li>


                            <li className="nav-item mt-2" onClick={()=>setTabSwitch(3)}>
                                <a className={"nav-link text-active-primary ms-0 me-10 py-5 "+(tabSwitch === 3 ? "active" : "")}
                                   href="#">
                                    Etat des lieux </a>
                            </li>


                            <li className="nav-item mt-2" onClick={()=>setTabSwitch(4)}>
                                <a className={"nav-link text-active-primary ms-0 me-10 py-5 "+(tabSwitch === 4 ? "active" : "")}
                                   href="#">
                                    Locataire </a>
                            </li>


                            <li className="nav-item mt-2" onClick={()=>setTabSwitch(5)}>
                                <a className={"nav-link text-active-primary ms-0 me-10 py-5 "+(tabSwitch === 5 ? "active" : "")}
                                   href="#">
                                    revenue financière
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="col">
                {tabSwitch === 0 &&<PropertyInfos/>}
                {tabSwitch === 1 &&<PropertyWorks/>}
                {tabSwitch === 2 &&<PropertyContracts/>}
                {tabSwitch === 3 &&<PropertyInventory/>}
                {tabSwitch === 4 &&<PropertyTenant/>}
                {tabSwitch === 5 &&<Statistic/>}
            </div>
        </div>
    );
};

export default PropertyDetails;