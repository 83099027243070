import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isAuth, setAccessToken, setUserDatasInfos} from "../../store/authentification/AuthReducer";
import {Navigate} from "react-router-dom";
const DashboardGuard = ({children}) => {
    const dispatch = useDispatch()
    const hasConnect = useSelector(isAuth);
    if (!hasConnect && localStorage.getItem("access_token")){
        dispatch(setAccessToken(localStorage.getItem("access_token")))
        dispatch(setUserDatasInfos(JSON.parse(localStorage.getItem("authInfos"))))
    }
    console.warn(hasConnect)
    return hasConnect ? children : <Navigate to="/login"/>
};

export default DashboardGuard;
