import React from 'react';
import {Route, Routes} from "react-router-dom";
import {
    DashboardLayout,
    NewProperty,
    NewMember,
    MemberList,
    PropertyList,
    PropertyDetails,
    PropertyEdit, NewContract,
    TenantList, EditTenant,
    ContractList, ContractEdit,
    ReceiptEdit, ReceiptPayment, Financial, Campaign, NewCampaign, UserProfile, UserConfiguration, OwnerSubscribe,
} from "../main/index";
import {Resume} from "../main/dashboard/Resume";
import {Analytic} from "../main/dashboard/Analytic";
import ContractDetails from "../main/contracts/ContractDetails";
import ReceiptInvoice from "../main/quittance/invoice/ReceiptInvoice";
import {ReceiptByContract} from "../main";


const DashboardRouter = () => {
    return (
        <Routes>
                <Route element={<DashboardLayout/>}>
                    <Route index element={<Resume/>}></Route>
                    <Route path='properties' element={<PropertyList/>}></Route>
                    <Route path='property/add' element={<NewProperty/>}></Route>
                    <Route path='property/:id/details' element={<PropertyDetails/>}></Route>
                    <Route path='property/:id/edit' element={<PropertyEdit/>}></Route>
                    <Route path='property/:id/new/contract' element={<NewContract/>}></Route>
                    {/*<Route path='resume' element={<Resume/>}></Route>*/}
                    <Route path='analytics' element={<Analytic/>}></Route>
                    <Route path='member/add' element={<NewMember/>}></Route>
                    <Route path='member/list' element={<MemberList/>}></Route>
                    <Route path='owner/tenants' element={<TenantList/>}></Route>
                    <Route path='owner/tenant/:id/details' element={<EditTenant/>}></Route>
                    <Route path='campaign' element={<Campaign/>}></Route>
                    <Route path='campaign/add' element={<NewCampaign/>}></Route>
                    <Route path='contracts' element={<ContractList/>}></Route>
                    <Route path='user/profile' element={<UserProfile/>}></Route>
                    <Route path='user/config' element={<UserConfiguration/>}></Route>
                    <Route path='user/subscribe' element={<OwnerSubscribe/>}></Route>
                    <Route path='contract/:id/details' element={<ContractDetails/>}></Route>
                    <Route path='contract/:id/edit' element={<ContractEdit/>}></Route>
                    <Route path='contract/rents' element={<ReceiptByContract/>}></Route>
                    <Route path='properties/financial' element={<Financial/>}></Route>
                    <Route path='contract/rent/:uid/details' element={<ReceiptInvoice/>}></Route>
                    <Route path='contract/rent/:uid/edit' element={<ReceiptEdit/>}></Route>
                    <Route path='contract/rent/:uid/payment' element={<ReceiptPayment/>}></Route>
                    {/*<Route path='*' element={<Dashboard/>}></Route>*/}
                </Route>
        </Routes>
    );
};

export default DashboardRouter;