import React from 'react';
import Loading from "./Loading";

const IziButton = ({children,colorType='btn-primary', buttonType='button',buttonKtType='type-next', size='btn-lg',
                       isProgress=false,disabled=false, onClick}) => {

    return (
        <div>
            <button type={buttonType} className={"btn "+colorType+ " "+ size}
                    data-kt-element={buttonKtType} onClick={onClick} disabled={disabled}>
                <span className="indicator-label">
                    {children}
                </span>
                {isProgress === true ? <Loading label={'veilliez patientez...'}/>:''}
            </button>
        </div>
    );
};

export default IziButton;