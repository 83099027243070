import React, {useState} from 'react';
import {Link} from "react-router-dom";
import AsideMenu from "./Aside-Menu";

const SideBar = () => {

    const Menu=  [
        {
            title: 'Mon Tableau de Bord',
            icon: 'ki-outline ki-menu fs-2',
            id: 'dash',
            activeSub:false,
            isLink:false,
            submenu:
                [
                    {
                        subTitle: 'Résumé',
                        link: '/dashboard',
                        id: 'dash-01',
                        isActive: false,
                    },
                    {
                        subTitle: 'Analytique',
                        link: '/dashboard/analytics',
                        id: 'dash-02',
                        isActive: false,
                    }
                ]
        },
        {
            title: 'Mes Biens',
            icon: 'ki-outline ki-home-3 fs-2',
            id: 'pro',
            activeSub:false,
            isLink:false,
            submenu:
                [
                    {
                        subTitle: ' Ajouter un nouveau bien',
                        id: 'pro-01',
                        link: '/dashboard/property/add',
                        isActive: false,

                    },
                    {
                        subTitle: 'Liste des biens',
                        link: '/dashboard/properties',
                        id: 'pro-02',
                        isActive: false,
                    }
                ]
        },
        {
            title: 'Mes Locataires',
            icon: 'ki-outline ki-user-square fs-2',
            id: 'tenant',
            isLink:false,

            activeSub:false,
            submenu:
                [
                    // {
                    //     subTitle: 'Nouvelle Propriété',
                    //     link: '/dashboard'
                    // },
                    {
                        subTitle: 'Liste des locataire',
                        link: '/dashboard/owner/tenants',
                        id: 'tenant-02',
                        isActive: false,

                    }
                ]
        },
        {
            title: 'Mes Contrats',
            icon: 'ki-outline ki-file-sheet fs-2',
            id: 'contrat',
            isLink:false,
            activeSub:false,
            submenu:
                [
                    // {
                    //     subTitle: 'Nouvelle Propriété',
                    //     link: '/dashboard'
                    // },
                    {
                        subTitle: 'Liste des contrat',
                        link: '/dashboard/contracts',
                        id: 'tenant-02',
                        isActive: false,

                    }
                ]
        },
        {
            title: 'Mes Quittances',
            icon: 'ki-outline ki-receipt-square  fs-2',
            id: 'receipt',
            isLink:false,

            activeSub:false,
            submenu:
                [
                    // {
                    //     subTitle: 'Nouvelle Propriété',
                    //     link: '/dashboard'
                    // },
                    {
                        subTitle: 'Liste des Quittance',
                        link: '/dashboard/contract/rents',
                        id: 'receipt-02',
                        isActive: false,
                    }
                ]
        },
        {
            title: 'Mon Equipe',
            icon: 'ki-outline ki-user fs-2',
            id: 'staff',
            activeSub:false,
            submenu:
                [
                    {
                        subTitle: 'Nouveau Membre',
                        link: '/dashboard/member/add',
                        id: 'staff-01',
                        isActive: false,

                    },
                    {
                        subTitle: 'Lister',
                        link: '/dashboard/member/list',
                        id: 'staff-02',
                        isActive: false,

                    }
                ]
        },
        {
            title: 'Finance',
            icon: 'ki-outline ki-dollar fs-2',
            id: 'financial',
            isLink:true,
            link:'/dashboard/properties/financial',
        },
        {
            title: 'Mes Campagnes',
            icon: 'ki-outline ki-message-notif fs-2',
            id: 'campaign',
            isLink:true,
            link:'/dashboard/campaign',
        },
        {
            title: 'Mon Abonnement',
            icon: 'ki-outline ki-receipt-square  fs-2',
            id: 'subscription',
            isLink:true,
            link:'/dashboard/user/subscribe',
        },

    ];

    return (
        <div id="kt_app_sidebar" className="app-sidebar  flex-column"
             data-kt-app-sidebar-fixed={true}
             data-kt-app-header-fixed={true}
             data-kt-app-sidebar-push-header={true}
             data-kt-drawer="true" data-kt-drawer-name="app-sidebar"
             data-kt-drawer-activate="{default: true, lg: false}"
             data-kt-drawer-overlay={true}
             data-kt-drawer-width="250px" data-kt-drawer-direction={"start"}
             data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
            <div
                id="kt_app_sidebar_wrapper"
                className="app-sidebar-wrapper" data-kt-app-sidebar-hoverable={true}>
                <div
                    className="hover-scroll-y my-5 my-lg-2 mx-4"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_app_header"
                    data-kt-scroll-wrappers="#kt_app_sidebar_wrapper"
                    data-kt-scroll-offset="5px">
                    <div id="#kt_app_sidebar_menu"
                         data-kt-menu="true"
                         data-kt-menu-expand="false"
                         className="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary px-3 mb-5">
                        {Menu.map((userMenu) =>{
                            return (
                                <AsideMenu menuItem={userMenu} key={userMenu.id}></AsideMenu>
                            );
                        })}

                    </div>
                </div>
            </div>

        </div>
    );
};

export default SideBar;