import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import IziStepper from "../../shared/components/stepper/IziStepper";
import StepContent from "../../shared/components/stepper/StepContent";
import IziButton from "../../shared/button/IziButton";
import IziInputField from "../../shared/components/form/IziInputField";
import IziCheckboxField from "../../shared/components/form/IziCheckboxField";
import {userAccess} from "../../shared/constant/AccesList";
import {errorAlert, loadDbJson, stepValidation, successAlert, writeDbJson} from "../../shared/helper";

const NewMember = () => {
    const routeRedirect = useNavigate();

    const  stepItems = [
        {
            label: "Infos personnel",
            isCurrent: true,
        },
        {
            label: "Infos du compte",
            isCurrent: false,
        },
        {
            label: "Droits d'accès",
            isCurrent: false,
        },
    ];

    const accessList = userAccess
    const redirect = (uri) => {
        routeRedirect(uri);
    };
    const [memberForm, setMemberForm] =useState({
        id:new Date().getMilliseconds(),
        lastName:'',
        firstName:'',
        township:'',
        town:'',
        contact:'',
        service:'',
        post:'',
        email: '',
        password: '',
        access:[],
    })
    const onSubmit = (event) => {
        event.preventDefault();
        console.log(memberForm)
        const memberData = {
            last_name:memberForm.lastName,
            first_name:memberForm.firstName,
            township:memberForm.township,
            town:memberForm.town,
            contact:memberForm.contact,
        }
        const accountData ={
            id:new Date().getMilliseconds(),
            email:memberForm.email,
            password:memberForm.password,
            service:memberForm.service,
            post:memberForm.post,
            access:memberForm.access,
            user_infos: memberData,
            user_type_id: 3,
        };
        const db_member = loadDbJson('db_member')
        if (db_member !== null){
            db_member.push(accountData)
            writeDbJson(db_member,'db_member');
            successAlert("Nouveau collaborateur ajouté avec succès").then();
            let step = stepMove;
            step = 0;
            setStepMove(step);
            redirect('/dashboard/member/list');
        }else{
            const db_data = [];
            db_data.push(accountData)
            writeDbJson(db_data,'db_member');
            successAlert("Nouveau collaboarteur ajouté avec succès").then();
            let step = stepMove;
            step = 0;
            setStepMove(step);
            redirect('/dashboard/member/list');

        }

    };

    const onChange = (event) => {
        const { name, value, type } = event.target;
        // console.log(event.target)
        const isCheckbox = type === "checkbox";
        // console.log('name:'+name , 'value:'+value, 'type:'+type)
        setMemberForm({
            ...memberForm,
            [name]: isCheckbox ? event.target.checked === true ? [...memberForm.access,value]:[value] : value,
        });

    };
    const [stepItem, setStepItem] = useState(stepItems)
    const  [stepMove, setStepMove]=useState(0)
    function  handleMove(next ){

        let newVal = stepMove;
        if (next === true && newVal === 0 && !stepValidation(['lastName',
            'firstName',
            'township',
            'town',
            'contact',
        ], memberForm)){
            errorAlert('null', "Veuillez renseigné tous les infos de cette étape").then();
        }else  if (next === true && newVal === 1 && !stepValidation([
            'email',
            'password'], memberForm)){
            errorAlert('null', "Veuillez renseigné tous les infos de cette étape").then();
        }
        else{
            newVal =next === true ? stepMove + 1 : stepMove - 1;
            setStepMove(newVal)
            switch(newVal){
                case 0:
                    stepItem.map((item)=> item.isCurrent = false);
                    stepItem[newVal].isCurrent = true;
                    setStepItem(stepItem);
                    break;
                case 1:
                    stepItem.map((item)=> item.isCurrent = false);
                    stepItem[newVal].isCurrent = true;
                    setStepItem(stepItem)
                    break;
                case 2:
                    stepItem.map((item)=> item.isCurrent = false);
                    stepItem[newVal].isCurrent = true;
                    setStepItem(stepItem)
                    break;
                default:
                    stepItem.map((item)=> item.isCurrent = false);
                    stepItem[0].isCurrent = true;
                    setStepItem(stepItem)
                    break;
            }
        }

    }
    return (
        <>
            <h4>Ajouter un nouveau membre à mon équipe</h4>
            <IziStepper stepItemList={stepItem}>
                <form onSubmit={onSubmit}>
                {stepMove === 0 && <StepContent headTitle={"Infos Personnel"} headDesc={"veuillez renseigné les information du membre"}
                              isActive={true}>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Nom'} onChange={(event)=>onChange(event)} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le nom du membre'} value={memberForm.lastName} name={'lastName'} required={true}/>

                        </div>
                        <div className="col">
                            <IziInputField label={'Prénom'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le prénom du membre'} value={memberForm.firstName} name={'firstName'}  required={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Commune'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir la commune du membre'} value={memberForm.township} name={'township'} required={true}/>

                        </div>
                        <div className="col">
                            <IziInputField label={'Ville'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir la ville du membre'} value={memberForm.town} name={'town'} required={true}/>
                        </div>
                    </div>
                    <IziInputField label={'Contact'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir la ville du membre'} value={memberForm.contact} name={'contact'} required={true} pattern="^[0-9]{10}$"/>
                    <div className="d-flex justify-content-end py-3">
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Compte
                        </IziButton>
                    </div>
                </StepContent>}
                {stepMove === 1 && <StepContent headTitle={"Infos Compte"} headDesc={"veuillez renseigné les information du compte"}
                              isActive={true}>
                    <IziInputField label={'Email'} onChange={onChange} type={'email'} name={'email'}  value={memberForm.email} errorMessage={'Veuillez saisir l\'email du membre'} required={true}/>
                    <IziInputField label={'Mot de Passe'} onChange={onChange} name={'password'} type={'text'} value={memberForm.password} errorMessage={'Veuillez saisir le password du membre'} required={true}/>
                    <div className=" d-flex flex-stack py-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Compte
                        </IziButton>
                        <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(true)}>
                            Droits d'accès
                        </IziButton>
                    </div>
                </StepContent>}
                {stepMove === 2 &&<StepContent headTitle={"Droits d'accès"} headDesc={"veuillez definir les accès du membre"}
                              isActive={true}>
                    <IziInputField label={'Service'} onChange={(event)=>onChange(event)} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le nom du service'} value={memberForm.service} name={'service'} required={true}/>
                    <IziInputField label={'Fonction'} onChange={(event)=>onChange(event)} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le poste du membre'} value={memberForm.post} name={'post'} required={true}/>

                    <div className="row gap-1">
                        {
                            accessList.map((access, index)=>{
                                return <IziCheckboxField className={'col-4'}  key={index} onChange={onChange} value={access.rootLink} label={access.title}  name={'access'} />
                            })
                        }
                    </div>
                    <div className=" d-flex flex-stack py-3">
                        <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'} isProgress={false} onClick={()=>handleMove(false)}>
                            Droits d'accès
                        </IziButton>
                        <IziButton buttonType={'submit'} buttonKtType={'type-next'}  size={'btn-lg'} isProgress={false}>
                            Ajouter
                        </IziButton>
                    </div>
                </StepContent>}
                </form>
            </IziStepper>
        </>
    );
};

export default NewMember;