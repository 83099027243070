import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PublicRouter from "./router/PublicRouter";
import DashboardRouter from "./router/DashboardRouter";
import AdminRouter from "./router/AdminRouter";
import DashboardGuard from "./shared/guard/DashboardGuard";
import AuthRedirection from "./shared/guard/AuthRedirection";

function App() {

  return (
    <div className="App d-flex flex-column flex-root app-root fs-5 fw-semibold" id="kt_app_root">
      <BrowserRouter>
          <Routes>
              <Route path="/*" element={
                  <AuthRedirection>
                      <PublicRouter></PublicRouter>
                  </AuthRedirection>
              }></Route>
              <Route path="/dashboard/*" element={
                  <DashboardGuard>
                      <DashboardRouter></DashboardRouter>
                  </DashboardGuard>
              }></Route>
              <Route path="/admin/dashboard/*" element={<AdminRouter></AdminRouter>}></Route>
          </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
