import React from 'react';

const WorkItem = ({isCurrent}) => {
    return (
        <>
        <div className={"notice d-flex "+(isCurrent === true ? 'bg-light-primary border-primary':'bg-light-warning border-warning')+" rounded  border border-dashed  p-4 cursor-pointer my-3"}>
            <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>

            <div className="d-flex flex-stack flex-grow-1 ">
                <div className=" fw-semibold">
                    <h4 className="text-gray-900 fw-bold">Cout: 58 600 FCFA</h4>

                    <div className="fs-6 text-gray-700 ">
                        <p>Exécuté par: Agitel</p>
                        <p>Du: 2023-05-14 au 2023-12-19</p>
                    </div>
                </div>

            </div>
        </div>
        </>
    );
};

export default WorkItem;