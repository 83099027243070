import React, {useState} from 'react';
import Paginator from "./Paginator";

const PaginateContent = ({perPage = 6, itemData = [],render}) => {
    const [recordsPerPage] = useState(perPage);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = itemData.slice(indexOfFirstRecord, indexOfLastRecord);
    return (
        <div className="w-100">
            <div className="row d-flex flex-column">
                {render(currentRecords)}
                <div className="col">
                    <div className="container">
                        <Paginator
                            currentPage={currentPage} itemPerPage={recordsPerPage} setCurrentPage={setCurrentPage}
                            totalRecord={itemData.length} start={indexOfFirstRecord}
                            end={indexOfLastRecord}
                        ></Paginator>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaginateContent;
