import React, {useEffect, useState} from 'react';
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziInputField from "../../../shared/components/form/IziInputField";
import axios_http from "../../../http/axios-plugin";
import endpoint from "../../../endpoint/endpoint.ts";
import {errorAlert, toastAlert} from "../../../shared/helper";
import {IziRangeField} from "../../../shared/components/form/IziRangeField";
import {useSelector} from "react-redux";
import {selectProperties} from "../../../store/property-filter/PropertyFilterReducer";
import http_axios from "../../../http/axios-plugin";

const PropertySearch = ({dataToFilter = [], dataFiltered}) => {
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [is_last, set_is_last] = useState(false);
    const getAllProperties = useSelector(selectProperties)
    const filter = {
        propertyType:null,
        status:'200',
        rent_default:0,
        country:null,
        city:null,
        otherField:''
    }
    const [countriesList, setCountries] = useState([])
    const [citiesList, setCities] = useState([]);
    const onchangeCountry = (data)=>{
        setFilterForm({
            ...filterForm, country: data
        })
        const citiesList = [];
        data.cities.forEach((city)=>{
            citiesList.push({
                label:city,
                value:city,
            });
        });
        setCities(citiesList)
    }
    useEffect(() =>{
        setTimeout(async () => {
            const allCountries = JSON.parse(localStorage.getItem('countries'));
            if (allCountries){
                const countriesList = [];
                allCountries.forEach((countryData)=>{
                    countriesList.push({
                        label:countryData.country,
                        value:countryData.country,
                        cities:countryData.cities,
                    });
                });
                setCountries(countriesList)
            }
        }, 1000)
    }, []);
    const onchangeTown = async (data) => {
        try {
            const oldInfos = filterForm
            oldInfos.town = data
            await setFilterForm(oldInfos);
        }catch (e) {
            console.error(e)
        }
    }

    const applyFilter = ()=>{
        let propertyFiltered = dataToFilter;
         if (filterForm.status != 200){
             propertyFiltered = propertyFiltered.filter(prop=> prop.status == filterForm.status )
         }
         if (filterForm.propertyType != null){
             propertyFiltered = propertyFiltered.filter(prop => prop.property_type_id == filterForm.propertyType.value)
         }
        if (filterForm.rent_default != 0){
            propertyFiltered = propertyFiltered.filter(prop => prop.rent_default == filterForm.rent_default)
        }
        if (filterForm.country != null){
            propertyFiltered = propertyFiltered.filter(prop => prop.country == filterForm.country.label)
        }
        if (filterForm.town != null){
            propertyFiltered = propertyFiltered.filter(prop => prop.town == filterForm.city.label)
        }
        if (is_last){
            propertyFiltered = [...propertyFiltered].reverse()
        }
        if (filterForm.otherField !=""){
            propertyFiltered = propertyFiltered.filter(prop => prop.township == filterForm.otherField)
        }
         dataFiltered(propertyFiltered)
        toastAlert(propertyFiltered.length+" élément(s) trouvé").then()
         console.warn(dataToFilter)
         console.warn(filterForm)
    };
     const resetFilter = ()=>{
         dataFiltered(getAllProperties)
         setFilterForm({...filter})
         set_is_last(false);
     }
    const [filterForm, setFilterForm] = useState({...filter})
    useEffect(() => {
        if (propertyTypeList.length === 0){
            axios_http.get(endpoint.getPropertyTypes).then((resp_prop_type) => {
                const parseData = resp_prop_type.data.data.map((prop_type)=> {
                    return {label:prop_type.label, value:prop_type.id}
                })
                setPropertyTypeList(parseData)
            }).catch((err) => {
                errorAlert("Ooops!", "une erreur est survenue lors du chargement des données").then()
                console.error(err)
            });
        }
    }, []);
     const onChangeIsLast = (event)=>{
         const oldValue = ! is_last
         set_is_last(oldValue)
     }
    const onChange = (event)=>{
        const {name, value,} = event.target
        setFilterForm({
            ...filterForm, [name]:value
        })
    }
    const onChangeSelect = (data, name)=>{
        setFilterForm({
            ...filterForm, [name]:data
        })
    }
    return (
        <>
            <div className="row w-100">
                <div className="col-sm-12 col-md-12 col-lg-8">
                    <h3 className="fw-semibold text-dark">Recherche multi-critère</h3>
                </div>
            </div>
        <form data-kt-search-element="advanced-options-form" className="row align-items-center position-relative w-100 m-0" >
            <div className="col-12">
                <div className="d-flex justify-content-end pt-6">
                    <button type="button" className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
                            data-kt-search-element="preferences-dismiss" onClick={resetFilter}>Reinitialisé
                    </button>
                    <button type="button" className="btn btn-sm fw-bold btn-primary" onClick={applyFilter}>Appliquer le filtre</button>
                </div>

            </div>
            <div className="col-sm-6 col-md-6 col-lg-4">
                <IziSelectField
                                value={filterForm.propertyType} name={"propertyType"}
                                onChange={(e)=>{onChangeSelect(e,"propertyType")}}
                                label={"Type de bien"}
                                dataList={propertyTypeList}></IziSelectField>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="nav-group nav-group-fluid">
                    <label>
                        <input type="radio" className="btn-check" name="status" onChange={onChange}  value="200" checked={filterForm.status =="200"}/>
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                    Tout
                </span>
                    </label>

                    <label>
                        <input type="radio" className="btn-check" name="status" onChange={onChange} value="100" checked={filterForm.status =="100"}/>
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Libre
                </span>
                    </label>

                    <label>
                        <input type="radio" className="btn-check" name="status" onChange={onChange} value="105" checked={filterForm.status =="105"} />
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Occupé
                </span>
                    </label>

                    <label>
                        <input type="radio" className="btn-check" name="status" onChange={onChange} value="110" checked={filterForm.status =="110"} />
                        <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                    Entretient
                </span>
                    </label>
                </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-4">
                <IziRangeField value={filterForm.rent_default} name={"rent_default"} onChange={onChange} label={"Prix"}></IziRangeField>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4">
                <label
                    className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                Récent
            </span>
                    <input className="form-check-input" type="checkbox" name={"is_last"} onChange={onChangeIsLast} value={is_last}   checked={is_last === true}/>
                </label>
            </div>
            <div className="ccol-sm-6 col-md-6 col-lg-4">
                <IziSelectField
                    dataList={countriesList}
                    value={filterForm.country}
                    validateRule={[]}
                    onChange={onchangeCountry}
                    label={"Par pays"}
                    name={"country"}
                ></IziSelectField>

            </div>
            <div className="ccol-sm-6 col-md-6 col-lg-4">
                <IziSelectField
                    dataList={citiesList}
                    value={filterForm.town}
                    onChange={onchangeTown}
                    validateRule={[]}
                    label={"Par ville"}
                    name={"town"}
                ></IziSelectField>
            </div>
            <div className="ccol-sm-6 col-md-6 col-lg-4">
                <IziInputField name={"otherField"} value={filterForm.otherField} label={"(Commune)"}
                               onChange={onChange}>
                </IziInputField>
            </div>
        </form>
        </>
    );
};

export default PropertySearch;
