import React, {useEffect, useState} from 'react';
import PropertyCard from "./PropertyCard";
import Paginator from "../../shared/components/paginator/Paginator";
import PropertySearch from "../components/advanced-search/PropertySearch";
import axios_http from "../../http/axios-plugin";
import endpoint from "../../endpoint/endpoint.ts";
import {useDispatch, useSelector} from "react-redux";
import {selectUserDatasInfos} from "../../store/authentification/AuthReducer";
import Loading from "../../shared/button/Loading";
import {toastAlert} from "../../shared/helper";
import {Link} from "react-router-dom";
import PageLoading from "../../shared/components/page-loading/PageLoading";
import {setPropertyList} from "../../store/property-filter/PropertyFilterReducer";

const PropertyList = () => {
    const currentUserInfos = useSelector(selectUserDatasInfos)
    const [propList, setPropList] = useState([]);
    const [load, setLoad] = useState(false);
    const storePropertyList = useDispatch();
    useEffect(() => {
        setLoad(true)
        axios_http.get(endpoint.getOwnerProperties(currentUserInfos.owner?.id)).then((resp_prop) => {
            toastAlert(resp_prop.data.message).then()
            storePropertyList(setPropertyList(resp_prop.data.data))
            setPropList([...resp_prop.data.data])
        }).catch((err) => {
            toastAlert("Oops! une erreur s'est produite, veuillez actualiser la page svp","error").then()
        }).finally(() => {
            setLoad(false)
        })
    }, []);
    const setFilterData = (datas) =>{
        setPropList(datas)
    }
    const [recordsPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = propList.slice(indexOfFirstRecord, indexOfLastRecord);
    return (
        <div className="w-100">
            {load && <PageLoading></PageLoading>}
            <div className="row d-flex flex-column">
                <div className="col">
                    <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">

                        <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                            <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                                Mes Biens
                            </h1>
                        </div>
                        <PropertySearch dataToFilter={propList} dataFiltered={(datas)=>setFilterData(datas)} ></PropertySearch>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/dashboard/property/add" className="btn btn-flex btn-primary h-40px fs-7 fw-bold">
                                Nouveau Bien
                            </Link>
                        </div>
                    </div>
                </div>
                {currentRecords.length === 0 && <div className="col">
                    <h4 className="fs-5 fw-bold my-3 text-muted">Vous n'avez pas encore ajouté de biens dans votre espace, veuillez ajouter en cliquant sur le
                        bouton Nouveau bien</h4>
                </div>}
                {currentRecords.length > 0 &&
                    <>
                        <div className="col">
                            <div className="row  w-100">
                                {
                                    currentRecords.map((prop, i) => (
                                        <PropertyCard property={prop} key={i}></PropertyCard>))
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="container">
                                <Paginator
                                    currentPage={currentPage} itemPerPage={recordsPerPage}
                                    setCurrentPage={setCurrentPage}
                                    totalRecord={propList.length} start={indexOfFirstRecord}
                                    end={indexOfLastRecord}
                                ></Paginator>
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    );
};

export default PropertyList;