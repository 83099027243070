import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    selectAccountType,
    setUserPersonalDatas,
    setUserInfosValidation,
    selectUserInfos
} from "../../store/user-register/CustomerReducer";
import IziInputField from "../../shared/components/form/IziInputField";
import http_axios from "../../http/axios-plugin";
import IziSelectField from "../../shared/components/form/IziSelectField";
import IziPhoneNumberField from "../../shared/components/form/IziPhoneNumberField";

const UserInfosForm = () => {
    const accountType = useSelector(selectAccountType)
    const dispatch = useDispatch()
    const userInfos = useSelector(selectUserInfos)
    const validUserInfos:boolean = ()=> {
        if (accountType ==="Business"){
            return (
                registerInfos.town !== null
                && registerInfos.country !== null
                && registerInfos.last_name.length > 0
                && registerInfos.first_name.length > 0
                && registerInfos.contact.length > 0
                && registerInfos.agencyAddr.length > 0
                && registerInfos.agencyName.length > 0
                &&  registerInfos.agencyStatus.length > 0
            )
        }else {
            return (
                registerInfos.town !== null
                 && registerInfos.country !== null
                && registerInfos.last_name.length > 0
                && registerInfos.first_name.length > 0
                && registerInfos.contact.length > 0
            )
        }
    }
    const [countriesList, setCountries] = useState([])
    const [citiesList, setCities] = useState([])
    useEffect(() => {
        setTimeout(async () => {
            const allCountries = JSON.parse(localStorage.getItem('countries'));
            if (allCountries){
                const countriesList = [];
                allCountries.forEach((countryData)=>{
                    countriesList.push({
                        label:countryData.country,
                        value:countryData.country,
                        cities:countryData.cities,
                    });
                });
                setCountries(countriesList)
            }
        }, 1000)
    }, []);
    const onchangeCountry = (data)=>{
        setRegister((prevState)=>({...prevState, country: data }));
        const citiesList = [];
        data.cities.forEach((city)=>{
            citiesList.push({
                label:city,
                value:city,
            });
        });
        setCities(citiesList)
    }
    const onChangePhone = (data)=>{
        setRegister((prevState)=>({...prevState, contact: data }));
    };
    const onchangeCivility = (data)=>{
        setRegister((prevState)=>({...prevState, civility: data }));
    }
    const onchangeTown = async (data) => {
        try {
            setRegister({...registerInfos, town: data });
        }catch (e) {
            console.error(e)
        }

        if (validUserInfos()) {
            dispatch(setUserPersonalDatas(registerInfos))
            dispatch(setUserInfosValidation(true))
        }

    }

    const genderList = accountType ==="Basic" ?[
        {label:"Madame", value:"Mme"},
        {label:"Monsieur", value:"Mr"},
        {label:"Mademoiselle", value:"Mlle"},
    ]: [ {label:"Entreprise", value:"Entreprise"} ];

    const [registerInfos, setRegister] = useState({
        civility:userInfos?.civility??"",
        last_name:userInfos?.last_name??"",
        first_name:userInfos?.first_name??"",
        contact:userInfos?.contact??"",
        job:userInfos?.job??"",
        address:userInfos?.address??"",
        agencyAddr:userInfos?.agencyAddr??"",
        agencyStatus:userInfos?.agencyStatus??"",
        owner_type_id:1,
        country:userInfos?.country??null,
        town:userInfos?.town??null,
        agencyName:userInfos?.agencyName??"",
        agencyRcc:userInfos?.agencyRcc??"",
    });

    const onChange = (event) => {
        const { name, value } = event.target;
        setRegister((prevState)=>({
            ...prevState,
            [name] : value,
        }));
        if (validUserInfos()){
            dispatch(setUserPersonalDatas(registerInfos))
            dispatch(setUserInfosValidation(true))
        }
    };
    return (
        <>
            <div className="pb-10 pb-lg-15">
                <h2 className="fw-bold text-gray-900">Informations personnelles</h2>

                <div className="text-muted fw-semibold fs-6">
                    Veuillez renseigner vos infos personnelles
                </div>
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center form-label mb-3">
                    {accountType === "Business" ? "Gérant" : "Propriétaire"}
                </label>
                <div className="row">
                    <div className="col">
                        <IziSelectField
                            dataList={genderList}
                            validateRule={['required']}
                            required={true}
                            onChange={onchangeCivility}
                            label={accountType ==="Basic"?"Civilité":"Type"}
                            name={"town"}
                            errorMessage={'Veuillez sélectionner la civilité'}
                        ></IziSelectField>
                    </div>

                    <div className="col">
                        <IziInputField label={'Nom'} onChange={(event) => onChange(event)} type={'text'}
                                       validateRule={['required']} errorMessage={'Veuillez saisir votre nom'}
                                       value={registerInfos.last_name} name={'last_name'} required={true}/>

                    </div>
                    <div className="col">
                        <IziInputField label={'Prénom'} onChange={onChange} type={'text'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir vos prénoms'}
                                       value={registerInfos.first_name} name={'first_name'} required={true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziPhoneNumberField onChange={onChangePhone} label={"Contact principal"}></IziPhoneNumberField>

                    </div>
                    <div className="col">
                        <IziInputField label={'Profession/Poste'} onChange={onChange} type={'text'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir la ville de residence'}
                                       value={registerInfos.job}
                                       name={'job'} required={true}/>
                    </div>
                </div>

            </div>

            {accountType === "Business" && <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label mb-3">Agence</label>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Denomination de l\'agence'} onChange={onChange} type={'text'}
                                       validateRule={['required']}
                                       errorMessage={'Veuillez saisir le nom de l\'agence'}
                                       value={registerInfos.agencyName}
                                       name={'agencyName'} required={true}/>

                    </div>
                    <div className="col">
                        <IziInputField label={'IDU'} onChange={onChange} type={'text'}
                                       validateRule={[]}
                                       value={registerInfos.agencyRcc}
                                       name={'agencyRcc'} required={false}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <IziInputField label={'Adresse'} onChange={onChange} type={'text'} validateRule={['required']}
                                       errorMessage={'Veuillez saisir l\'adresse'} value={registerInfos.agencyAddr}
                                       name={'agencyAddr'} required={true}/>

                    </div>
                    <div className="col">
                        <IziInputField label={'Statut'} onChange={onChange} type={'text'}
                                       validateRule={[]}
                                       value={registerInfos.agencyStatus}
                                       name={'agencyStatus'} required={false}/>
                    </div>
                </div>
            </div>}

            <div className="mb-0 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center form-label mb-5">
                    Localisation
                </label>
                <div className="row">
                    <div className="col">
                        <IziSelectField
                            dataList={countriesList}
                            validateRule={['required']}
                            required={true}
                            onChange={onchangeCountry}
                            label={"Pays"}
                            name={"country"}
                            errorMessage={'Veuillez sélectionner le Pays'}
                        ></IziSelectField>

                    </div>
                    <div className="col">
                        <IziInputField label={'Adresse'} onChange={onChange} type={'text'} validateRule={['required']}
                                       errorMessage={'Veuillez saisir votre adresse'} value={registerInfos.address}
                                       name={'address'} required={true}/>

                    </div>
                    <div className="col">
                        <IziSelectField
                            dataList={citiesList}
                            validateRule={['required']}
                            required={true}
                            onChange={onchangeTown}
                            label={"Ville"}
                            name={"town"}
                            errorMessage={'Veuillez sélectionner la ville'}
                        ></IziSelectField>
                    </div>
                </div>

            </div>
        </>
    );
};

export default UserInfosForm;
