import React from 'react';
import InventoryList from "./InventoryList";
import InventoryDetails from "./InventoryDetails";
import InventoryHeading from "./InventoryHeading";

const PropertyInventory = () => {
    return (
        <div className={"d-flex flex-column"}>
            <InventoryHeading></InventoryHeading>
        <div className="row g-7 w-100">
            <div className="col-lg-6 col-xl-4">
                <InventoryList></InventoryList>
            </div>
            <div className="col-lg-6 col-xl-8">
                <InventoryDetails></InventoryDetails>
            </div>
        </div>
        </div>
    );
};

export default PropertyInventory;