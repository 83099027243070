import React from 'react';
import {useNavigate} from "react-router-dom";
import FullCard from "../../../shared/components/full-card/FullCard";

const TenantItem = ({tenant, hasDetails=true}) => {
    const routeRedirect = useNavigate();
    const redirect = (uri) => {
        routeRedirect(uri);
    };
    function getTenantDetails(){
        redirect('/dashboard/owner/tenant/' + tenant.contact+'/details');
    }
    return (
        <FullCard isItem={true}>
            <div className="symbol symbol-65px symbol-circle mb-5">
                <span className="symbol-label fs-2x fw-semibold text-info bg-light-info">{tenant.last_name[0]}{tenant.first_name[0]}</span>
                <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-15px w-15px ms-n3 mt-n3"></div>
            </div>

            <p  className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">{tenant.civility} {tenant.last_name} {tenant.first_name}</p>
            <div className="fw-semibold text-gray-400 mb-6">Contact: {tenant.contact}</div>

            <div className="d-flex flex-center flex-wrap mb-2">
                <div className="border border-dashed rounded  py-2 px-2 mx-2 mb-3">
                    <div className="fs-6 fw-bold text-gray-700">{tenant.email}</div>
                    <div className="fw-semibold text-gray-400">Email</div>
                </div>

                <div className="border border-dashed rounded py-2 px-2 mx-2 mb-3">
                    <div className="fs-6 fw-bold text-gray-700">{tenant.job}</div>
                    <div className="fw-semibold text-gray-400">Profession</div>
                </div>
            </div>
            <div className="d-flex flex-center flex-wrap gap-3 w-100">
                {/*<button className="btn btn-sm btn-light-primary btn-flex btn-center"  data-kt-follow-btn="true">*/}
                {/*    <i className="ki-outline ki-file following fs-3"></i>*/}
                {/*    <i className="ki-outline ki-plus follow fs-3 d-none"></i>*/}
                {/*    <span className="indicator-label">Modifier</span>*/}
                {/*</button>*/}
                {hasDetails===true && <button className="btn btn-sm btn-light-primary btn-flex btn-center" onClick={getTenantDetails}
                                              data-kt-follow-btn="true">
                    <i className="ki-outline ki-eye following fs-3"></i>
                    <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                    <span className="indicator-label">Details</span>
                </button>}
            </div>
        </FullCard>


    );
};

export default TenantItem;
