import React, {useState} from 'react';
import {errorAlert, Validator} from "../../helper";

const IziInputField = ({ label, errorMessage,placeholder,value,onChange,name, type,validateRule=[],required,...inputProps}) => {
    const [error, setError] = useState(false);
    // const  = props;

    const onHandleFocus = (e) => {
        if (Validator(e.target.value,validateRule).length > 0){
            errorAlert('null',errorMessage, "compris").then();
            setError(true);
        }
    };
    return (

        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                <span className={error ? "required":""}>{label}</span>
                {(error && required) && <span className="ms-1" data-bs-toggle="tooltip"
                       aria-label="Specify a target name for future usage and reference"
                       data-bs-original-title="Specify a target name for future usage and reference"
                       data-kt-initialized="1">
	            <i className="ki-outline ki-information-5 text-gray-500 fs-6"></i></span>}
            </label>
            <input type={type} {...inputProps} value={value} required={required} className="form-control form-control-lg form-control-solid"
                   onChange={onChange} onBlur={onHandleFocus}  placeholder={placeholder} name={name}/>
            {error &&<div
                className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errorMessage}</div>}
        </div>
    );
};

export default IziInputField;