import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import GeneralInfos from "./components/GeneralInfos";
import Location from "./components/Location";
import Owner from "./components/Owner";
import IziButton from "../../shared/button/IziButton";
import {useSelector} from "react-redux";
import {
    selectPropertyChoose,
    selectPropertyFullAddress,
    selectPropertyGeneralInfos
} from "../../store/add-property/AddPropertyReducer";
import {errorAlert, toastAlert} from "../../shared/helper";
import axios_http from "../../http/axios-plugin";
import endpoint from "../../endpoint/endpoint.ts";
import AdvancedEdit from "./components/AdvancedEdit";

const PropertyEdit = () => {
    let { id } = useParams();
    const propToUpdate = useSelector(selectPropertyChoose);
    const updateData = useSelector(selectPropertyGeneralInfos);
    const updateAddressData = useSelector(selectPropertyFullAddress)
    const redirect = (uri) => {
        routeRedirect(uri);
    };
    const addressValidator = ()=>{
        const {country, township, town, neighborhood, address} = updateAddressData;
        return country !== null && township.length > 3 && town !== null && neighborhood.length > 3 && address.length > 3;
    }
    const propFullAddress = {
        address:propToUpdate.address,country:{label:propToUpdate.country,value:propToUpdate.country},
        town: {label:propToUpdate.town, value:propToUpdate.town},
        township:propToUpdate.township,neighborhood:propToUpdate.neighborhood}
    const routeRedirect = useNavigate();
    const Validator = ()=>{
        const {label, area, pieces_nbr,description, rent_default} = updateData
        return label.length > 3 && area > 1 && pieces_nbr > 0 && description.length > 3 && rent_default > 0
    }
    const updateGeneralInfos = ()=>{
        console.warn(updateData)
        if (Validator()){
            axios_http.put(endpoint.updateProperty, {...updateData,id:propToUpdate.id }).then((resp_update)=>{
                toastAlert(resp_update.data.message).then()
            }).catch((err)=>{
                errorAlert("Ooops!",err.error.message).then()
            })
        }else {
            errorAlert("infos invalide", "Veuillez bien renseigner les infos du bien").then()
        }
    }
    const updatePropertyFullAddress = ()=>{
        if (addressValidator()){
            console.warn(updateAddressData)
            let fullPropAddress:any = {...updateAddressData}
            const countryUpdate = updateAddressData.country.value;
            const townUpdate = updateAddressData.town.value;
            delete  fullPropAddress.country
            delete  fullPropAddress.town
            fullPropAddress.country = countryUpdate
            fullPropAddress.town = townUpdate
            axios_http.put(endpoint.updateProperty, {...fullPropAddress,id:propToUpdate.id }).then((resp_update)=>{
                toastAlert(resp_update.data.message).then()
            }).catch((err)=>{
                errorAlert("Ooops!",err.error.message).then()
            })
        }else {
            errorAlert("infos invalide", "Veuillez bien renseigner les infos de l'adresse du bien").then()
        }
    }
    function getPropList() {
        redirect('/dashboard/properties');
    }
    return (
        <>
            <div className="card mb-5 mb-xl-10 bg-white shadow border-1 border-light">
                <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div className="card-title m-0 d-flex flex-sm-column flex-md-row flex-lg-row justify-content-between w-100">
                        <h3 className="fw-bold m-0">Modification du bien {propToUpdate.label}</h3>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className="btn btn-flex btn-primary h-40px fs-7 fw-bold" onClick={getPropList}>
                                Retour
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body border-top p-9">
                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                        role="tablist">

                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                               data-bs-toggle="tab" href="#kt_contact_view_general" aria-selected="true" role="tab">
                                <i className="ki-outline ki-home fs-4 me-1"></i> Infos générales
                            </a>
                        </li>

                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary d-flex align-items-center pb-4"
                               data-bs-toggle="tab" href="#kt_contact_view_meetings" aria-selected="false"
                               tabIndex="-1" role="tab">
                                <i className="ki-outline ki-calendar-8 fs-4 me-1"></i> Situation Géographique
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link text-active-primary d-flex align-items-center pb-4"
                               data-bs-toggle="tab" href="#kt_contact_view_owner" aria-selected="false"
                               tabIndex="-2" role="tab">
                                <i className="ki-outline ki-calendar-8 fs-4 me-1"></i> Avancé
                            </a>
                        </li>

                    </ul>
                    <div className="tab-content" id="">
                        <br/>
                        <div className="tab-pane fade show active" id="kt_contact_view_general" role="tabpanel">
                            <div id="kt_account_settings_profile_details" className="collapse show">
                                <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                    <div className="card-body border-top p-9">
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6"></label>
                                            <div className="col-lg-8">
                                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                    <i className="bi bi-houses-fill fs-5qx"></i>
                                                    <div
                                                        className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-4">Infos générales</label>
                                        </div>
                                        <GeneralInfos  propertyData={propToUpdate} withFileInout={false}></GeneralInfos>

                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                            {/*<button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button>*/}
                                            <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false}  onClick={updateGeneralInfos}>
                                                Modifier
                                            </IziButton>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <br/>
                        <div className="tab-pane fade" id="kt_contact_view_meetings" role="tabpanel">
                            <div id="kt_account_settings_profile_details" className="collapse show">
                                <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                    <div className="card-body border-top p-9">
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6"></label>
                                            <div className="col-lg-8">
                                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                    <i className="bi bi-houses-fill fs-5qx"></i>
                                                    <div
                                                        className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-4">Situation Géographique</label>
                                        </div>
                                        <Location  updateLocation={propFullAddress}></Location>

                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                            {/*<button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button>*/}
                                            <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} onClick={updatePropertyFullAddress}>
                                                Modifier
                                            </IziButton>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <br/>
                        <div className="tab-pane fade" id="kt_contact_view_owner" role="tabpanel">
                            <div id="kt_account_settings_profile_details" className="collapse show">
                                <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                    <div className="card-body border-top p-9">
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6"></label>
                                            <div className="col-lg-8">
                                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                    <i className="bi bi-houses-fill fs-5qx"></i>
                                                    <div
                                                        className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-4">Avancé</label>
                                        </div>
                                        <AdvancedEdit propertyData={propToUpdate}></AdvancedEdit>
                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                            {/*<button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button>*/}
                                            <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false} >
                                                Modifier
                                            </IziButton>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyEdit;