import React, {useState} from 'react';
import './login.scss'
import {Link, useNavigate} from "react-router-dom";
import IziInputField from "../../shared/components/form/IziInputField";
import {errorAlert} from "../../shared/helper";
import axios_http from "../../http/axios-plugin";
import endpoint from "../../endpoint/endpoint.ts";
import {setAccessToken, setOwnerAgency, setUserDatasInfos} from "../../store/authentification/AuthReducer";
import {useDispatch} from "react-redux";
import IziPhoneNumberField from "../../shared/components/form/IziPhoneNumberField";

const Login = () => {
    const [loginForm, setLoginForm] = useState({
        phone_number:"",
        password:""
    });
    const dispatch = useDispatch();
    const onChange = (event) => {
        const { name, value } = event.target;
        setLoginForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const onChangePhone = (data)=>{
        setLoginForm((prevState)=>({...prevState, phone_number: data }));
    };
    const [load, setLoad] = useState(false)
    const navigate = useNavigate();
    const loginUser = ()=>{
        setLoad(true)
        if (loginValidator()){
            setTimeout(()=>{
                setLoad(true)
            },600)
            setTimeout(()=>{
                axios_http.post(endpoint.loginUserAccount, loginForm).then((resp_user)=>{
                    setTimeout(()=>{
                        setLoad(false)
                    },600);
                    dispatch(setAccessToken(resp_user.data.token))
                    dispatch(setUserDatasInfos(resp_user.data.user))
                    dispatch(setOwnerAgency(resp_user.data.user.owner.owner_agency))
                    localStorage.setItem('access_token',resp_user.data.token )
                    localStorage.setItem('authInfos',JSON.stringify(resp_user.data.user) )
                    navigate('/dashboard')
                }).catch((err)=>{
                    errorAlert('Oops!', err.response?.data?.message).then();
                    setTimeout(()=>{
                        setLoad(false)
                    },600)
                })
            },1000)
        }else {
            errorAlert('Oops!', "Veuillez bien renseigner vos accès").then();
            setLoad(false)
        }
    }
    const loginValidator = ()=>{
        return loginForm.phone_number.length >=8  && loginForm.password.length >= 8
    }
    return (
        <main className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center w-100 container-fluid">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-lg-row-fluid">
                    <div className="d-flex flex-column flex-center pb-0  pb-lg-10 p-10 w-100">
                        <img className="theme-light-show mx-auto mw-100  w-150px w-lg-300px mb-10 mb-lg-20" src="/assets/images/sign-in.png" alt=""/>
                        {/*<img className="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="/assets/images/sign-in.png" alt=""/>*/}

                        <h1 className="text-gray-800  fs-2qx fw-bold text-center mb-7">
                            Rapide, Efficace et Productive
                        </h1>

                        <div className="text-gray-600 fs-base text-center fw-semibold">
                            IZILOYER, la solution digitale au service des agences immobilières et <br/> propriétaires en Afrique pour gérer facilement leurs biens en location
                        </div>
                    </div>
                </div>

                <div
                    className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                    <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                        <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                            <div className="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">

                                <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                                    <div className="text-center mb-11">
                                        <h1 className="text-dark fw-bolder mb-3">
                                            Se Connecter
                                        </h1>

                                        <div className="text-gray-500 fw-semibold fs-6">
                                            J'accède à mon compte
                                        </div>

                                    </div>

                                    <div className="row g-3 mb-9">
                                    </div>

                                    <div className="separator separator-content my-14">
                                        {/*<span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>*/}
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <IziPhoneNumberField onChange={onChangePhone} label={"Identifiant (numéro de téléphone)"}></IziPhoneNumberField>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <IziInputField label={'Mot de passe'} onChange={onChange} type={'password'}
                                                           validateRule={['required']}
                                                           errorMessage={'Veuillez saisir un mot de passe'}
                                                           value={loginForm.password}
                                                           name={'password'} required={true}/>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                        <div></div>

                                        <a href="/"
                                           className="link-primary">
                                            Mot de passe oublié ?
                                        </a>
                                    </div>

                                    <div className="d-grid mb-10">

                                        <button type="button" id="kt_sign_in_submit" onClick={loginUser} className="btn btn-primary">

                                            {load===false && <span className="indicator-label">Connexion</span>}

                                            {load === true &&<span className={load === true ? "d-inline-block" : "indicator-progress"}>
                                                    Patientez svp...
                                                    <span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>}
                                            </button>
                                        </div>
                                        <div className="text-gray-500 text-center fw-semibold fs-6">
                                            Nouveau ici?

                                            <Link to="/register"
                                                  className="link-primary">
                                                Je créer mon compte
                                            </Link>
                                        </div>
                                </form>

                            </div>

                            <div className=" d-flex flex-stack">
                                <div className="d-flex fw-semibold text-primary fs-base gap-5">
                                    <a href="/" target="_blank">Confidentialité</a>

                                    <a href="/" target="_blank">Plans</a>

                                    <a href="/" target="_blank">Contactez-nous</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
);
};

export default Login;