export const environment = {
  production: true,
  apiUrl: 'https://api.iziloyer.com/api/',
  storageApiUrl: 'https://api.iziloyer.com/storage/',
  // apiUrl: 'http://127.0.0.1:8000/api/',
  // storageApiUrl: 'http://127.0.0.1:8000/storage/',
  defaultLanguage: 'fr',
  countriesApiUrl:"https://countriesnow.space/api/v0.1/countries",
  // citiesApiUrl:"",
};
