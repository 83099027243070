// @ts-ignore
import {environment} from "../environment/environment.ts";

const  base =environment.apiUrl
const endpoint= {
    getUserTypes:`${base}user/types`,
    getPropertyTypes:`${base}property/types`,
    getGlobalContractTemplate:`${base}contract/model/global`,
    storeProperty:`${base}property/store`,
    storePropertyInventory:`${base}inventory/store`,
    storeTenant:`${base}tenant/store`,
    storeContract:`${base}contract/store`,
    checkContractDateInterval:`${base}contract/interval-date`,
    storeTenantEmergencyContact:`${base}tenant/emergency-contact/store`,
    updateProperty:`${base}property/update`,
    updatePropertyAttachFiles:`${base}property/attache/files/update`,
    removePropertyAttachFiles:`${base}property/attache/file/remove`,
    checkUserEmail: (email:String)=> `${base}auth/user/${email}/check`,
    getOwnerProperties: (owner_id:String)=> `${base}property/owner/${owner_id}`,
    getOwnerTenants: (owner_id:String)=> `${base}owner/${owner_id}/tenants`,
    registerUserAccount:  `${base}user/register`,
    logoutUserSession:  `${base}logout/session`,
    loginUserAccount:  `${base}user/login`,
}
export  default endpoint;