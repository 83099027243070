import React, {useState} from 'react';
import IziInputField from "../../shared/components/form/IziInputField";
import Member from "../models/Member.ts";
import IziButton from "../../shared/button/IziButton";

const Overview = ({memberInfos = Member}) => {
    const [memberUpdate, setMemberUpdate] = useState(memberInfos);
    const onChange = (event) => {
        const { name, value } = event.target;
        setMemberUpdate({
            ...memberUpdate,
            [name]:  value,
        });

    };
    function updateMemberInfos(){
        console.log(memberUpdate)
    }
    return (
        <div>
            <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
                  noValidate="novalidate" data-select2-id="select2-data-kt_account_profile_details_form" onSubmit={updateMemberInfos}>
                <div>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Nom'} onChange={(event)=>onChange(event)} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le nom du membre'} value={memberUpdate.last_name} name={'last_name'} required={true}/>

                        </div>
                        <div className="col">
                            <IziInputField label={'Prénom'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir le prénom du membre'} value={memberUpdate.first_name} name={'first_name'}  required={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Commune'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir la commune du membre'} value={memberUpdate.township} name={'township'} required={true}/>

                        </div>
                        <div className="col">
                            <IziInputField label={'Ville'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir la ville du membre'} value={memberUpdate.town} name={'town'} required={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <IziInputField label={'Contact'} onChange={onChange} type={'text'} validateRule={['required']} errorMessage={'Veuillez saisir la ville du membre'} value={memberUpdate.contact} name={'contact'} required={true} pattern="^[0-9]{10}$"/>
                        </div>
                    </div>
                </div>
                <div className="card-footer bg-white d-flex justify-content-end py-6 px-9">
                    <IziButton className={"ml-2"}  buttonType={'submit'}>Modifer</IziButton>
                </div>
            </form>
        </div>
    );
};

export default Overview;