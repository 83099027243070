import React from 'react';
import IziInputField from "../../shared/components/form/IziInputField";
import IziSelectField from "../../shared/components/form/IziSelectField";
import {useNavigate} from "react-router-dom";

const ReceiptEdit = () => {
    const paymentMode = [
        {label:"Versement en Espèce", value:"Espèce"},
        {label:"Virement Bancaire", value:"Virement Bancaire"},
        {label:"Wave", value:"Wave"},
        {label:"Orange Money", value:"Orange money"},
        {label:"MTN Money", value:"MTN money"},
        {label:"Moov Money", value:"Moov money"},
    ];
    const route = useNavigate();
    const backToList = ()=>{
        route('/dashboard/contract/rents');
    }
    return (
        <div>
            <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                <div className="card card-flush pt-3 mb-5 mb-xl-10">
                    <div className="card-header">
                        <div className="card-title">
                            <h2 className="fw-bold">Modifier la quittance N°QMAMMDKKD55</h2>
                        </div>

                        <div className="card-toolbar">
                            <a href="#" className="btn btn-light-primary" onClick={backToList}>Retour</a>
                        </div>
                    </div>

                    <div className="card-body pt-3">
                        <div className="mb-10">
                            <h5 className="mb-4">Détails:</h5>

                            <div className="d-flex flex-wrap py-5">
                                <div className="flex-equal me-5">
                                    <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0 fs-4">
                                        <tbody>
                                        <tr>
                                            <td className="text-gray-500 min-w-175px w-175px">Bien concerné:</td>
                                            <td className="text-gray-800 min-w-200px">
                                                <a href="#" className="text-gray-800 text-hover-primary">Appartement amondji</a>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Locataire:</td>
                                            <td className="text-gray-800">
                                                Emma Smith                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Adresse:</td>
                                            <td className="text-gray-800">
                                                Floor 10, 101 Avenue of
                                                the Light Square, New York, NY, 10050.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Contact:</td>
                                            <td className="text-gray-800">(555) 555-1234</td>
                                        </tr>
                                        </tbody></table>
                                </div>

                                <div className="flex-equal">
                                    <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0 fs-4">
                                        <tbody>
                                        <tr>
                                            <td className="text-gray-500">Mois de :</td>
                                            <td className="text-gray-800">
                                                Janvier
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-gray-500">Loyer brute:</td>
                                            <td className="text-gray-800">
                                                $149.99 / Mois
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Charge:</td>
                                            <td className="text-gray-800">
                                                $49.99
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-gray-500">Loyer Total:</td>
                                            <td className="text-gray-800">$249 / Mois</td>
                                        </tr>
                                        </tbody></table>
                                </div>
                            </div>
                        </div>

                        <div className="mb-0">
                            <h5 className="mb-4">Element à modifier:</h5>
                            <div className="row">
                                <div className="col-6">
                                    <IziInputField label={'Montant des charges'}  type={'number'}
                                                   validateRule={['required']} errorMessage={'Veuillez saisir le montant du loyer'}  name={'rent_amount'} required={true}/>
                                </div>
                                <div className="col-6">
                                    <IziSelectField  label={'Mode paiement'}  name={'payment_mode'} dataList={paymentMode}></IziSelectField>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptEdit;
