import React, {useEffect, useState} from 'react';
import MemberDetails from "./MemberDetails";
import UserAccount from "../models/UserAccount.ts";
import {loadDbJson} from "../../shared/helper";
import MemberCard from "./MemberCard";
import Paginator from "../../shared/components/paginator/Paginator";
import UserSearch from "../components/advanced-search/UserSearch";
import PaginateContent from "../../shared/components/paginator/PaginateContent";


const MemberList = () => {
    const [teamMember:UserAccount, setTeamMember] =useState({});
    const [userTeam:UserAccount[], setUserTeam] = useState([]);
    const [activeDetails, setActiveDetails] = useState(false);

    useEffect(
        () => {
            setTimeout(() => {
                const fullData = loadDbJson('db_member')
                if (fullData !== null) setUserTeam(fullData);
                console.log(fullData, userTeam)
            }, 500);
        },
        []
    );
    function getUserDetails(userDetails:UserAccount){
        setTeamMember(userDetails)
        setActiveDetails(true)
    }
    function backUserDetails(){
        setTeamMember({})
        setActiveDetails(false)
        console.log(teamMember, activeDetails)
    }

           if(!activeDetails){
               return <PaginateContent itemData={userTeam} perPage={6} render={(currentData)=><>
                   <div className="d-flex flex-wrap flex-stack mb-6">
                       <h3 className="text-gray-800 fw-bold my-2">
                           Mon Équipe
                           <span className="fs-6 text-gray-400 fw-semibold ms-1">{userTeam.length}</span>
                       </h3>

                       <div className="d-flex my-2">
                       </div>
                   </div>
                   <UserSearch></UserSearch>
                   <div className="row g-3 mb-6">
                           {currentData.map((usr, index)=>{
                               return <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4" key={index}>
                                   <MemberCard key={usr.id} user={usr} getDetails={()=>getUserDetails(usr)}/>

                               </div>
                           })}

                   </div>
               </> } />}else{
               return <MemberDetails memberDetails={teamMember} backToList={()=>backUserDetails()}></MemberDetails>
           }

};

export default MemberList;