import React from 'react';
import {useSelector} from "react-redux";
import {selectPropertyChoose} from "../../store/add-property/AddPropertyReducer";
import {selectOwnerAgency, selectUserDatasInfos} from "../../store/authentification/AuthReducer";
import {environment} from "../../environment/environment.ts";

const PropertyInfos = () => {
    const getPropChoose = useSelector(selectPropertyChoose)
    const currentUser = useSelector(selectUserDatasInfos);
    const ownerAgency = useSelector(selectOwnerAgency)
    return (
        <div className="card card-flush bg-white shadow border-1 border-light h-lg-100" id="kt_contacts_main">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                <div className="card-title">
                    <i className="ki-outline ki-information-3 fs-1 me-2"></i>
                    <h2>Informations supplémentaires du bien</h2>
                </div>

                <div className="card-toolbar gap-3">
                    {/*<a href="#"*/}
                    {/*   className="btn btn-sm btn-light btn-active-light-primary">*/}
                    {/*    <i className="ki-outline ki-arrow-down fs-2"></i> Télécharger*/}
                    {/*</a>*/}
                </div>
            </div>

            <div className="card-body pt-5">

                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                    role="tablist">

                    <li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                           data-bs-toggle="tab" href="#kt_contact_view_general" aria-selected="true" role="tab">
                            <i className="ki-outline ki-home fs-4 me-1"></i> Général
                        </a>
                    </li>


                    {currentUser.user_type?.label.toLowerCase() == "Business".toLowerCase() &&<li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4"
                           data-bs-toggle="tab" href="#kt_contact_view_meetings" aria-selected="false"
                           tabIndex="-1" role="tab">
                            <i className="ki-outline ki-user-square fs-4 me-1"></i> Propriétaire
                        </a>
                    </li>}

                </ul>
                <div className="tab-content" id="">
                    <br/>
                    <div className="tab-pane fade show active" id="kt_contact_view_general" role="tabpanel">
                        <div className="d-flex flex-column gap-5 mt-7">
                            <div className={"row"}>
                                <div className="col-sm-12 col-md-6 col-lg-6 ">
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Type</div>
                                        <div className="fw-bold fs-5">{getPropChoose.property_type.label}</div>
                                    </div>


                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Commodités</div>
                                        <div className="fw-bold fs-5">
                                            {getPropChoose.commodity.join(' - ')}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Pays</div>
                                        <div className="fw-bold fs-5"> {getPropChoose.country}</div>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Ville</div>
                                        <div className="fw-bold fs-5"> {getPropChoose.town}</div>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Commune</div>
                                        <div className="fw-bold fs-5"> {getPropChoose.township}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 ">
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Nombre de pièce</div>
                                        <div className="fw-bold fs-5">{getPropChoose.pieces_nbr}</div>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Superficie</div>
                                        <div className="fw-bold fs-5">{getPropChoose.area} m²</div>
                                    </div>


                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Adresse</div>
                                        <div className="fw-bold fs-5"><address>{getPropChoose.address}</address></div>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Description</div>
                                        <div className="fw-bold fs-5"><p>{getPropChoose.description}</p></div>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Identifiant unique</div>
                                        <div className="fw-bold fs-5"><p>{getPropChoose.uid}</p></div>
                                    </div>
                                </div>
                            </div>


                            <div className="d-flex flex-column gap-1 w-100">
                                <div className="fw-bold text-muted">Pièces jointes</div>
                                <div className="row gap-2 align-items-center border border-dashed border-gray-300 rounded  p-7">
                                    {getPropChoose.pieces_image.length > 0 && getPropChoose.pieces_image.map(imagePath => {
                                        return <div className="overlay col-sm-4 col-md-3 col-lg-2">
                                            <div className="overlay-wrapper">
                                                <img alt="img" className="rounded w-150px"
                                                     src={environment.storageApiUrl+imagePath}/>
                                            </div>

                                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                <a href="#" className="btn btn-sm btn-primary btn-shadow">image un</a>
                                            </div>
                                        </div>
                                    })
                                    }
                                    {
                                        getPropChoose.pieces_image.length===0 && <h5>Aucune Pieces jointes ajouter</h5>
                                    }
                                </div>

                            </div>

                        </div>

                    </div>


                    <br/>
                    <div className="tab-pane fade" id="kt_contact_view_meetings" role="tabpanel">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 ">
                                <div className="d-flex flex-column gap-5 mt-7">
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">{getPropChoose.pivot.is_manager ? 'Denomination':'Civilité'}</div>
                                        <div className="fw-bold fs-5">{getPropChoose.pivot.is_manager ?ownerAgency?.agency_name:getPropChoose?.owner?.civility}</div>

                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">{getPropChoose.pivot.is_manager ? 'Idu':'Nom'}</div>
                                        <div className="fw-bold fs-5">{getPropChoose.pivot.is_manager ?ownerAgency?.agency_idu:getPropChoose?.owner?.last_name} </div>

                                    </div>

                                    { !getPropChoose.pivot.is_manager &&
                                        <>
                                            <div className="d-flex flex-column gap-1">
                                             <div className="fw-bold text-muted">Prénoms</div>
                                             <div className="fw-bold fs-5">{getPropChoose?.owner?.first_name}</div>
                                            </div>

                                            <div className="d-flex flex-column gap-1">
                                                <div className="fw-bold text-muted">Contact</div>
                                                <div className="fw-bold fs-5">{getPropChoose?.owner?.contact}</div>
                                            </div>
                                        </>
                                      }

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="d-flex flex-column gap-5 mt-7">
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Email</div>
                                        <div className="fw-bold fs-5">{getPropChoose.owner.email??"non-renseigné"}</div>
                                    </div>
                                    {!getPropChoose.pivot.is_manager &&<div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Pays</div>
                                        <div className="fw-bold fs-5">{getPropChoose.owner.country}</div>
                                    </div>}
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">{getPropChoose.pivot.is_manager ? 'Status':'Ville'}</div>
                                        <div className="fw-bold fs-5"> {getPropChoose.pivot.is_manager ?ownerAgency?.agency_status:getPropChoose.owner.town}</div>
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="fw-bold text-muted">Adresse</div>
                                        <div className="fw-bold fs-5"> {getPropChoose.pivot.is_manager ?ownerAgency?.agency_address:getPropChoose.owner.address}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>

                </div>


            </div>

        </div>

    );
};

export default PropertyInfos;