import React, {useState} from 'react';
import WorkItemList from "./WorkItemList";
import WorkItemDetails from "./WorkItemDetails";
import NewWork from "./NewWork";
import EditWorkItem from "./EditWorkItem";
import WorkHeading from "./WorkHeading";

const PropertyWorks = () => {
    const [activeNewWork,setActive] = useState(false)
    const [activeEditWork,setModify] = useState(false)
    const getActive = (data)=>{
        setActive(data);
    }
    const getModify = (data)=>{
        setModify(data.active);
    }
    return (
        <div className={"d-flex flex-column"}>
            <WorkHeading activeNewWork={getActive}></WorkHeading>
            <div className="row g-7 w-100">
                <div className="col-lg-6 col-xl-4">
                   <WorkItemList></WorkItemList>
                </div>
                <div className="col-lg-6 col-xl-8">
                    {(activeNewWork === false && activeEditWork === false) &&<WorkItemDetails modifyWork={getModify}></WorkItemDetails>}
                    {activeNewWork === true &&<NewWork backWorkList={getActive}/>}
                    {activeEditWork === true &&<EditWorkItem backWorkList={getModify}/>}
                </div>
            </div>

        </div>
    );
};

export default PropertyWorks;