import React from "react";
import AdminLayout from "../layout/admin/AdminLayout";
import {Route, Routes} from "react-router-dom";
import Dashboard from "../admin/dashboard/Dashboard";


const AdminRouter = () => {
    return (
        <Routes>
            <Route element={<AdminLayout/>}>
                <Route index element={<Dashboard/>}></Route>
            </Route>
        </Routes>
    );
};

export default AdminRouter;