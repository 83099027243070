import React, {useState} from 'react';
import {errorAlert} from "../../../shared/helper";
import IziStepper from "../../../shared/components/stepper/IziStepper";
import StepContent from "../../../shared/components/stepper/StepContent";
import IziInputField from "../../../shared/components/form/IziInputField";
import IziButton from "../../../shared/button/IziButton";
import IziSelectField from "../../../shared/components/form/IziSelectField";
import IziFileField from "../../../shared/components/form/IziFileField";
import {useDispatch} from "react-redux";
import {setIsNewTenant, setTenantChoose, setTenantChooseGuard} from "../../../store/add-contract/AddContractReducer";

const NewTenant = () => {
    const  stepItems = [
        {
            label: "Infos sur le locataire",
            isCurrent: true,
        },
        {
            label: "Infos sur la personne à contacter d'urgence",
            isCurrent: false,
        },
    ];
    const idCardType = [
        {label:"Carte National d'identité", value:"CNI"},
        {label:"Passeport", value:"Passeport"},
        {label:"Permis de conduire", value:"Permis de conduire"},
        {label:"Attestation d'identité", value:"Attestation"},
    ];
    const civilityList = [
        {label:"Monsieur", value:"Mr"},
        {label:"Madame", value:"Mme"},
        {label:"Mademoiselle", value:"Mlle"},
    ];

    const saveTenantState = useDispatch();
    const [tenantForm, setTenantForm] =useState({
        civility:'',
        last_name:'',
        first_name:'',
        township:'',
        town:null,
        contact:'',
        id_card:null,
        id_card_ref:'',
        id_card_recto:null,
        id_card_verso:null,
        job:'',
        has_account:false,
        email: '',
        password: '',
    });
    const [tenantGuardForm, setTenantGuardForm] =useState({
        civility:'',
        last_name:'',
        first_name:'',
        township:'',
        town:'',
        contact:'',
        id_card:'',
        id_card_ref:'',
        id_card_recto:null,
        id_card_verso:null,
        job:'',
        email: '',
        password: '',
    });
    const tenantInfosValidator = (isGuard = false)=>{
        const {civility, last_name, first_name, township , town, id_card, id_card_ref, contact} = isGuard ? tenantGuardForm :tenantForm
        return civility !== null && last_name.length > 1 && first_name.length > 2 &&township.length>2 && town !== null && id_card !== null && id_card_ref.length > 5 && contact.length > 7
    }
    const [stepItem, setStepItem] = useState(stepItems)
    const  [stepMove, setStepMove]=useState(0)
    function  handleMove(next ){
        let newVal = stepMove;
        newVal =next === true ? stepMove + 1 : stepMove - 1;
        setStepMove(newVal)
        switch(newVal){
            case 0:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem);
                break;
            case 1:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem)
                break;
            case 2:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[newVal].isCurrent = true;
                setStepItem(stepItem)
                break;
            default:
                stepItem.map((item)=> item.isCurrent = false);
                stepItem[0].isCurrent = true;
                setStepItem(stepItem)
                break;
        }

    }

    const onChange = (event, isFile=false, fileInputName="id_card_recto") => {
        if(!isFile){
            const { name, value, type } = event.target;
            const isCheckbox = type === "checkbox";
            setTenantForm((prevState)=>(
                {
                    ...prevState,
                    [name]: isCheckbox ? event.target.checked === true ? [...prevState.access,value]:[value] : value,
                }
            ));
        }else{
            setTenantForm({...tenantForm,  [fileInputName]: event[0]});
        }
        saveTenantData();
    };
    const saveTenantGuardData  = ()=>{
        if (tenantInfosValidator(true)){
            const tenantGuardInfos = {...tenantGuardForm};

            tenantGuardInfos.id_card_recto =tenantGuardInfos.id_card_recto ? URL.createObjectURL(tenantGuardInfos.id_card_recto):'none';
            tenantGuardInfos.id_card_verso =tenantGuardInfos.id_card_verso ? URL.createObjectURL(tenantGuardInfos.id_card_verso):'none';
            saveTenantState(setTenantChooseGuard(
                {...tenantGuardInfos}
            ));
            saveTenantState(setIsNewTenant(true))
        }

    }
    const saveTenantData = ()=>{
        if (tenantInfosValidator()){
            const tenantInfos = {...tenantForm};
            tenantInfos.id_card_recto =tenantForm.id_card_recto ? URL.createObjectURL(tenantForm.id_card_recto):'none';
            tenantInfos.id_card_verso =tenantForm.id_card_verso ? URL.createObjectURL(tenantForm.id_card_verso):'none';
            saveTenantState(setTenantChoose(
                {...tenantInfos}
            ));

            saveTenantState(setIsNewTenant(true))
        }
    }
    const onChangeGuard = (event, isFile=false, fileInputName="id_card_recto") => {
        if(!isFile){
            const { name, value, type } = event.target;
            const isCheckbox = type === "checkbox";
            setTenantGuardForm((prevState)=>(
                {
                    ...prevState,
                    [name]: isCheckbox ? event.target.checked === true ? [...prevState.access,value]:[value] : value,
                }
            ));
        }else{
            setTenantGuardForm({...tenantGuardForm, [fileInputName]: event[0]});
        }
        saveTenantGuardData();
    };
    const onChangeId = (event)=>{
        setTenantForm((prevState)=>(
            {
                ...prevState,
                'id_card': event,}
        ));
    }
    const onChangeCivility = (event)=>{
        setTenantForm((prevState)=>(
            {
                ...prevState,
                'civility': event,}
        ));
    }
    const onChangeGuardId = (event)=>{
        setTenantGuardForm({
            ...tenantGuardForm,
            'id_card': event,});
    }
    const onChangeGuardCivility = (event)=>{
        setTenantGuardForm((prevState)=>(
            {
                ...prevState,
                'civility': event}
        ));
    }
    return (
        <>
            <h4>Ajouter un nouveau Locataire à mon espace</h4>
            <IziStepper stepItemList={stepItem}>
                <form >
                    {stepMove === 0 && <StepContent headTitle={"Infos Personnel"}
                                                    headDesc={"veuillez renseigné les information du locataire"}
                                                    isActive={true}>
                        <div className="row">
                            <div className="col">
                                <IziSelectField onChange={(event) => onChangeCivility(event)} label={'Civilité'}
                                                value={tenantForm.civility} name={'civility'}
                                                dataList={civilityList}></IziSelectField>

                            </div>
                            <div className="col">
                                <IziInputField label={'Nom'} onChange={(event) => onChange(event)} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir le nom du locataire'}
                                               value={tenantForm.last_name} name={'last_name'} required={true}/>
                            </div>
                            <div className="col">
                                <IziInputField label={'Prénom'} onChange={onChange} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir le prénom du locataire'}
                                               value={tenantForm.first_name} name={'first_name'} required={true}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <IziInputField label={'Contact'} onChange={onChange} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir le contact du locataire'}
                                               value={tenantForm.contact} name={'contact'} required={true}
                                               pattern="^[0-9]{10}$"/>

                            </div>
                            <div className="col">
                                <IziInputField label={'Profession'} onChange={onChange} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la profession du locataire'}
                                               value={tenantForm.job} name={'job'} required={true}
                                               pattern="^[0-9]{10}$"/>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <IziSelectField onChange={(event) => onChangeId(event)}
                                                label={'Type de pièces d\'identité'} value={tenantForm.id_card}
                                                name={'state'} dataList={idCardType}></IziSelectField>
                            </div>
                            <div className="col">
                                <IziInputField label={'N°pièces'} onChange={onChange} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la ville du locataire'}
                                               value={tenantForm.id_card_ref} name={'id_card_ref'} required={true}
                                               pattern="^[0-9]{10}$"/>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex flex-column gap-2">
                                <IziFileField getfile={(event) => onChange(event, true, "id_card_recto")}
                                              label={"Recto de la pièce d'identité"}
                                              id={"rectoTenantCard"}></IziFileField>
                                {tenantForm.id_card_recto !== null && <span className="col-6 badge badge-primary p-2"
                                >{tenantForm.id_card_recto?.name} ({tenantForm.id_card_recto?.size / 1024} Kb)</span>}
                            </div>
                            <div className="col d-flex flex-column gap-2">
                                <IziFileField getfile={(event) => onChange(event, true, "id_card_verso")}
                                              label={"Verso de la pièce d'identité"}
                                              id={"versoTenantCard"}></IziFileField>
                                {tenantForm.id_card_verso !== null && <span className="col-6 badge badge-primary p-2"
                                >{tenantForm.id_card_verso?.name} ({tenantForm.id_card_verso?.size / 1024} Kb)</span>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <IziInputField label={'Commune'} onChange={onChange} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la commune du locataire'}
                                               value={tenantForm.township} name={'township'} required={true}/>

                            </div>
                            <div className="col">
                                <IziInputField label={'Ville'} onChange={onChange} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la ville du locataire'}
                                               value={tenantForm.town} name={'town'} required={true}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <IziInputField label={'Email'} onChange={onChange} type={'email'} name={'email'}
                                               value={tenantForm.email}
                                               errorMessage={'Veuillez saisir l\'email du locataire'} required={true}/>

                            </div>
                            <div className="col">
                                <IziInputField label={'Mot de Passe'} onChange={onChange} name={'password'}
                                               type={'text'} value={tenantForm.password}
                                               errorMessage={'Veuillez saisir le password du locataire'}
                                               required={true}/>

                            </div>
                        </div>
                        <div className="d-flex justify-content-end py-3">
                            <IziButton buttonKtType={'type-next'} size={'btn-lg'} isProgress={false}
                                       onClick={() => handleMove(true)}>
                                Contact d'urgence
                            </IziButton>
                        </div>
                    </StepContent>}
                    {stepMove === 1 && <StepContent headTitle={"Infos Compte"}
                                                    headDesc={"veuillez renseigné les information du compte"}
                                                    isActive={true}>
                        <div className="row">
                            <div className="col">
                                <IziSelectField onChange={(event) => onChangeGuardCivility(event)} label={'Civilité'}
                                                value={tenantGuardForm.civility} name={'civility'}
                                                dataList={civilityList}></IziSelectField>

                            </div>
                            <div className="col">
                                <IziInputField label={'Nom'} onChange={(event) => onChangeGuard(event)} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir le nom du locataire'}
                                               value={tenantGuardForm.last_name} name={'last_name'} required={true}/>
                            </div>
                            <div className="col">
                                <IziInputField label={'Prénom'} onChange={onChangeGuard} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir le prénom du locataire'}
                                               value={tenantGuardForm.first_name}
                                               name={'first_name'} required={true}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <IziInputField label={'Contact'} onChange={onChangeGuard} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la ville du locataire'}
                                               value={tenantGuardForm.contact} name={'contact'} required={true}
                                               pattern="^[0-9]{10}$"/>
                            </div>
                            <div className="col">
                                <IziInputField label={'Profession'} onChange={onChangeGuard} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la ville du locataire'}
                                               value={tenantGuardForm.job}
                                               name={'job'} required={true} pattern="^[0-9]{10}$"/>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <IziSelectField onChange={(event) => onChangeGuardId(event)}
                                                label={'Type de pièces d\'identité'} value={tenantGuardForm.id_card}
                                                name={'state'}
                                                dataList={idCardType}></IziSelectField>
                            </div>
                            <div className="col">
                                <IziInputField label={'N°pièces'} onChange={onChangeGuard} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la ville du locataire'}
                                               value={tenantGuardForm.id_card_ref} name={'id_card_ref'}
                                               required={true}/>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex flex-column gap-2">
                                <IziFileField getfile={(event) => onChangeGuard(event, true)} id={"rectoFile"}
                                              label={"Recto de la pièce d'identité"}></IziFileField>
                                {tenantGuardForm.id_card_recto !== null &&
                                    <span className="col-6 badge badge-primary p-2"
                                    >{tenantGuardForm.id_card_recto?.name} ({tenantGuardForm.id_card_recto?.size / 1024} Kb)</span>}
                            </div>
                            <div className="col-6 d-flex flex-column gap-2">
                                <IziFileField getfile={(event) => onChangeGuard(event, true, "id_card_verso")}
                                              id={"versoFile"} label={"Verso de la pièce d'identité"}></IziFileField>
                                {tenantGuardForm.id_card_verso !== null &&
                                    <span className="col-6 badge badge-primary p-2"
                                    >{tenantGuardForm.id_card_verso?.name} ({tenantGuardForm.id_card_verso?.size / 1024} Kb)</span>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <IziInputField label={'Commune'} onChange={onChangeGuard} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la commune du locataire'}
                                               value={tenantGuardForm.township}
                                               name={'township'} required={true}/>

                            </div>
                            <div className="col">
                                <IziInputField label={'Ville'} onChange={onChangeGuard} type={'text'}
                                               validateRule={['required']}
                                               errorMessage={'Veuillez saisir la ville du locataire'}
                                               value={tenantGuardForm.town} name={'town'} required={true}/>
                            </div>
                        </div>

                        <div className=" d-flex flex-stack py-3">
                            <IziButton buttonKtType={'previous'} colorType={'btn-light'} size={'btn-lg'}
                                       isProgress={false} onClick={() => handleMove(false)}>
                                Précédent
                            </IziButton>
                        </div>
                    </StepContent>}
                </form>
            </IziStepper>
        </>
    );
};

export default NewTenant;
