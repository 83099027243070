import React, {useState} from 'react';
import PropertyContratsList from "../contracts/components/PropertyContratsList";
import RentList from "./RentList";

const ReceiptByContract = () => {
    const contracts = Array(6).fill({rent:8000, start_date:"20/10/2023",end_date:"24/05/2024", status:101, id:7, uid:'Q114AA3M'});
    const [receiptByContract, setReceiptByContract] = useState(contracts);
    const backToList = ()=>{
        setContractChoose(null);
    }
    const [contractChoose, setContractChoose] = useState(null)
    const getContractCallBack = (event) =>{
        setContractChoose(event)
    }
    return (
        <div>
            <h4>Liste des Quittance par Contrat</h4>
            {contractChoose === null &&<PropertyContratsList contracts={receiptByContract} activeCallBack={true} callBack={getContractCallBack}></PropertyContratsList>}
            {contractChoose !== null &&<RentList contract={contractChoose} backToList={backToList}/>
            }
        </div>
    );
};

export default ReceiptByContract;
