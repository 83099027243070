import React from 'react';
import {Outlet} from "react-router-dom";
import {AdminSideBar} from "../../main/components/sidebar/AdminSideBar";
import Header from "../../main/components/header/Header";
import Backdrop from "../user/Backdrop";
import {Notification} from "../../main/components/sidebar/Notification";

const AdminLayout = () => {
    return (
        <iv
    className = "app-page  flex-column flex-column-fluid"
    id = "kt_app_page" >
        <Header> < /Header>
    <div className="app-wrapper  flex-column flex-row-fluid" id="kt_app_wrapper">
        <AdminSideBar></AdminSideBar>
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_content" className="app-content  flex-column-fluid ">
                    <div id="kt_app_content_container" className="app-container  container-fluid ">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
        <Notification></Notification>
    </div>
    <Backdrop/>
</iv>

)
    ;
};

export default AdminLayout;