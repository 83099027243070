import React from 'react';

const PageLoading = () => {
    return (
        <div className="w-100">

        </div>
    );
};

export default PageLoading;
