import React from 'react';

const IziTable = ({headerELem = [
    {
        label:"Bien",
        column:"property.Designation"
    },
    {
        label:"Locataire",
        column:"tenantFullName"
    },
    {
        label:"Mois",
        column:"rent.month"
    },
    {
        label:"Montant",
        column:"rent.amount"
    },
    {
        label:"Statut",
        column:"rent.payment.status"
    },
], tableDatable = [
]}) => {

    return (
        <>
            <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        {headerELem.map((header, index) => {
                            return index === 0 ? <th className="min-w-175px">{header.label}</th>:<th className="min-w-100px text-end">{header.label}</th>
                        })}
                    </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                    {tableDatable.map((elmeData, index)=> {
                        return <tr>
                            {headerELem.map((header, index) => {
                               return  index === 0 ? <td>
                                    <div className="d-flex align-items-center">
                                        <a href="#" className="symbol symbol-50px">
                                            {/*style="background-image:url(/metronic8/demo39/assets/media//stock/ecommerce/1.png);"*/}
                                            <span className="symbol-label" ></span>
                                        </a>

                                        <div className="ms-5">
                                            <a href="#" className="fw-bold text-gray-600 text-hover-primary">{elmeData[header.column]}</a>
                                            {/*<div className="fs-7 text-muted">Duplex, 45  rue Marchant Trechville Abidjan Cote d'ivoir</div>*/}
                                        </div>
                                    </div>
                                </td> :<td className="text-end">
                                   {elmeData[header.column]}
                                </td>
                            })}


                            {/*<td className="text-end">*/}
                            {/*    2*/}
                            {/*</td>*/}
                            {/*<td className="text-end">*/}
                            {/*    $120.00*/}
                            {/*</td>*/}
                            {/*<td className="text-end">*/}
                            {/*    $240.00*/}
                            {/*</td>*/}
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>

        </>
    );
};

export default IziTable;
