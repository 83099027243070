import React, {useEffect, useState} from 'react';
import axios_http from "../../../http/axios-plugin";
import endpoint from "../../../endpoint/endpoint.ts";
import {useDispatch, useSelector} from "react-redux";
import {selectPropertyType, setPropertyType} from "../../../store/add-property/AddPropertyReducer";
import {errorAlert} from "../../../shared/helper";

const PropertyChoice = () => {
    const getChoiceState = useSelector(selectPropertyType)
    const [propertyType, setPropertyTypeInfos] = useState(getChoiceState);
    const [loading, setLoad] = useState(false);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const storeType = useDispatch()
    useEffect(() => {
        setLoad(true);
        if (propertyTypeList.length === 0){
            axios_http.get(endpoint.getPropertyTypes).then((resp_prop_type) => {
                setPropertyTypeList(resp_prop_type.data.data)
            }).catch((err) => {
                errorAlert("Ooops!", "une erreur est survenue lors du chargement des données").then()
                console.error(err)
            }).finally(() => {
                setLoad(false);
            });

        }
    }, []);
    const onChange = (event) => {
        const {value} = event.target;
        storeType(setPropertyType(value))
        setPropertyTypeInfos(value)
    };
    return (
        <div className={"row my-6 g-5 w-100 " + (loading ? "placeholder-glow" : "")} data-kt-buttons="true"
             data-kt-buttons-target="[data-kt-button]"
             data-kt-initialized="1">
            {
                propertyTypeList.map((propType, index) => (

                    <div className="col-md-4 col-lg-12 col-xxl-4" key={index}>
                        <label
                            className={"btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 " + (loading ? "placeholder" : "") + (propertyType === propType.id ? 'active' : '')}
                            data-kt-button="true">
                            <span
                                className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                    <input className="form-check-input" type="radio" name="propertyType"
                                           checked={propertyType == propType?.id}

                                           value={propType.id} onChange={onChange}/>
                                </span>
                            <span className="ms-5">
                                    <span className="fs-4 fw-bold mb-1 d-block">{propType.label}</span>
                            </span>
                        </label>
                    </div>

                ))
            }
        </div>

    );
};

export default PropertyChoice;