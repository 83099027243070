import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import IziButton from "../../shared/button/IziButton";
import TenantInfos from "./components/TenantInfos";
import TenantGuarantorInfos from "./components/TenantGuarantorInfos";

const EditTenant = () => {
    const {tenantId} = useParams()
    const routeRedirect = useNavigate();
    const redirect = (uri) => {
        routeRedirect(uri);
    };
    function getTenantList(){
        redirect('/dashboard/owner/tenants');
    }
    const tenantData = {
        civility: {label:'Monsieur', value:'Monsieur'},
        last_name:'gongoloma',
        first_name:'Souké',
        township:'Haut senegal',
        town:'Yagouwahi',
        country:'Gadogaoua',
        contact:'+5533697741',
        id_card_type:{label:"Carte National d'identité", value:'CNI'},
        id_card:'114s555a666',
        id_card_recto:'dddddd',
        id_card_verso:'dddddddddd',
        job:'Machiniste',
        has_account:true,
        email: 'wangrin@gmail.com',
        password: 'belleBichette',
    }
    const tenantGuarantor = {
        civility: {label:'Monsieur', value:'Monsieur'},
        last_name:'Koyaga',
        first_name:'Souké',
        township:'Ozone',
        town:'Goma',
        country:'Gadogaoua',
        contact:'+225 5533697041',
        id_card_type:{label:"Carte National d'identité", value:'CNI'},
        id_card:'Q55A3TT778',
        id_card_recto:'dddddd',
        id_card_verso:'dddddddddd',
        job:'Architect',
        has_account:true,
        email: 'Kuruma@gmail.com',
        password: 'belleBichette',
    }
    return (
        <div>
            <div className="row my-2">
                <div className="col">
                    <IziButton onClick={getTenantList}>Retour</IziButton>
                </div>
            </div>
            <TenantInfos tenant={tenantData}></TenantInfos>
            <TenantGuarantorInfos guarantor={tenantGuarantor}></TenantGuarantorInfos>
        </div>
    );
};

export default EditTenant;
