import React from 'react';
import Card from "../../../shared/components/card/Card";
import IziChart from "../../../shared/components/charts/IziChart";

const Statistic = () => {
    return (
        <div className="card card-flush bg-white shadow border-1 border-light h-lg-100" id="kt_contacts_main">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                <div className="card-title">
                    <i className="ki-outline ki-information-3 fs-1 me-2"></i>
                    <h2>Information financières du bien</h2>
                </div>

                <div className="card-toolbar gap-3">
                    {/*<a href="#"*/}
                    {/*   className="btn btn-sm btn-light btn-active-light-primary">*/}
                    {/*    <i className="ki-outline ki-arrow-down fs-2"></i> Télécharger*/}
                    {/*</a>*/}
                </div>
            </div>

            <div className="card-body pt-5">
                {/*<div className="d-flex gap-7 align-items-center">*/}
                {/*    <div className="d-flex flex-column gap-2">*/}
                {/*        <h3 className="mb-0">Mme Drago Malfoy Serpenta</h3>*/}

                {/*        <div className="d-flex align-items-center gap-2">*/}
                {/*            <i className="ki-outline ki-phone fs-2"></i>*/}
                {/*            <a href="#" className="text-muted text-hover-primary">Contact: +225 05 66 99 88 45</a>*/}
                {/*        </div>*/}

                {/*        <div className="d-flex align-items-center gap-2">*/}
                {/*            <i className="ki-outline ki-address-book fs-2"></i>*/}
                {/*            <a href="#" className="text-muted text-hover-primary">Adresse: Charlie Armageddon 52 rue pol </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <h2>Revenues du bien</h2>
                <div className="row g-5 g-xl-10">
                    <div className="col-xxl-12 mb-md-5 mb-xl-10">
                        <div className="row g-5 g-xl-10">
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"15 500"}
                                    contentNumber={"15 500"}
                                    subTitle={"Ce mois"}
                                    hasIcon={false}
                                    cardIcon={"ki-outline ki-home-2 text-gray-400"}
                                    isAmount={true}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"105 500"}
                                    contentNumber={"105 500"}
                                    subTitle={"Ce trimestre"}
                                    hasIcon={false}
                                    cardIcon={"ki-outline ki-home-2 text-gray-400"}
                                    isAmount={true}
                                ></Card>
                            </div>
                            <div className="col-md-4 col-xl-4 mb-xxl-10">
                                <Card
                                    title={"1 000 500"}
                                    contentNumber={"1 000 500"}
                                    subTitle={"Cette année"}
                                    hasIcon={false}
                                    cardIcon={"ki-outline ki-home-2 text-gray-400"}
                                    isAmount={true}
                                ></Card>
                            </div>

                        </div>
                    </div>
                </div>

                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                    role="tablist">

                    <li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                           data-bs-toggle="tab" href="#kt_contact_view_general" aria-selected="true" role="tab">
                            <i className="ki-outline ki-chart fs-4 me-1"></i> Statistique
                        </a>
                    </li>


                    {/*<li className="nav-item" role="presentation">*/}
                    {/*    <a className="nav-link text-active-primary d-flex align-items-center pb-4"*/}
                    {/*       data-bs-toggle="tab" href="#kt_contact_view_meetings" aria-selected="false"*/}
                    {/*       tabIndex="-1" role="tab">*/}
                    {/*        <i className="ki-outline ki-more-2 fs-4 me-1"></i> Plus*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                </ul>


                <div className="tab-content" id="">
                    <br/>
                    <div className="row g-5 g-xl-10">
                        <div className="col-12 mb-xxl-10">
                            <Card
                            >
                                <IziChart></IziChart>
                            </Card>
                        </div>
                    </div>


                    <br/>
                    <div className="tab-pane fade" id="kt_contact_view_meetings" role="tabpanel">
                        <div className="row">
                            <div className="col">
                                <h4 className="fs-4 fw-bold text-center my-2">Abonnement</h4>
                            </div>
                        </div>
                    </div>

                    <br/>

                </div>


            </div>

        </div>
    );
};

export default Statistic;