import React from 'react';

const IziCheckboxField = ({ label, checked,name, onChange, value, ...inputProps }) => {
    return (<label className="form-check form-check-custom form-check-solid me-10">
            {/*checked={checked}*/}
            <input className="form-check-input h-20px w-20px" type="checkbox" name={name} value={value} onChange={onChange} checked={checked}
                   {...inputProps}  />

            <span className="form-check-label fw-semibold">
                {label}
            </span>
        </label>);
};

export default IziCheckboxField;