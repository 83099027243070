import React from 'react';
import {Route, Routes} from "react-router-dom";
import {HomeLayout, Home, Register, Login} from "../home/index"
const PublicRouter = () => {
    return (
        <Routes>
            <Route element={<HomeLayout/>}>
                <Route index element={<Home/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='*' element={<Home/>}/>
            </Route>
        </Routes>
    );
};

export default PublicRouter;