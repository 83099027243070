import React from 'react';

const ContactItem = ({isCurrent}) => {
    return (
        <div className={"notice d-flex "+(isCurrent ? 'bg-white border-2':'bg-light-primary border')+" rounded border-primary  border-dashed  p-4 cursor-pointer my-3"}>
            <i className="ki-outline ki-document fs-2tx text-primary me-4"></i>

            <div className="d-flex flex-stack flex-grow-1 ">
                <div className="fw-semibold">
                    <h5 className="text-gray-900 fw-bold">N°: Q55M8P6OO</h5>
                    <div className="fs-6 text-gray-700 ">
                        <p>Locataire: Agitel Inc</p>
                        <p>Périodicité: Mensuelle</p>
                        <p>Début: 2023-05-14  Fin: 2023-12-19</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContactItem;