import React, {useState} from 'react';
import TenantChoose from "./TenantChoose";
import IziButton from "../../../shared/button/IziButton";
import NewTenant from "./NewTenant";

const Tenant = () => {
    const [newTenant, setNewTenant] = useState(false)
    return (
        <div className="d-flex flex-column w-100">
            <div className="row mb-3">
                <div className="col-12 w-100 d-flex justify-content-end">
                    {newTenant===false&& <IziButton onClick={() => setNewTenant(true)}>Nouveau Locataire</IziButton>}
                    {newTenant===true&&<IziButton onClick={() => setNewTenant(false)}>Annuler</IziButton>}
                </div>
            </div>
            {newTenant===false&&<TenantChoose></TenantChoose>}
            {newTenant===true&&<NewTenant></NewTenant>}
        </div>
    );
};

export default Tenant;
