import React from 'react';

const InventoryItem = ({isCurrent}) => {
    return (
        <div className={"notice d-flex "+(isCurrent ? 'bg-white border-2':'bg-light-primary border')+" rounded border-primary  border-dashed  p-4 cursor-pointer my-3"}>
            <i className="ki-outline ki-document fs-2tx text-primary me-4"></i>

            <div className="d-flex flex-stack flex-grow-1 ">
                <div className="fw-semibold">
                    <h5 className="text-gray-700 fw-bold">État du 2023-03-07</h5>
                    <div className="fs-6 text-gray-700 ">
                        <p>Locataire: Agitel Inc</p>
                        <p>Statut: Approuvé</p>
                        <p>ENTRÉE: 2023-05-14 </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default InventoryItem;