import React, {useEffect, useState} from 'react';
import {errorAlert, Validator} from "../../helper";
import Select from 'react-select'

const IziSelectField = ({
                            label,
                            errorMessage,
                            placeholder,
                            value,
                            onChange,
                            name,
                            isMulti=false,
                            validateRule = ['required'],
                            dataList = [],
                            ...inputProps
                        }) => {
    const [error, setError] = useState(false);
            const onHandleFocus = (e) => {
                if (Validator(e.target.value, validateRule).length > 0) {
                    errorAlert('null', errorMessage, "compris").then();
                    setError(true);
                }
                console.log(error)
            };
            return (
                <>
                    <div className="fv-row mb-8 fv-plugins-icon-container" data-select2-id="select2-data-149-ksn1">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{label}</span>


                            <span className="ms-1" data-bs-toggle="tooltip" aria-label="Select the currency type."
                                  data-bs-original-title="Select the currency type." data-kt-initialized="1">
	<i className="ki-outline ki-information-5 text-gray-500 fs-6"></i></span> </label>
                        <Select isMulti={isMulti} options={dataList} name={name} value={value} onChange={onChange} defaultValue={value}></Select>
                    </div>
                </>
            );
        };

    export default IziSelectField;