import React, {useState} from 'react';
import DatePicker from "react-multi-date-picker";

const IziDatePicker = ({onChange,values, label, name}) => {
    // const today = new Date()
    // const [values, setValues] = useState([today])
    return (
        <>
            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span className="required">{label}</span>
                    <span className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
	            <i className="ki-outline ki-information-5 text-gray-500 fs-6"></i></span>
                </label>
            <DatePicker
                multiple
                value={values}
                onChange={onChange}
                name={name}
                style={{
                    height: "40px",
                    borderRadius: "6px",
                    width: "100%",
                    fontSize: "14px",
                    padding: "3px 10px"
                }}
            />
            </div>
        </>
    );
};

export default IziDatePicker;
