import React, {useState} from 'react';
import './home.scss'
import FeatureBloc from "./components/FeatureBloc";
import SubscribePlan from "./components/SubscribePlan";
const Home = () => {
    const [packMonth, changePack] = useState(true)
    const [packLabel, setLabel] = useState("Mois")
    const [packAmount, setAmount] = useState(5000)
    const [packAmountTwo, setAmountTwo] = useState(15000)
    const switchPack = (value) =>{
        if (value === true){
            changePack(true)
            setLabel("Mois")
            setAmount(5000)
            setAmountTwo(15000)
        }else {
            changePack(false)
            setLabel("Année")
            setAmount(75000)
            setAmountTwo(120000)
        }
    }
    return (
        <div className="container">
            <div className="text-center mb-17">
                <h3 className="fs-2hx text-dark mb-5" id="how-it-works"
                    data-kt-scroll-offset="{default: 100, lg: 150}">Fonctionnalité</h3>

                <div className="fs-5 text-muted fw-bold">
                    IZILOYER, la solution digitale au service des agences immobilières <br/>
                    et propriétaires en Afrique pour gérer facilement leurs biens en location
                </div>
            </div>

            <div className="row w-100 gy-10 mb-md-20">
                <div className="col-md-4 px-5">
                    <FeatureBloc
                        desc={<p>Le module Gestion des biens permet :<br/>
                            De créer des biens,
                            De voir sa disponibilité,
                            D’y allouer un loyer et un locataire,
                            De vérifier,
                            </p>}
                       imgPath={"/assets/images/home-town-house.png"} nbr={1} title={"Gestion des biens"}/>
                </div>

                <div className="col-md-4 px-5">
                    <FeatureBloc
                        desc={<p className={"text-justify"}>
                            Le module Gestion des locataires permet :<br/>
                            Créer le profil d’un locataire dans l’application,
                            De lui attribuer un logement,
                            De lui rattacher un contrat,
                            De vérifier l’état de paiement de ses redevances de loyer,

                        </p>}
                        imgPath={"/assets/images/home-manage-tenant.png"} nbr={2} title={"Gestion des locataires"}/>

                </div>

                <div className="col-md-4 px-5">
                    <FeatureBloc
                        desc={<p className={"text-start"}>
                            Le module Gestion des locataires permet :<br/>
                            Créer le profil d’un locataire dans l’application,
                            De lui attribuer un logement,
                            De lui rattacher un contrat,
                            De vérifier l’état de paiement de ses redevances de loyer,

                        </p>}
                        imgPath={"/assets/images/home-prop-state.png"} nbr={3} title={"État des lieux"}/>
                </div>
                <div className="col-md-4 px-5">
                    <FeatureBloc
                        desc={<p className={"text-justify"}>
                            Le module Inventaires permet :<br/>
                            Aux loueurs d’appartements meublés de créer une liste de produits disponibles et d’en
                            vérifier la disponibilité au départ d’un locataire.
                        </p>}
                        imgPath={"/assets/images/home-prop-state.png"} nbr={4} title={"Inventaires"}/>
                </div>
                <div className="col-md-4 px-5">
                    <FeatureBloc
                        desc={<p className={"text-justify"}>
                            Le module Gestion des contrats permet :<br/>
                            Aux bailleur de rédiger, charger leur contrat
                        </p>}
                        imgPath={"/assets/images/home-prop-state.png"} nbr={5} title={"Gestion des contrats"}/>
                </div>
                <div className="col-md-4 px-5">
                    <FeatureBloc
                        desc={<p className={"text-justify"}>
                            Le module Interventions techniques permet :<br/>
                            ⦁ De suivre les interventions techniques qui ont eu lieu dans un appartement sur demande du
                            locataire ou du loueur
                        </p>}
                        imgPath={"/assets/images/home-prop-state.png"} nbr={6} title={"Interventions techniques"}/>
                </div>
            </div>


            <div className="tns tns-default tns-initiazlied">
                <div className="tns-outer" id="tns1-ow">
                    <div className="tns-liveregion tns-visually-hidden" aria-live="polite"
                         aria-atomic="true">slide <span className="current">3</span> of 4
                    </div>
                    <div id="tns1-mw" className="tns-ovh">
                        <div className="tns-inner" id="tns1-iw">
                            <div data-tns="true" data-tns-loop="true" data-tns-swipe-angle="false"
                                 data-tns-speed="2000" data-tns-autoplay="true"
                                 data-tns-autoplay-timeout="18000" data-tns-controls="true" data-tns-nav="false"
                                 data-tns-items="1" data-tns-center="false" data-tns-dots="false"
                                 data-tns-prev-button="#kt_team_slider_prev1"
                                 data-tns-next-button="#kt_team_slider_next1"
                                 className="  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal"
                                 id="tns1" data-kt-initialized="1">
                                <div
                                    className="text-center px-5 pt-5 pt-lg-10 px-lg-10 tns-item tns-slide-cloned"
                                    aria-hidden="true" tabIndex="-1">
                                    <img src="https://images.unsplash.com/photo-1608222351212-18fe0ec7b13b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
                                         className="card-rounded shadow mh-lg-650px mw-100" alt=""/>
                                </div>

                                <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10 tns-item"
                                     id="tns1-item0" aria-hidden="true" tabIndex="-1">
                                    <img src="https://images.unsplash.com/photo-1599658880436-c61792e70672?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                                         className="card-rounded shadow mh-lg-650px mw-100" alt=""/>
                                </div>
                                <div
                                    className="text-center px-5 pt-5 pt-lg-10 px-lg-10 tns-item tns-slide-active"
                                    id="tns1-item1">
                                    <img src="https://images.unsplash.com/photo-1560472354-b33ff0c44a43?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2151&q=80"
                                         className="card-rounded shadow mh-lg-650px mw-100" alt=""/>
                                </div>
                                <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10 tns-item"
                                     id="tns1-item2" aria-hidden="true" tabIndex="-1">
                                    <img src="https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2076&q=80"
                                         className="card-rounded shadow mh-lg-650px mw-100" alt=""/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_prev1"
                        aria-controls="tns1" tabIndex="-1" data-controls="prev">
                    <i className="ki-duotone ki-left fs-2x"></i></button>

                <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_next1"
                        aria-controls="tns1" tabIndex="-1" data-controls="next">
                    <i className="ki-duotone ki-right fs-2x"></i></button>
            </div>
            <div className="py-10 landing-dark-bg ">
                <div className="container">
                    <div className="d-flex flex-column container pt-lg-20">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bold text-white mb-5" id="pricing" data-kt-scroll-offset="{default: 100, lg: 150}">Pourquoi souscrire à IZILOYER ?</h1>

                            <div className="text-gray-600 fw-semibold fs-5">
                                Un mois d’essai gratuit quel que soit l’offre souscrite ,Désabonnement à tout moment.
                            </div>
                        </div>

                        <div className="text-center" id="kt_pricing">
                            <div className="nav-group landing-dark-bg d-inline-flex mb-1" data-kt-buttons="true" style={{border: 1+'px dashed #2B4666'}} data-kt-initialized="1">
                                <button  onClick={()=>switchPack(true)} className={"btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2"+ (packMonth ? " active" :"")} data-kt-plan="month">
                                    Mensuelle
                                </button>

                                <button  onClick={()=>switchPack(false)} className={"btn btn-color-gray-600 btn-active btn-active-success px-6 py-3"+ (packMonth === false ? " active" :"")} data-kt-plan="annual">
                                    Annuelle
                                </button>
                            </div>

                            <div className="row g-10 mt-5 pt-5">
                                <div className="col-xl-6">
                                    <SubscribePlan
                                        avantage={[
                                            {
                                                label:"Jusqu'a 2 utilisateurs",
                                                include:true
                                            },
                                            {
                                                label:"Jusqu'a 5 biens",
                                                include:true
                                            },
                                            {
                                                label:"Module campagne marketing",
                                                include:false
                                            },
                                            {
                                                label:"Module analytique",
                                                include:false
                                            },
                                        ]}
                                        duration={packLabel}
                                        packTitle={"Basic"}
                                        amount={packAmount}
                                        currency={"CFA"}
                                        desc={"ideal pour les propriétaire et gérant de biens"}/>
                                </div>
                                <div className="col-xl-6">
                                    <SubscribePlan
                                        avantage={[
                                            {
                                                label:"Jusqu'a 100+ utilisateurs",
                                                include:true
                                            },
                                            {
                                                label:"Jusqu'a 100+ biens",
                                                include:true
                                            },
                                            {
                                                label:"Module campagne marketing",
                                                include:true
                                            },
                                            {
                                                label:"Module analytique",
                                                include:true
                                            },
                                        ]}
                                        duration={packLabel}
                                        packTitle={"Business"}
                                        amount={packAmountTwo}
                                        currency={"CFA"}
                                        desc={"idéal pour les agence immobilière"}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;