export const userAccess =[
    {
        title: 'Creer un bien',
        rootLink: 'property/add',
    },
    {
        title: 'liste les biens',
        rootLink: 'property/list',
    },
    {
        title: 'voir le dashboard',
        rootLink: 'dashboard/analytics',
    },
];