import React from 'react';
import OldTenantList from "./OldTenantList";
import TenantDetails from "./TenantDetails";
import TenantHeading from "./TenantHeading";

const PropertyTenant = () => {
    return (
        <div className={"d-flex flex-column"}>
            <TenantHeading></TenantHeading>
            <div className="row g-7 w-100">
                <div className="col-lg-6 col-xl-4">
                    <OldTenantList/>
                </div>
                <div className="col-lg-6 col-xl-8">
                    <TenantDetails/>
                </div>
            </div>

        </div>
    );
};

export default PropertyTenant;