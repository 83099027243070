import React, {useState} from 'react';
import ContractByProperty from "./components/ContractByProperty";
import PropertyContratsList from "./components/PropertyContratsList";

const ContractList = () => {
    const [contractsChoose, setContratcts] = useState([]);
    const getContractChoose = (data)=>{
        setContratcts(data)
    }
    const backToList = ()=>{
        setContratcts([])
    }
    return (
        <>
            {contractsChoose.length ===0 && <ContractByProperty setContrat={getContractChoose}></ContractByProperty>}
            {contractsChoose.length > 0 &&<PropertyContratsList contracts={contractsChoose} backTolist={backToList}></PropertyContratsList>}
        </>
    );
};

export default ContractList;
