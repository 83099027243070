import React, {useEffect, useState} from 'react';
import IziInputField from "../../../shared/components/form/IziInputField";
import IziButton from "../../../shared/button/IziButton";
import {useDispatch, useSelector} from "react-redux";
import {
    selectEnterInventoryExtra,
    setPropertyEnterInventoryExtra
} from "../../../store/add-contract/AddContractReducer";

const CommodityInventory = () => {
    const saveCommState = useDispatch();
    const extratCommState = useSelector(selectEnterInventoryExtra);
    const [contractInventoryCommodity
        , setCommodity] = useState({
        water_meter:"",
        water_meter_used:"",
        water_amount_remain:"",
        electricity_meter:"",
        electricity_old_used:"",
        electricity_amount_remain:"",
    });
    useEffect(() => {
        if (extratCommState){
            setCommodity({...extratCommState})
        }
    }, []);
    const commoddityValidator = ()=>{
        return contractInventoryCommodity.water_meter.length >= 3 && contractInventoryCommodity.electricity_meter.length >= 3
    }
    const onChange = (event) => {
        const { name, value } = event.target;
        setCommodity((prevState)=> ({...prevState,
            [name]: value,
        }));
        if (commoddityValidator()){
            saveCommState(setPropertyEnterInventoryExtra(
                {...contractInventoryCommodity,
                    [name]: value,
                }
            ))
        }
    };

    return (
        <form className="container-md my-3">
            <div className="row">
                <div className="col">
                    <IziInputField label={'Numéro de compteur eau'} onChange={onChange} type={'text'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir l\'id '} value={contractInventoryCommodity.water_meter} name={'water_meter'} required={true}/>
                </div>
                <div className="col">
                    <IziInputField label={'Ancienne consommation'} onChange={onChange} type={'number'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir le nombre'} value={contractInventoryCommodity.water_meter_used} name={'water_meter_used'} required={true}/>
                </div>
                <div className="col">
                    <IziInputField label={'Montant restant à payer'} onChange={onChange} type={'number'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir le nombre'} value={contractInventoryCommodity.water_amount_remain} name={'water_amount_remain'} required={true}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <IziInputField label={'Numéro de compteur électricité'} onChange={onChange} type={'text'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir l\'id '} value={contractInventoryCommodity.electricity_meter} name={'electricity_meter'} required={true}/>
                </div>
                <div className="col">
                    <IziInputField label={'Ancienne consommation'} onChange={onChange} type={'number'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir le nombre'} value={contractInventoryCommodity.electricity_old_used} name={'electricity_old_used'} required={true}/>
                </div>
                <div className="col">
                    <IziInputField label={'Montant restant à payer'} onChange={onChange} type={'number'} validateRule={['required']}
                                   errorMessage={'Veuillez saisir le nombre'} value={contractInventoryCommodity.electricity_amount_remain} name={'electricity_amount_remain'} required={true}/>
                </div>
            </div>
        </form>
    );
};

export default CommodityInventory;
