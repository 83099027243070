import React, {useState} from 'react';
import Card from "../../shared/components/card/Card";
import IziModal from "../../shared/components/modal/IziModal";
import UpgradePlan from "./UpgradePlan";
import SubscribePayments from "./SubscribePayments";

const OwnerSubscribe = () => {
    const [plan, setPlan] = useState(false)
    return (
        <>
            {plan === true && <IziModal modalTitle={"Mettre à jour mon abonnement"} closeValue={plan} closeModal={()=>setPlan(false)} children={
                <UpgradePlan/>
            }/>}
            <Card>
                <div
                    className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6">
                    <i className="ki-outline ki-check-circle fs-2tx text-warning me-4"></i>

                    <div className="d-flex flex-stack flex-grow-1 ">
                        <div className=" fw-semibold">
                            <h4 className="text-gray-900 fw-bold">Forfait Gold</h4>

                            <div className="fs-6 text-gray-700 ">100 biens et mode analytique activé.
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-7">
                        <h3 className="mb-2">Valide jusqu'au 09 Décembre 2024</h3>
                        <p className="fs-6 text-gray-600 fw-semibold mb-6 mb-lg-15">Nous vous enverrons une notification
                            à l'expiration de l'abonnement </p>

                        <div className="fs-5 mb-2">
                            <span className="text-gray-800 fw-bold me-1">CFA 24.99</span>
                            <span className="text-gray-600 fw-semibold">Par Mois</span>
                        </div>

                        <div className="fs-6 text-gray-600 fw-semibold">
                            Forfait PME avec 100 biens &amp; mode analytique activé
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="d-flex text-muted fw-bold fs-5 mb-3">
                            <span className="flex-grow-1 text-gray-800">Biens</span>
                            <span className="text-gray-800">86 sur 100 Utilisé</span>
                        </div>

                        <div className="progress h-8px bg-light-primary mb-2">
                            <div className="progress-bar bg-primary" role="progressbar" style={{width: "86%"}}
                                 aria-valuenow="86" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        <div className="fs-6 text-gray-600 fw-semibold mb-10">14 biens restant pour votre forfait
                        </div>
                        <div className="d-flex text-muted fw-bold fs-5 mb-3">
                            <span className="flex-grow-1 text-gray-800">Utilisateurs</span>
                            <span className="text-gray-800">2 sur 10 Ajouté</span>
                        </div>

                        <div className="progress h-8px bg-light-primary mb-2">
                            <div className="progress-bar bg-primary" role="progressbar" style={{width: "20%"}}
                                 aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div className="fs-6 text-gray-600 fw-semibold mb-10">8 utilisateurs restant pour votre forfait
                        </div>

                        <div className="d-flex justify-content-end pb-0 px-0">
                            {/*<a href="#" className="btn btn-light btn-active-light-primary me-2"*/}
                            {/*   id="kt_account_billing_cancel_subscription_btn">Annuler mon abonnement</a>*/}
                            <button className="btn btn-primary fw-semibold" onClick={() => setPlan(true)}>Mettre à jour
                                mon plan
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
            <Card>
                <SubscribePayments></SubscribePayments>
            </Card>
        </>
    );
};

export default OwnerSubscribe;
