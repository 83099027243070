import React from 'react';

const IziModal = ({children, modalTitle='test',closeValue=false, closeModal=false, fullScreen=true}) => {
    return (
        <>
            <div className={"modal " + (closeValue ? "show" : "fade")} tabIndex="-1"
                 aria-modal="true" role="dialog"
                 style={{display: (closeValue ? "block" : "none")}}>
                <div
                    className={"modal-dialog " + (fullScreen ? "modal-fullscreen" : "modal-dialog-centered mw-750px") + " p-9"}>
                    <div className="modal-content modal-rounded">
                        <div className="modal-header py-7 d-flex justify-content-between">
                            <h2>{modalTitle}</h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal"
                                 onClick={closeModal}>
                                <i className="ki-outline ki-cross fs-1"></i>
                            </div>
                        </div>

                        <div className="modal-body scroll-y m-5">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IziModal;