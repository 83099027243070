import axios from 'axios';

import {environment} from "../environment/environment.ts";
import endpoint from "../endpoint/endpoint.ts";

const axios_http = axios.create({
    baseURL: environment.apiUrl,
    timeout: 12000,
});
// Ajouter un en-tête d'authentification à chaque requête sortante
axios_http.interceptors.request.use(
    config => {
        const token = localStorage.getItem("access_token")
            // useSelector(selectAccessToken)
        // Modifiez ici si nécessaire la configuration de la requête avant l'envoi
        const formDataEndpoint = [
          endpoint.storeProperty,
          endpoint.updatePropertyAttachFiles,
            endpoint.storeTenant,
            endpoint.storePropertyInventory,
            endpoint.storeTenantEmergencyContact,
            endpoint.storeContract,
        ];

        if (token && formDataEndpoint.includes(config.url)){
            config.headers = {
                Authorization:`Bearer ${token}`,
                'Content-Type':  'multipart/form-data',
                'Access-Control-Allow-Origin':'*',
                'X-Frame-Options':"allow-from *",
                Accept: '*/*',
            }
        }
       else if (token && !formDataEndpoint.includes(config.url)){
            config.headers = {
                Authorization:`Bearer ${token}`,
                'Content-Type':  'application/json',
                'Access-Control-Allow-Origin':'*',
                'X-Frame-Options':"allow-from *",
                Accept: '*/*',
            };
        }else {
            config.headers = {
                'Content-Type':  'application/json',
                Accept: '*/*',
            };

        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Autres intercepteurs ou configuration d'Axios ici
export  default  axios_http;
