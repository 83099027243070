import React, {useRef} from 'react';
import {EmailEditor} from "react-email-editor";

const ConfigEmailEditor = () => {
    const emailEditorRef = useRef(null);
    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            console.log('exportHtml', html);
        });
    };
    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }
    const onReady = () => {
        // editor is ready
        console.log('onReady');
    };
    return (
        <>
            <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">
                    Créer un nouveau modèle d'email
                </h1>
            </div>

            <div>
                <div>
                    <button className={"btn btn-sm btn-primary"} onClick={exportHtml}>Sauvegarder</button>
                    <p className={"fs-6"}>Utilisé ces annotation pour charge dynamiquement les données clients et biens:<br/>
                        <ul>
                            <li>Nom complet du client : <strong>{"{{client_fullname}}"}</strong></li>
                            <li>Contact du client : <strong>{"{{client_contact}}"}</strong></li>
                            <li>Adresse du client : <strong>{"{{client_address}}"}</strong></li>
                            <li>Designation du bien : <strong>{"{{property_label}}"}</strong></li>
                            <li>Coût du loyer du bien : <strong>{"{{property_rent}}"}</strong></li>
                            <li>Adresse du bien : <strong>{"{{property_address}}"}</strong></li>
                        </ul>
                    </p>
                </div>

                <EmailEditor
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    onReady={onReady}
                    locale={"fr"}
                    minHeight={600}
                />
            </div>
        </>
    );
};

export default ConfigEmailEditor;
