import React from 'react';
import WorkSearch from "../../components/advanced-search/WorkSearch";

const WorkHeading = ({activeNewWork}) => {
    return (
        <div className={"card  bg-white shadow border-1 border-light h-75 m-3 p-7"}>
           <div className="row mb-4">
               <div className="col-sm-12 col-md-12 col-lg-8">
                   <h3 className="fw-semibold text-dark">Recherche multi-critère</h3>
               </div>
               <div className="col-sm-12 col-md-12 col-lg-4">
                   <div className="d-flex justify-content-center align-items-center">

                       <button  className="btn btn-flex btn-primary h-40px fs-7 fw-bold"  onClick={()=>activeNewWork(true)}>
                           Nouveaux travaux
                       </button>
                   </div>

               </div>

           </div>
            <div className="row w-100">
                <div className=" col-sm-12 col-md-12 col-lg-12">
                    <WorkSearch></WorkSearch>
                </div>
            </div>

        </div>
    );
};

export default WorkHeading;
