import React from 'react';

const IziTextAreaField = ({label,  errorMessage,placeholder,value,onChange,name,validateRule=[], ...inputProps}) => {
    return (
        <>
            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span className="required">{label}</span>
                    <span className="ms-1" data-bs-toggle="tooltip"
                          aria-label="Specify a target name for future usage and reference"
                          data-bs-original-title="Specify a target name for future usage and reference"
                          data-kt-initialized="1">
	            <i className="ki-outline ki-information-5 text-gray-500 fs-6"></i></span>
                </label>
                <div className="mb-5">
                    <textarea name={name} value={value} onChange={onChange} className="form-control form-control-solid" rows="3"
                              placeholder={placeholder}></textarea>
                </div>
            </div>
        </>
    );
};

export default IziTextAreaField;