import React from 'react';

const FullCard = ({children, isItem = false}) => {
    return (
        <div className="card bg-white shadow my-2 border-1 border-light mb-5">
            <div className={"card-body"+(isItem === true ? "relative d-flex flex-center flex-column py-2 px-1":"pt-9 pb-0")}>
                {children}
            </div>
        </div>
    );
};

export default FullCard;
