import React from 'react';
import ContactItem from "./ContactItem";

const ContractItemList = () => {
    return (
        <div className="card bg-white shadow border-1 border-light card-flush" id="kt_contacts_list">
            <div className="card-header pt-7" id="kt_contacts_list_header">
                <h5 className="fw-bold fs-4">Liste des contrats</h5>
            </div>
            <div className="card-body pt-5" id="kt_contacts_list_body">
                <div className="scroll-y me-n5 pe-5 h-300px h-xl-700px" data-kt-scroll="true"
                     data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                     data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_contacts_list_header"
                     data-kt-scroll-wrappers="#kt_content, #kt_contacts_list_body"
                     data-kt-scroll-stretch="#kt_contacts_list, #kt_contacts_main" data-kt-scroll-offset="5px"
                     style={{}}>
                    <ContactItem isCurrent={true} />
                    <div className="separator separator-dashed d-none"></div>
                    <ContactItem/>
                    <div className="separator separator-dashed d-none"></div>
                    <ContactItem/>
                    <div className="separator separator-dashed d-none"></div>
                    <ContactItem/>
                    <div className="separator separator-dashed d-none"></div>
                    <ContactItem/>
                    <div className="separator separator-dashed d-none"></div>
                    <ContactItem/>
                    <div className="separator separator-dashed d-none"></div>
                </div>
            </div>
        </div>
    );
};

export default ContractItemList;