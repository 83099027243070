import React from 'react';

const FeatureBloc = ({imgPath, title, desc, nbr}) => {
    return (
        <div className="text-center mb-10 mb-md-0">
            <img src={imgPath} className="mh-125px mb-9" alt={title}/>

            <div className="d-flex flex-center mb-5">
                <span className="badge  badge-circle badge-light-success fw-bold p-3 me-3 fs-3">{nbr}</span>
                <div className="fs-5 fs-lg-3 fw-bold text-dark">
                    {title}
                </div>
            </div>

            <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                {desc}
            </div>
        </div>
    );
};

export default FeatureBloc;
