import React from 'react';
import ContractItemList from "./ContractItemList";

import ContractDetails from "./ContractDetails";
import ContractHeading from "./ContractHeading";
const PropertyContracts = () => {
    return (
        <div className={"d-flex flex-column"}>
            <ContractHeading></ContractHeading>
            <div className="row g-7 w-100">
                <div className="col-lg-6 col-xl-4">
                    <ContractItemList/>
                </div>
                <div className="col-lg-6 col-xl-8">
                    <ContractDetails/>
                </div>
            </div>

        </div>
);
};

export default PropertyContracts;