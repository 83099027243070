import React from 'react';

const WorkItemDetails = ({modifyWork, deleteWork}) => {
    return (
        <div className="card card-flush bg-white shadow border-1 border-light h-lg-100" id="kt_contacts_main">
            <div className="card-header pt-7" id="kt_chat_contacts_header">
                <div className="card-title">
                    <i className="ki-outline ki-information-3 fs-1 me-2"></i>
                    <h2>Détails</h2>
                </div>

                <div className="card-toolbar gap-3">
                    <button className="btn btn-sm btn-light btn-active-light-primary" onClick={()=>{modifyWork({active:true, update:{}})}}>
                        <i className="ki-outline ki-update-file fs-2"></i> Modifier
                    </button>

                    <a href="#"
                       className="btn btn-sm btn-light btn-active-light-primary">
                        <i className="ki-outline ki-trash fs-2"></i> Spprimer
                    </a>
                </div>
            </div>

            <div className="card-body pt-5">
                <div className="d-flex gap-7 align-items-center">
                    <div className="d-flex flex-column gap-2">
                        <h3 className="mb-0">Libellé: Reparation du portails | Statut: En-attente</h3>

                        <div className="d-flex align-items-center gap-2">
                            <i className="ki-outline ki-calendar fs-2"></i>
                            <a href="#" className="text-muted text-hover-primary">Ajouté le 2023-05-15</a>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                            <i className="ki-outline ki-calendar-2 fs-2"></i>
                            <a href="#" className="text-muted text-hover-primary">Période d'intervention: Du 2023-05-15 au 2023-08-19</a>
                        </div>
                    </div>
                </div>


                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 fw-semibold mt-6 mb-8 gap-2"
                    role="tablist">

                    <li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4 active"
                           data-bs-toggle="tab" href="#kt_contact_view_general" aria-selected="true" role="tab">
                            <i className="ki-outline ki-home fs-4 me-1"></i> Général
                        </a>
                    </li>


                    <li className="nav-item" role="presentation">
                        <a className="nav-link text-active-primary d-flex align-items-center pb-4"
                           data-bs-toggle="tab" href="#kt_contact_view_meetings" aria-selected="false"
                           tabIndex="-1" role="tab">
                            <i className="ki-outline ki-calendar-8 fs-4 me-1"></i> Média
                        </a>
                    </li>

                </ul>


                <div className="tab-content" id="">
                    <br/>
                    <div className="tab-pane fade show active" id="kt_contact_view_general" role="tabpanel">


                        <div className="d-flex flex-column gap-5 mt-7">

                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Prestataire</div>
                                <div className="fw-bold fs-5">Aribas Bnb</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Domaines d'intervention</div>
                                <div className="fw-bold fs-5">Electricité,
                                    Maçonnerie, 
                                    Menuiserie</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Numéro(s) de téléphone</div>
                                <div className="fw-bold fs-5">+1 (693) 477-3772</div>
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Cout des travaux</div>
                                <div className="fw-bold fs-5"> 58 600 FCFA</div>
                            </div>


                            <div className="d-flex flex-column gap-1">
                                <div className="fw-bold text-muted">Description</div>
                                <p>
                                    Emma Smith joined the team on September 2019 as a junior associate. She soon
                                    showcased her expertise and experience in knowledge and skill in the field,
                                    which was very valuable to the company. She was promptly promoted to senior
                                    associate on July 2020. <br/>
                                    <br/>
                                    Emma Smith now heads a team of 5 associates and leads the company's sales
                                    growth by 7%.
                                </p>
                            </div>
                            <br/>
                        </div>

                    </div>


                    <br/>
                    <div className="tab-pane fade" id="kt_contact_view_meetings" role="tabpanel">

                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                            <div className="overlay me-10">
                                <div className="overlay-wrapper">
                                    <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                </div>

                                <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                    <a href="#" className="btn btn-sm btn-primary btn-shadow">image un</a>
                                </div>
                            </div>

                            <div className="overlay me-10">
                                <div className="overlay-wrapper">
                                    <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                </div>

                                <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                    <a href="#" className="btn btn-sm btn-primary btn-shadow">image deux</a>
                                </div>
                            </div>

                            <div className="overlay">
                                <div className="overlay-wrapper">
                                    <img alt="img" className="rounded w-150px" src={"/assets/images/img_test.jpg"}/>
                                </div>

                                <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                    <a href="#" className="btn btn-sm btn-primary btn-shadow">image trois</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>

                </div>


            </div>

        </div>

    );
};

export default WorkItemDetails;