import React from 'react';
import StepContentHeading from "./StepContentHeading";

const StepContent = (props) => {
    const {children, isActive=false, headTitle='', headDesc=''} = props
    return (
                <div className={isActive === true ? "current" :"pending"} data-kt-stepper-element="content">
                    <div className="w-100">
                        <StepContentHeading headTitle={headTitle} headDescription={headDesc}></StepContentHeading>
                        {children}
                    </div>
                </div>
    );
};

export default StepContent;