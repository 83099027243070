export  const PiecesPart = {
    type_elem:'',
    type_elem_other:'',
    state:{},
    description:'',
    images:[],
}
export  const PiecesLight = {
    nbr_outlet:'',
    type_elem_other:'',
    state:{},
    desc:'',
    images:[],
}
export const piecesState =[
    {value:"Mauvais",label:"Mauvais",},
    {value:"Passable", label:"Passable",},
    {value:"Bon", label:"Bon",},
    {value:"Très bon", label:"Très bon",},
]
export const piecesDoortype =[
    {value:"Bois", label:"Bois",},
    {value:"Métal", label: "Métal"},
    {value:"PVC", label: "PVC"},
    {value:"Vitre", label: "Vitre"},
    {value:"Autre", label: "Autre"},
]
export const piecesType =[
    {value:"Salon", label:"Salon",},
    {value:"Chambre", label: "Chambre"},
    {value:"Cuisine", label: "Cuisine"},
    {value:"Douche", label: "Douche"},
    {value:"Autre", label: "Autre"},
]

export  const piecesWaltype =[
    {value:"Peinture", label:"Peinture"},
    {value:"Papier peint", label: "Papier peint"},
    {value:"Crépi", label: "Crépi"},
    {value:"Faïence", label: "Faïence"},
    {value:"Autre", label: "Autre"},
]
export  const piecesGroundtype =[
    {value:"Parquet", label:"Parquet"},
    {value:"Moquette", label: "Moquette"},
    {value:"Carrelage", label: "Carrelage"},
    {value:"Marbre", label: "Marbre"},
    {value:"Lino", label: "Lino"},
    {value:"Autre", label: "Autre"},
]
export  const piecesGlazingtype =[
    {value:"Simple", label:"Simple"},
    {value:"Double et volets", label:"Double et volets"},
    {value:"PVC", label:"PVC"},
    {value:"Aluminium", label: "Aluminium"},
    {value:"Bois", label: "Bois"},
    {value:"Autre", label: "Autre"},
]
export  const piecesCeilingtype =[
    {value:"contreplaqué", label:"contreplaqué"},
    {value:"Staff", label: "Staff"},
    {value:"PVC", label: "PVC"},
    {value:"Dalle", label: "Dalle"},
    {value:"Bois", label: "Bois"},
    {value:"Autre", label: "Autre"},
]