import React, {useEffect} from 'react';
import {Link, Outlet} from "react-router-dom";
import Header from "../../home/components/header/Header";
import './home_layout.scss'
import http_axios from "../../http/axios-plugin";
import {environment} from "../../environment/environment.ts";
const HomeLayout = () => {
    useEffect(() => {
        setTimeout(async () => {
            http_axios.get(environment.countriesApiUrl).then(resp_countries => {
               localStorage.setItem('countries', JSON.stringify(resp_countries.data.data))
            }).catch(err=>{
                console.error(err)
            })
        }, 1000)
    }, []);

    return (
        <div className="HomeLayou d-flex flex-column flex-root">
            <div className="mb-0" id="home">
                <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg">
                    <Header></Header>
                    <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                        <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                            <h3 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15 ">
                                Comprendre la rentabilité
                                de votre investissement immobilier <br/>
                                <span className="landing-hero">
                                <span >En Quelques Clics</span>
                            </span>
                            </h3>

                            <Link to="/register"  className="btn btn-primary">Je M'inscris</Link>
                        </div>

                        <div className="d-flex flex-center flex-wrap position-relative px-5">

                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                                 aria-label="Fujifilm" data-bs-original-title="Fujifilm" data-kt-initialized="1">
                                <img src="/"
                                     className="mh-30px mh-lg-40px" alt=""/>
                            </div>

                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                                 aria-label="Vodafone" data-bs-original-title="Vodafone" data-kt-initialized="1">
                                <img src="/"
                                     className="mh-30px mh-lg-40px" alt=""/>
                            </div>

                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                                 aria-label="KPMG International" data-bs-original-title="KPMG International"
                                 data-kt-initialized="1">
                                <img src="/"
                                     className="mh-30px mh-lg-40px" alt=""/>
                            </div>

                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" aria-label="Nasa"
                                 data-bs-original-title="Nasa" data-kt-initialized="1">
                                <img src="/"
                                     className="mh-30px mh-lg-40px" alt=""/>
                            </div>

                            <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                                 aria-label="Aspnetzero" data-bs-original-title="Aspnetzero" data-kt-initialized="1">
                                <img src="/"
                                     className="mh-30px mh-lg-40px" alt=""/>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                    <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356
                            1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                            fill="currentColor"></path>
                    </svg>
                </div>
                <Outlet/>
            </div>
        </div>
    );
};

export default HomeLayout;