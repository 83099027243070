import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountType, setUserType} from "../../store/user-register/CustomerReducer";

export const AccountTypeForm = () => {
    const userType = useSelector(selectAccountType)
    const [account_type, setAccountType] = useState(userType)
    const dispatch = useDispatch()
    const onChange = (event) => {
        const {value} = event.target;
        setAccountType(value)
        dispatch(setUserType(value))
    };
    return (
        <>
            <div className="pb-10 pb-lg-15 ">
                <h2 className="fw-bold d-flex align-items-center text-gray-900">
                    Choisissez votre type de compte


                    <span className="ms-1" data-bs-toggle="tooltip"
                          aria-label="Billing is issued based on your selected account typ"
                          data-bs-original-title="Billing is issued based on your selected account typ"
                          data-kt-initialized="1">
	                                    <i className="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                                        </span>
                </h2>

                <div className="text-muted fw-semibold fs-6">
                    {/*Veuillez sélectionné le compte que vous souhaité utilisé*/}
                    {/*: {account_type}*/}
                    {/*<a href="#" className="link-primary fw-bold">Help Page</a>.*/}
                </div>
            </div>

            <div className="fv-row fv-plugins-icon-container ">
                <div className="row w-100">
                    <div className="col-lg-6">
                        <input type="radio" className="btn-check" name="accounType"
                               onChange={onChange}
                               value="Basic"
                               id="kt_create_account_form_account_type_personal"/>
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary border-2 p-7 d-flex align-items-center mb-10"
                            htmlFor="kt_create_account_form_account_type_personal">
                            <i className="ki-outline ki-badge fs-3x me-5"></i>
                            <span className="d-block fw-semibold text-start">
                        <span className="text-gray-900 fw-bold d-block fs-4 mb-2">
                            Particulier
                        </span>
                        <span
                            className="text-muted fw-semibold fs-6">Adapter pour les propriétaires, indépendants...</span>
                    </span>
                        </label>
                        <div
                            className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                    </div>

                    <div className="col-lg-6">
                        <input type="radio" className="btn-check" name="accounType"
                               onChange={onChange}
                               value="Business"
                               id="kt_create_account_form_account_type_corporate"/>
                        <label
                            className="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success border-2 p-7 d-flex align-items-center"
                            htmlFor="kt_create_account_form_account_type_corporate">
                            <i className="ki-outline ki-briefcase fs-3x me-5"></i>
                            <span className="d-block fw-semibold text-start">
                        <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Agence Immobilière</span>
                        <span className="text-muted fw-semibold fs-6">Adapter pour les professionnelle</span>
                    </span>
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}