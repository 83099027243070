import React from 'react';
import './header.scss';
import {Link} from "react-router-dom";
const Header = () => {
    return (
        <div className="landing-header" >

            <div className="container">

                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-equal">
                        <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                id="kt_landing_menu_toggle">
                            <i className="ki-duotone ki-abstract-14 fs-2hx"><span
                                className="path1"></span><span className="path2"></span></i>
                        </button>

                        <a href="/">
                            <img alt="Logo" src={'/assets/images/logo-text.png'}
                                 className="logo-default h-40px h-lg-60px"/>
                            <img alt="Logo" src={'/assets/images/logo-text.png'}
                                 className="logo-sticky h-40px h-lg-60px"/>
                        </a>
                    </div>

                    <div className="d-lg-block" id="kt_header_nav_wrapper">

                    </div>

                    <div className="flex-equal text-end ms-1">
                        <Link to="/login" className="btn btn-success">Mon Compte</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;