import React, {useState} from 'react';
import UserAccount from "../models/UserAccount.ts";
import FullCard from "../../shared/components/full-card/FullCard";
const MemberCard = ({user=UserAccount, getDetails}) => {

    return (
        <FullCard isItem={true}>
            <div className="symbol symbol-65px symbol-circle mb-5">
                <span className="symbol-label fs-2x fw-semibold text-info bg-light-info">{user.user_infos.last_name[0]}{user.user_infos.first_name[0]}</span>
                <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-15px w-15px ms-n3 mt-n3"></div>
            </div>

            <p  className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">{user.user_infos.last_name} {user.user_infos.first_name}</p>
            <div className="fw-semibold text-gray-400 mb-6">{user.post}</div>

            <div className="d-flex flex-center flex-wrap mb-2">
                <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                    <div className="fs-6 fw-bold text-gray-700">{user.service}</div>
                    <div className="fw-semibold text-gray-400">Service</div>
                </div>

                <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                    <div className="fs-6 fw-bold text-gray-700">{user.user_infos.contact}</div>
                    <div className="fw-semibold text-gray-400">Contact</div>
                </div>
            </div>

            <button className="btn btn-sm btn-light-primary btn-flex btn-center" onClick={getDetails} data-kt-follow-btn="true">
                <i className="ki-outline ki-check following fs-3"></i>
                <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                <span className="indicator-label">Details</span>
            </button>
        </FullCard>
    );
};

export default MemberCard;