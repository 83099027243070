import React from 'react';

const EmailItems = ({emailData}) => {
    return (
        <div className="col">
            <div className="card bg-white shadow my-2 border-1 border-light">
                <div className="card-body d-flex flex-center flex-column py-9 px-5">
                    <div className="symbol symbol-75px symbol-circle mb-5">
                        <span className="symbol-label fs-2x fw-semibold text-info bg-light-primary">
                            <i className="bi bi-envelope-fill fs-3x"></i>
                        </span>
                    </div>

                    <p className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">N°: {emailData.uid}</p>
                    <div className="fw-semibold text-gray-400 mb-6"> {emailData.type}</div>

                    <div className="d-flex flex-center flex-wrap mb-4 w-100">
                        <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                            <div className="fs-6 fw-bold text-gray-700">Utilisé</div>
                            <div className="fw-semibold text-gray-400">Statut</div>
                        </div>

                        <div className="border border-dashed rounded min-w-100px py-2 px-3 mx-2 mb-3">
                            <div className="fs-6 fw-bold text-gray-700">{emailData.desc}</div>
                            <div className="fw-semibold text-gray-400">Description</div>
                        </div>
                    </div>
                    <div className="d-flex flex-center flex-wrap gap-3 w-100">
                        <button className="btn btn-sm btn-light-primary btn-flex btn-center"
                                data-kt-follow-btn="true">
                            <i className="ki-outline ki-eye following fs-3"></i>
                            <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                            <span className="indicator-label">Voir</span>
                        </button>
                        <button className="btn btn-sm btn-light-danger btn-flex btn-center"
                                data-kt-follow-btn="true">
                            <i className="ki-outline ki-trash following fs-3"></i>
                            <i className="ki-outline ki-plus follow fs-3 d-none"></i>
                            <span className="indicator-label">Supprimer</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default EmailItems;
