import React from 'react';
import {useSelector} from "react-redux";
import {
    selectAccountType,
    selectUserAccount,
    selectUserInfos
} from "../../store/user-register/CustomerReducer";

const RegisterSuccessFull = () => {
    const accountType = useSelector(selectAccountType)
    const userInfos = useSelector(selectUserInfos)
    const userAccount = useSelector(selectUserAccount)

    return (
        <>
            <div className="w-100">
                <div className="pb-8 pb-lg-10">
                    <h2 className="fw-bold text-gray-900">Vous avez presque Terminé!</h2>

                    <div className="text-muted fw-semibold fs-6">
                        Votre compte va être créé après votre validation
                    </div>
                </div>

                <div className="mb-0">
                    <div className="fs-6 text-gray-600 mb-5">
                        Veuillez vous assurer d'avoir bien renseigné vos informations d'authentification.
                    </div>
                    <div className="row gap-2  w-100">
                        <div className="col notice d-flex bg-light-info  rounded border-info border border-dashed  p-6">
                            <i className="ki-outline ki-information fs-2tx text-info me-4"></i>

                            <div className="d-flex flex-stack flex-grow-1 ">
                                <div className=" fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">Type de compte</h4>

                                    <div className="fs-6 text-gray-700 ">
                                        {accountType === "Basic" ? "Particulier" : "Entreprise"}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col notice d-flex bg-light-info rounded border-info border border-dashed  p-6">
                            <i className="ki-outline ki-information fs-2tx text-info me-4"></i>

                            <div className="d-flex flex-stack flex-grow-1 ">
                                <div className=" fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">Téléphone</h4>
                                    <div className="fs-6 text-gray-700 ">
                                        {userAccount.phone_number}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col notice d-flex bg-light-info rounded border-info border border-dashed  p-6">
                            <i className="ki-outline ki-information fs-2tx text-info me-4"></i>

                            <div className="d-flex flex-stack flex-grow-1 ">
                                <div className=" fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">Nom d'utilisateur</h4>
                                    <div className="fs-6 text-gray-700 ">
                                        {userAccount.username}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default RegisterSuccessFull;
